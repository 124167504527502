export type Breed = {
  id: number;
  nameTH: string;
  nameEN: string;
};

export const breeds: Breed[] = [
  {
    id: 0,
    nameEN: "Other breed / Mongrel (Mixed race)",
    nameTH: "สายพันธุ์อื่น / พันธุ์ผสม (ลูกผสม)",
  },
  { id: 1, nameEN: "Affenpinscher", nameTH: "อัฟเฟนพินเชอร์" },
  { id: 2, nameEN: "Afghan Hound", nameTH: "อัฟกันฮาวนด์" },
  { id: 3, nameEN: "Airedale Terrier", nameTH: "แอเรเดล เทอร์เรียร์" },
  { id: 4, nameEN: "Akita", nameTH: "อาคิตะ" },
  { id: 5, nameEN: "Alaskan Malamute", nameTH: "อลาสกัน มาลามิวท์" },
  { id: 6, nameEN: "Alpine Dachsbracke", nameTH: "อัลไพน์ ดัคส์บรัคเคอ" },
  { id: 7, nameEN: "American Akita", nameTH: "อเมริกัน อาคิตะ" },
  { id: 8, nameEN: "American Bully", nameTH: "อเมริกัน บูลลี่" },
  {
    id: 9,
    nameEN: "American Cocker Spaniel",
    nameTH: "อเมริกัน ค็อกเกอร์ สแปเนียล",
  },
  { id: 10, nameEN: "American Foxhound", nameTH: "อเมริกัน ฟ็อกซ์ฮาวนด์" },
  { id: 11, nameEN: "American Shepherd", nameTH: "อเมริกัน เชพเพิร์ด" },
  {
    id: 12,
    nameEN: "American Staffordshire Terrier",
    nameTH: "อเมริกัน สแตฟฟอร์ดเชียร์ เทอร์เรีย",
  },
  {
    id: 13,
    nameEN: "American Water Spaniel",
    nameTH: "อเมริกัน วอเตอร์ สแปเนียล",
  },
  { id: 14, nameEN: "Anatolian Shepherd Dog", nameTH: "อนาโตเลี่ยน เชพเพิร์ด" },
  {
    id: 15,
    nameEN: "Anatolian Cattle Dog",
    nameTH: "อนาโตเลี่ยน แคตเทิล ด็อก",
  },
  {
    id: 16,
    nameEN: "Appenzell Cattle Dog",
    nameTH: "อัพเพนเซลล์ แคตเทิล ด็อก",
  },
  {
    id: 17,
    nameEN: "Arabian Greyhound",
    nameTH: "อาราเบียน เกรย์ฮาวด์ พันธุ์อาหรับ (ซาลูกิ)",
  },
  { id: 18, nameEN: "Ariege Hound", nameTH: "Ariege Hound" },
  { id: 19, nameEN: "Arege Pointer", nameTH: "อาเรียฌ พอยน์เตอร์" },
  {
    id: 20,
    nameEN: "Artesian-Norman Basset",
    nameTH: "อาร์ทีเชียน-นอร์แมนบาสเซ็ต",
  },
  { id: 21, nameEN: "Artois Hound", nameTH: "อาร์ตัวฮาวนด์" },
  { id: 22, nameEN: "Atlas Hound", nameTH: "แอทลาส ฮาวนด์" },
  { id: 23, nameEN: "Atlas Mountain Dog", nameTH: "แอทลาส เมาน์เทนด็อก" },
  {
    id: 24,
    nameEN: "Australian Cattle Dog",
    nameTH: "ออสเตรเลียน แคทเทิล ด็อก",
  },
  { id: 25, nameEN: "Australian Kelpie", nameTH: "ออสเตรเลียน เคลพี" },
  {
    id: 26,
    nameEN: "Australian Shepherd (Aussie)",
    nameTH: "ออสเตรเลียน เชพเพิร์ด (ออสซี่)",
  },
  {
    id: 27,
    nameEN: "Australian Silky Terrier",
    nameTH: "ออสเตรเลียน ซิลกี้ เทอร์เรียร์",
  },
  {
    id: 28,
    nameEN: "Australian Stumpy Tail Cattle Dog",
    nameTH: "ออสเตรเลียน สตัมปี เทล แคตเทิล",
  },
  { id: 29, nameEN: "Australian Terrier", nameTH: "ออสเตรเลียน เทอร์เรียร์" },
  {
    id: 30,
    nameEN: "Austrian Black-and-Tan Hound",
    nameTH: "ออสเตรีย ฮาวด์ สีดำและสีแทน",
  },
  { id: 31, nameEN: "Austrian Pinscher", nameTH: "ออสเตรียน พินเชอร์" },
  { id: 32, nameEN: "Auvergne Pointer", nameTH: "โอแวร์ญ พอยน์เตอร์" },
  { id: 33, nameEN: "Azawakh", nameTH: "อาซาวัค" },
  { id: 34, nameEN: "Basenji", nameTH: "บาเซนจิ" },
  { id: 35, nameEN: "Basset Hound", nameTH: "บาสเซ็ต ฮาวด์" },
  {
    id: 36,
    nameEN: "Bavarian Mountain Scenthound",
    nameTH: "บาวาเรียน เมาน์เทน ฮาวนด์",
  },
  { id: 37, nameEN: "Beagle", nameTH: "บีเกิล" },
  { id: 38, nameEN: "Beagle-Harrier", nameTH: "บีเกิล-แฮร์เรีย" },
  { id: 39, nameEN: "Bearded Collie", nameTH: "เบียร์ด คอลลี่" },
  { id: 40, nameEN: "Beauceron", nameTH: "บิวเซรอน" },
  { id: 41, nameEN: "Bedlington Terrier", nameTH: "เบดลิงตัน เทอร์เรียร์" },
  {
    id: 42,
    nameEN: "Belgian Shepherd Dog (Malinois)",
    nameTH: "เบลเยี่ยม เชพเพิร์ด (มาลินอยส์)",
  },
  {
    id: 43,
    nameEN: "Belgian Shepherd Dog (Tervuren)",
    nameTH: "เบลเยี่ยม เชพเพิร์ด (แตร์ฟือเริน)",
  },
  { id: 44, nameEN: "Bergamasco Shepherd", nameTH: "แบร์กามัสโก เชพเพิร์ด" },
  { id: 45, nameEN: "Bernese Mountain Dog", nameTH: "เบอร์นีส เมาน์เทนด็อก" },
  { id: 46, nameEN: "Bichon Frise", nameTH: "บิชอง ฟริเซ่" },
  { id: 47, nameEN: "Biewer Terrier", nameTH: "บีเวอร์ เทอร์เรีย" },
  { id: 48, nameEN: "Billy", nameTH: "บิลลี่" },
  {
    id: 49,
    nameEN: "Black-and-Tan Coonhound",
    nameTH: "คูนฮาวนด์ สีดำและสีแทน",
  },
  { id: 50, nameEN: "Bloodhound", nameTH: "บลัดฮาวนด์" },
  { id: 51, nameEN: "Blue Gascony Basset", nameTH: "บลู กัสโคนี่ บาสเซ็ต" },
  { id: 52, nameEN: "Blue Gascony Griffon", nameTH: "บลู กัสโคนี่ กริฟฟอน" },
  { id: 53, nameEN: "Blue Picardy Spaniel", nameTH: "บลู พิคาร์ดี้ สแปเนียล" },
  {
    id: 54,
    nameEN: "Bohemian Wirehaired Pointing Griffon",
    nameTH: "โบฮีเมียน ไวร์แฮร์ พอยน์ติ้ง กริฟฟอน",
  },
  { id: 55, nameEN: "Bolognese", nameTH: "โบโลเนส" },
  { id: 56, nameEN: "Border Terrier", nameTH: "บอเดอร์ เทอร์เรีย" },
  {
    id: 57,
    nameEN: "Bosnian and Herzegovinian-Croatian shepherd Dog",
    nameTH: "บอสเนียและเฮอร์เซโกวีนา-โครเอเชียน เชพเพิร์ด ด็อก",
  },
  {
    id: 58,
    nameEN: "Bosnian Coarsehaired Hound",
    nameTH: "บอสเนีย ฮาวนด์ ขนหยาบ (บารัดฮาวนด์)",
  },
  { id: 59, nameEN: "Boston Terrier", nameTH: "บอสตัน เทอร์เรีย" },
  { id: 60, nameEN: "Bourbonnais Pointer", nameTH: "บูร์บอนแน พอยน์เตอร์" },
  { id: 61, nameEN: "Bouvier des Ardennes", nameTH: "บูวีเย เด อาร์เดน" },
  { id: 62, nameEN: "Bouvier des Flandres", nameTH: "บูวีเย เด ฟล็องเดรอะ" },
  { id: 63, nameEN: "Boxer", nameTH: "บ็อกเซอร์" },
  { id: 64, nameEN: "Braque Dupuy", nameTH: "บรัก ดูว์ปุย" },
  { id: 65, nameEN: "Brazilian Terrier", nameTH: "บราซิเลียน เทอร์เรีย" },
  { id: 66, nameEN: "Briard", nameTH: "บริอาร์ด" },
  { id: 67, nameEN: "Brittany Spaniel", nameTH: "บริตทานีส สแปเนียล" },
  { id: 68, nameEN: "Broholmer", nameTH: "โบรฮอลเมอร์" },
  { id: 69, nameEN: "Bull Terrier", nameTH: "บูล เทอร์เรีย" },
  { id: 70, nameEN: "Bulldog", nameTH: "บูลด็อก" },
  { id: 71, nameEN: "Bullmastiff", nameTH: "บูล มาสทิฟฟ์" },
  { id: 72, nameEN: "Burgos Pointer", nameTH: "บูร์โกส พอยน์เตอร์" },
  { id: 73, nameEN: "Cairn Terrier", nameTH: "เครนเทอร์เรีย" },
  { id: 74, nameEN: "Canaan Dog", nameTH: "คานาอัน ด็อก" },
  {
    id: 75,
    nameEN: "Canarian Warren Hound (Canarian Podenco)",
    nameTH: "คานาเรี่ยน วอร์เรน ฮาวนด์ (คานาเรี่ยน โพเดนโก)",
  },
  { id: 76, nameEN: "Castro Laboreiro Dog", nameTH: "คาสโตร ลาโบเรียโร ด็อก" },
  { id: 77, nameEN: "Catalan Sheepdog", nameTH: "คาตาลัน ชีพ ด็อก" },
  { id: 78, nameEN: "Caucasian Shepherd Dog", nameTH: "คอเคเซียน เชพเพิร์ด" },
  {
    id: 79,
    nameEN: "Cavalier King Charles Spaniel",
    nameTH: "คาวาเลียร์ คิงชาลส์ สแปเนียล",
  },
  {
    id: 80,
    nameEN: "Central Asia Shepherd Dog",
    nameTH: "เซ็นทรัล เอเชีย เชพเพิร์ด ด็อก",
  },
  { id: 81, nameEN: "Cesky Terrier", nameTH: "เชสกี เทอร์เรีย" },
  {
    id: 82,
    nameEN: "Chesapeake Bay Retriever",
    nameTH: "เซซาพีก เบย์ รีทรีฟเวอร์",
  },
  { id: 83, nameEN: "Chinese Crested Dog", nameTH: "ไชนีส เครสเต็ด ด็อก" },
  { id: 84, nameEN: "Chow Chow", nameTH: "เชาเชา" },
  { id: 85, nameEN: "Cirneco dell’Etna", nameTH: "เชร์เนโก เดลเลตนา" },
  { id: 86, nameEN: "Clumber Spaniel", nameTH: "คลัมเบอร์ สแปเนียล" },
  { id: 87, nameEN: "Collie", nameTH: "คอลลี" },
  { id: 88, nameEN: "Collie, Rough", nameTH: "คอลลี, รัฟ" },
  { id: 89, nameEN: "Collie, Smooth", nameTH: "คอลลี, สมูธ" },
  {
    id: 90,
    nameEN: "Continental Toy Spaniel",
    nameTH: "คอนทิเนนทัล ทอย สแปเนียล",
  },
  { id: 91, nameEN: "Coton de Tulear", nameTH: "โคตอน เด ทิวเลียร์" },
  {
    id: 92,
    nameEN: "Croatian Shepherd Dog",
    nameTH: "คอเคเซียน เชพเพิร์ด ด็อก",
  },
  {
    id: 93,
    nameEN: "Curly Coated Retriever",
    nameTH: "เคอร์ลี่ โคด์ รีทรีฟเวอร์",
  },
  { id: 94, nameEN: "Cursinu", nameTH: "เคอร์ซินู" },
  {
    id: 95,
    nameEN: "Czechoslovakian Wolfdog",
    nameTH: "เชคโกสโลวัคเกียน วูล์ฟด็อก",
  },
  { id: 96, nameEN: "Dalmatian", nameTH: "ดัลเมเชียน" },
  {
    id: 97,
    nameEN: "Dandie Dinmont Terrier",
    nameTH: "แดนดี ดินมอนท์ เทอร์เรียร์",
  },
  {
    id: 98,
    nameEN: "Danish-Swedish Farmdog",
    nameTH: "เดนมาร์ก-สวีเดน ฟาร์มด็อก",
  },
  { id: 99, nameEN: "Deerhound", nameTH: "เดียร์ฮาวนด์" },
  { id: 100, nameEN: "Dobermann", nameTH: "โดเบอร์แมน" },
  { id: 101, nameEN: "Dogo Argentino", nameTH: "โดโก อาร์เจนติโน" },
  { id: 102, nameEN: "Dogo Canario", nameTH: "โดโก คานาริโอ" },
  { id: 103, nameEN: "Dogue de Bordeaux", nameTH: "ด็อจ เดอ บอร์โดซ์" },
  {
    id: 104,
    nameEN: "Drentsche Partridge Dog",
    nameTH: "เดรนท์เช พาทริดจ์ ด็อก",
  },
  { id: 105, nameEN: "Drever", nameTH: "เดรเวอร์" },
  { id: 106, nameEN: "Dutch Schapendoes", nameTH: "ดัทช์ สคาเพนดัส" },
  { id: 107, nameEN: "Dutch Shepherd Dog", nameTH: "ดัทช์ เชพเพิร์ดด็อก" },
  { id: 108, nameEN: "Dutch Smoushound", nameTH: "ดัทช์ สเมาสฮอนด์" },
  { id: 109, nameEN: "East Siberian Laika", nameTH: "อิสต์ ไซบีเรียน ไลก้า" },
  {
    id: 110,
    nameEN: "English Cocker Spaniel",
    nameTH: "อิงลิช ค็อกเกอร์ สแปเนียล",
  },
  { id: 111, nameEN: "English Foxhound", nameTH: "อิงลิช ฟ็อกซ์ฮาวนด์" },
  { id: 112, nameEN: "English Pointer", nameTH: "อิงลิช พอยน์เตอร์" },
  { id: 113, nameEN: "English Setter", nameTH: "อิงลิช เซตเทอร์" },
  {
    id: 114,
    nameEN: "English Springer Spaniel",
    nameTH: "อิงลิช สปริงเกอร์ สแปเนียล",
  },
  {
    id: 115,
    nameEN: "English Toy Terrier (English Black-and-Tan Terrier)",
    nameTH: "อิงลิช ทอย เทอร์เรีย (อิงลิช แบล็ค แอนด์ แทน เทอร์เรีย)",
  },
  {
    id: 116,
    nameEN: "Entlebuch Cattle Dog",
    nameTH: "เอ็นเทิลบุช แคตเทิล ด็อก",
  },
  { id: 117, nameEN: "Estrela Mountain Dog", nameTH: "เอสเทรลา เมาน์เทน ด็อก" },
  { id: 118, nameEN: "Eurasian", nameTH: "ยูเรเซียน" },
  { id: 119, nameEN: "Fawn Brittany Basset", nameTH: "ฟอว์น บริตทานี บาสเซ็ต" },
  {
    id: 120,
    nameEN: "Fawn Brittany Griffon",
    nameTH: "ฟอว์น บริตทานี กริฟฟอน",
  },
  { id: 121, nameEN: "Field Spaniel", nameTH: "ฟิลด์สแปเนียล" },
  { id: 122, nameEN: "Fila Brasileiro", nameTH: "ฟิล่า บราซิเลียโร" },
  { id: 123, nameEN: "Finnish Hound", nameTH: "ฟินนิช ฮาวนด์" },
  { id: 124, nameEN: "Finnish Lapphund", nameTH: "ฟินนิช แลปป์ฮุนด์" },
  { id: 125, nameEN: "Finnish Spitz", nameTH: "ฟินนิช สปิตซ์" },
  {
    id: 126,
    nameEN: "Flat Coated Retriever",
    nameTH: "แฟล็ต โคเท็ด รีทรีฟเวอร์",
  },
  { id: 127, nameEN: "Fox Terrier, Smooth", nameTH: "ฟ็อกซ์ เทอร์เรีย, สมูธ" },
  { id: 128, nameEN: "Fox Terrier, Wire", nameTH: "ฟ็อกซ์ เทอร์เรีย, ไวร์" },
  {
    id: 129,
    nameEN: "French Pointer, Gascogne type (Large)",
    nameTH: "เฟรนช์ พอยน์เตอร์ กัสกอญ (ใหญ่)",
  },
  {
    id: 130,
    nameEN: "French Pointer, Pyrenean type (Small)",
    nameTH: "เฟรนช์ พอยน์เตอร์ พิเรเนียน (เล็ก)",
  },
  { id: 131, nameEN: "French Spaniel", nameTH: "เฟรนช์ สแปเนียล" },
  { id: 132, nameEN: "French Tricolour Hound", nameTH: "เฟรนช์ สุนัขสามสี" },
  { id: 133, nameEN: "French Water Dog", nameTH: "เฟรนช์ วอเตอร์ด็อก" },
  {
    id: 134,
    nameEN: "French White-and-Black Hound",
    nameTH: "เฟรนช์ ฮาวนด์ สีขาวดำ ",
  },
  {
    id: 135,
    nameEN: "French White-and Orange Hound",
    nameTH: "เฟรนช์ ฮาวนด์ สีขาวและส้ม ",
  },
  { id: 136, nameEN: "Frisian Pointer", nameTH: "ฟรีเซียน พอยน์เตอร์" },
  { id: 137, nameEN: "Frisian Water Dog", nameTH: "ฟรีเซียน วอเตอร์ด็อก" },
  { id: 138, nameEN: "Gascon Saintongeois", nameTH: "แกสคอยน์ แซงต์ ตองยัว" },
  { id: 139, nameEN: "German Hound", nameTH: "เยอรมัน ฮาวนด์" },
  {
    id: 140,
    nameEN: "German Hunting Terrier",
    nameTH: "เยอรมัน ฮันทิง เทอร์เรีย",
  },
  {
    id: 141,
    nameEN: "German Longhaired Pointer",
    nameTH: "เยอรมัน พอยน์เตอร์ ขนยาว",
  },
  { id: 142, nameEN: "German Pinscher", nameTH: "เยอรมัน พินเชอร์" },
  {
    id: 143,
    nameEN: "German Rough-haired Pointer",
    nameTH: "เยอรมัน พอยน์เตอร์ ขนหยาบ",
  },
  { id: 144, nameEN: "German Shepherd Dog", nameTH: "เยอรมัน เชพเพิร์ด" },
  {
    id: 145,
    nameEN: "German Shorthaired Pointer",
    nameTH: "เยอรมัน พอยน์เตอร์ ขนสั้น",
  },
  { id: 146, nameEN: "German Spaniel", nameTH: "เยอรมัน สแปเนียล" },
  {
    id: 147,
    nameEN: "French White-and-Orange Hound",
    nameTH: "เฟรนช์ ฮาวนด์ สีขาวและส้ม",
  },
  { id: 148, nameEN: "Frisian Pointer", nameTH: "ฟรีเซียน พอยน์เตอร์" },
  { id: 149, nameEN: "Frisian Water Dog", nameTH: "ฟรีเซียน วอเตอร์ด็อก" },
  { id: 150, nameEN: "Gascon Saintongeois", nameTH: "แกสคอยน์ แซงต์ ตองยัว" },
  { id: 151, nameEN: "German Hound", nameTH: "เยอรมัน ฮาวนด์" },
  {
    id: 152,
    nameEN: "German Hunting Terrier",
    nameTH: "เยอรมัน ฮันทิง เทอร์เรีย",
  },
  {
    id: 153,
    nameEN: "German Longhaired Pointer",
    nameTH: "เยอรมัน พอยน์เตอร์ ขนยาว",
  },
  { id: 154, nameEN: "German Pinscher", nameTH: "เยอรมัน พินเชอร์" },
  {
    id: 155,
    nameEN: "German Rough-haired Pointer",
    nameTH: "เยอรมัน พอยน์เตอร์ ขนหยาบ",
  },
  { id: 156, nameEN: "German Shepherd Dog", nameTH: "เยอรมัน เชพเพิร์ด" },
  {
    id: 157,
    nameEN: "German Shorthaired Pointer",
    nameTH: "เยอรมัน พอยน์เตอร์ ขนสั้น",
  },
  { id: 158, nameEN: "German Spaniel", nameTH: "เยอรมัน สแปเนียล" },
  { id: 159, nameEN: "German Spitz", nameTH: "เยอรมัน สปิตซ์" },
  {
    id: 160,
    nameEN: "German Wirehaired Pointer",
    nameTH: "เยอรมัน ไวร์แฮร์ พอยน์เตอร์",
  },
  { id: 161, nameEN: "Giant Schnauzer", nameTH: "ไจแอนท์ ชเนาเซอร์" },
  { id: 162, nameEN: "Golden Retriever", nameTH: "โกลเด้น รีทรีฟเวอร์" },
  { id: 163, nameEN: "Gordon Setter", nameTH: "กอร์ดอน เซตเทอร์" },
  {
    id: 164,
    nameEN: "Grand Basset Griffon Vendeen",
    nameTH: "แกรนด์ บาสเซ็ต กริฟฟอน เวนดีน",
  },
  { id: 165, nameEN: "Grand Griffon Bendeen", nameTH: "แกรนด์ กริฟฟอน เวนดีน" },
  {
    id: 166,
    nameEN: "Great Anglo-French Tricolour Hound",
    nameTH: "เกรท แองโกล - ฝรั่งเศส ฮาวนด์ สามสี",
  },
  {
    id: 167,
    nameEN: "Great Anglo-French White-and-Black Hound",
    nameTH: "เกรท แองโกล - ฝรั่งเศส ฮาวนด์ สีขาวดำ",
  },
  {
    id: 168,
    nameEN: "Great Anglo-French White-and-Orange Hound",
    nameTH: "เกรท แองโกล - ฝรั่งเศส ฮาวนด์ สีขาวส้ม",
  },
  { id: 169, nameEN: "Great Dane", nameTH: "เกรท เดน" },
  { id: 170, nameEN: "Great Gascony Blue", nameTH: "เกรท แกสโคนี่ บลู" },
  {
    id: 171,
    nameEN: "Great Swiss Mountain Dog",
    nameTH: "เกรท สวิสส์ เมาน์เทนด็อก",
  },
  { id: 172, nameEN: "Greenland Dog", nameTH: "กรีนแลนด์ ด็อก" },
  { id: 173, nameEN: "Greyhound", nameTH: "เกรย์ ฮาวด์" },
  { id: 174, nameEN: "Griffon Belge", nameTH: "กริฟฟอน เบลจ์" },
  { id: 175, nameEN: "Griffon Boulet", nameTH: "กริฟฟอน บูเล็ต" },
  { id: 176, nameEN: "Griffon Bruxellois", nameTH: "กริฟฟอน บรูเซลลอยส์" },
  { id: 177, nameEN: "Griffon Nivernais", nameTH: "กริฟฟอน เนอแวร์" },
  { id: 178, nameEN: "Groenendael Dog", nameTH: "โกรเนนเดล ด็อก" },
  { id: 179, nameEN: "Halden Hound", nameTH: "ฮัลเดน ฮาวนด์" },
  { id: 180, nameEN: "Hamiltonstovare", nameTH: "แฮมิลตัน สโตวาเร" },
  {
    id: 181,
    nameEN: "Hanoverian Scenthound",
    nameTH: "ฮาโนเวอเรียน สเคนท์ ฮาวนด์",
  },
  { id: 182, nameEN: "Harrier", nameTH: "เฮอร์ริเออร์" },
  { id: 183, nameEN: "Havanese", nameTH: "ฮาวานีส" },
  { id: 184, nameEN: "Hellenic Hound", nameTH: "เฮลเลนิค ฮาวนด์" },
  { id: 185, nameEN: "Hokkaido", nameTH: "ฮอกไกโด" },
  { id: 186, nameEN: "Hovawart", nameTH: "โฮวาวาร์ต" },
  { id: 187, nameEN: "Hungarian Greyhound", nameTH: "ฮังกาเรียน เกรย์ฮาวนด์" },
  {
    id: 188,
    nameEN: "Hungarian Shorthaired Pointer",
    nameTH: "ฮังกาเรียน ช็อตแฮร์ พอยน์เตอร์ ",
  },
  {
    id: 189,
    nameEN: "Hungarian Wirehaired Pointer",
    nameTH: "ฮังกาเรียน พอยเตอร์ ไวร์แฮร์",
  },
  { id: 190, nameEN: "Hygen Hound", nameTH: "ไฮเกน ฮาวนด์" },
  { id: 191, nameEN: "Ibizan Hound", nameTH: "อิบิซาน ฮาวนด์" },
  { id: 192, nameEN: "Icelandic Sheepdog", nameTH: "ไอซ์แลนดิค ชีพด็อก" },
  {
    id: 193,
    nameEN: "Irish Glen of Imaal Terrier",
    nameTH: "ไอริช เกลน ออฟ อิมาล เทอร์เรีย ",
  },
  {
    id: 194,
    nameEN: "Irish Red and White Setter",
    nameTH: "ไอริช เซตเทอร์ สีแดงขาว",
  },
  { id: 195, nameEN: "Irish Red Setter", nameTH: "ไอริช เรด เซตเทอร์" },
  {
    id: 196,
    nameEN: "Irish Soft Coated Wheaten Terrier",
    nameTH: "ไอริช ซอฟต์ โคเทด วีทเทน เทอร์เรีย",
  },
  { id: 197, nameEN: "Irish Terrier", nameTH: "ไอริช เทอร์เรีย" },
  { id: 198, nameEN: "Irish Water Spaniel", nameTH: "ไอริช วอเตอร์ สแปเนียล" },
  { id: 199, nameEN: "Irish Wolfhound", nameTH: "ไอริช วูล์ฟฮาวนด์" },
  {
    id: 200,
    nameEN: "Istrian Shorthaired Scenthound",
    nameTH: "อิสเทรียน ชอร์ตแฮร์ ฮาวนด์",
  },
  {
    id: 201,
    nameEN: "Istrian Wirehaired Scenthound",
    nameTH: "อิสเทรียน ไวร์แฮร์ เซนท์ฮาวนด์",
  },
  {
    id: 202,
    nameEN: "Italian Corso Dog (Cane Corso)",
    nameTH: "อิตาเลียน คอร์โซ่ ด็อก (เคน คอร์โซ่)",
  },
  { id: 203, nameEN: "Italian Greyhound", nameTH: "อิตาเลียน เกรย์ฮาวด์" },
  {
    id: 204,
    nameEN: "Italian Hound, Rough-haired",
    nameTH: "อิตาเลียน ฮาวด์ ขนหยาบ",
  },
  {
    id: 205,
    nameEN: "Italian Hound, Shorthaired",
    nameTH: "อิตาเลียน ฮาวด์ ขนสั้น",
  },
  { id: 206, nameEN: "Italian Pointer", nameTH: "อิตาเลียน พอยน์เตอร์" },
  { id: 207, nameEN: "Kooikerhondje", nameTH: "คอยเกอร์ฮอนด์เจ" },
  { id: 208, nameEN: "Korea Jindo Dog", nameTH: "โคเรียน จินโด" },
  { id: 209, nameEN: "Kromfohrlander", nameTH: "ครอมฟอร์เลนเดอร์" },
  { id: 210, nameEN: "Kuvasz", nameTH: "คูวาสซ์" },
  {
    id: 211,
    nameEN: "Labradoodle (Labrapoodle)",
    nameTH: "ลาบราดูเดิ้ล (ลาบรา พุดเดิ้ล)",
  },
  { id: 212, nameEN: "Labrador Retriever", nameTH: "ลาบราดอร์ รีทรีฟเวอร์" },
  { id: 213, nameEN: "Lakeland Terrier", nameTH: "เลคแลนด์ เทอร์เรีย" },
  { id: 214, nameEN: "Landseer", nameTH: "แลนด์เซียร์" },
  { id: 215, nameEN: "Lapponian Herder", nameTH: "ลัปโปเนียน เฮอร์เดอร์" },
  { id: 216, nameEN: "Large Munsterlander", nameTH: "ลาร์จ มันสเตอร์แลนเดอร์" },
  { id: 217, nameEN: "Leonberger", nameTH: "ลีออนเบอร์เกอร์" },
  { id: 218, nameEN: "Lhasa Apso", nameTH: "ลาซา แอพโซ" },
  { id: 219, nameEN: "Little Lion Dog", nameTH: "ลิตเติล ไลออน ด็อก" },
  { id: 220, nameEN: "Majorca Mastiff", nameTH: "มาจอร์กา มาสทิฟฟ์" },
  {
    id: 221,
    nameEN: "Majorca Shepherd Dog",
    nameTH: "มาจอร์กา เชพเพิร์ด ด็อก",
  },
  { id: 222, nameEN: "Manchester Terrier", nameTH: "แมนเชสเตอร์ เทอร์เรีย" },
  {
    id: 223,
    nameEN: "Maremma-Abruzzes Sheepdog",
    nameTH: "มาเรมมา-อาบรูซซ์ ชีพด็อก",
  },
  { id: 224, nameEN: "Mastiff", nameTH: "มาสทิฟฟ์" },
  {
    id: 225,
    nameEN: "Medium Griffon Vendeen",
    nameTH: "กริฟฟอน เวนดีน ขนาดกลาง",
  },
  {
    id: 226,
    nameEN: "Medium-sized Anglo-French Hound",
    nameTH: "แองโกล-เฟรนช์ ฮาวนด์ ขนาดกลาง",
  },
  {
    id: 227,
    nameEN: "Mini Australian Shepherd (Mini-Aussie)",
    nameTH: "มินิ ออสเตรเลียน เชพเพิร์ด (มินิ-ออสซี่)",
  },
  { id: 228, nameEN: "Miniature Pinscher", nameTH: "มินิเอเจอร์ พินช์เชอร์" },
  {
    id: 229,
    nameEN: "Miniature Schnauzer",
    nameTH: "มินิเอเจอร์ ชเนาเซอร์ พันธุ์ผสม (ลูกผสม)",
  },
  { id: 230, nameEN: "Mongrel (Mixed race)", nameTH: "Mongrel (Mixed race)" },
  {
    id: 231,
    nameEN: "Montenegrin Mountain Hound",
    nameTH: "มอนเตเนกริน เมาน์เทน ฮาวนด์",
  },
  { id: 232, nameEN: "Mudi", nameTH: "มูดิ" },
  { id: 233, nameEN: "Neapolitan Mastiff", nameTH: "เนเปิลส์ มาสทิฟฟ์" },
  { id: 234, nameEN: "Newfoundland", nameTH: "นิวฟาวด์แลนด์" },
  { id: 235, nameEN: "Norfolk Terrier", nameTH: "นอร์โฟล์ค เทอร์เรีย" },
  { id: 236, nameEN: "Norrbottenspitz", nameTH: "นอร์บอทเทนสปิตซ์" },
  { id: 237, nameEN: "Norwegian Buhund", nameTH: "นอร์วีเจียน บูฮุนด์" },
  {
    id: 238,
    nameEN: "Norwegian Elkhound Black",
    nameTH: "นอร์วีเจียน เอลก์ฮาวนด์ สีดำ",
  },
  {
    id: 239,
    nameEN: "Norwegian Elkhound Grey",
    nameTH: "นอร์วีเจียน เอลก์ฮาวนด์ เกรย์",
  },
  { id: 240, nameEN: "Norwegian Hound", nameTH: "นอร์วีเจียน ฮาวนด์" },
  { id: 241, nameEN: "Norwegian Lundehund", nameTH: "นอร์วีเจียน ลาวด์ฮุนด์" },
  { id: 242, nameEN: "Norwich Terrier", nameTH: "นอร์วิช เทอร์เรีย" },
  {
    id: 243,
    nameEN: "Nova Scotia Duck Tolling Retriever",
    nameTH: "โนวา สกอเทีย ดัค ทอลลิง รีทรีฟเวอร์",
  },
  { id: 244, nameEN: "Old Danish Pointer", nameTH: "โอล เดนิช พอยเตอร์ ด็อก" },
  {
    id: 245,
    nameEN: "Old English Sheepdog (Bobtail)",
    nameTH: "โอลด์ อิงลิช ชีพด็อก (หางสั้น)",
  },
  { id: 246, nameEN: "Otterhound", nameTH: "อ็อตเตอร์ฮาวนด์" },
  {
    id: 247,
    nameEN: "Parson Russel Terrier",
    nameTH: "พาร์สัน รัสเซลล์ เทอร์เรีย",
  },
  { id: 248, nameEN: "Pekingese", nameTH: "ปักกิ่ง" },
  { id: 249, nameEN: "Peruvian Hairless Dog", nameTH: "เปรูเวียน ไร้ขน" },
  {
    id: 250,
    nameEN: "Petit Basset Griffon Vendeen",
    nameTH: "เปอตี บาสเซ็ต กริฟฟอน เวนดีน",
  },
  { id: 251, nameEN: "Petit Brabancon", nameTH: "ปอตี บราบ็องซง" },
  { id: 252, nameEN: "Pharaoh Hound", nameTH: "ฟาโรห์ ฮาวนด์" },
  { id: 253, nameEN: "Picardy Sheepdog", nameTH: "พิคาร์ดี ชีพด็อก" },
  { id: 254, nameEN: "Picardy Spaniel", nameTH: "พิคาร์ดี สแปเนียล" },
  { id: 255, nameEN: "Pit Bull Terrier", nameTH: "พิทบูล เทอร์เรีย" },
  { id: 256, nameEN: "Poitevin", nameTH: "พอยต์วิน" },
  { id: 257, nameEN: "Polish Greyhound", nameTH: "โปลิช เกรย์ฮาวนด์" },
  { id: 258, nameEN: "Polish Hound", nameTH: "โพลิช ฮาวนด์" },
  { id: 259, nameEN: "Polish Hunting Dog", nameTH: "โพลิช ฮันติ้ง ด็อก" },
  {
    id: 260,
    nameEN: "Polish Lowland Sheepdog",
    nameTH: "โพลิช โลว์แลนด์ ชีพด็อก",
  },
  {
    id: 261,
    nameEN: "Pommeranian (Zwergspitz)",
    nameTH: "ปอมเมอเรเนียน (ซแวร์กสปิตซ์)",
  },
  { id: 262, nameEN: "Pomsky", nameTH: "ปอมสกี้" },
  { id: 263, nameEN: "Pont-Audemer Spaniel", nameTH: "ปง-โอเดอแมร์ สแปเนียล" },
  { id: 264, nameEN: "Poodle", nameTH: "พุดเดิ้ล" },
  { id: 265, nameEN: "Porcelaine", nameTH: "พอร์เซอแลน" },
  { id: 266, nameEN: "Portuguese Pointer", nameTH: "โปรตุกีส พอยน์เตอร์" },
  { id: 267, nameEN: "Portuguese Sheepdog", nameTH: "โปรตุกีส ชีพด็อก" },
  {
    id: 268,
    nameEN: "Portuguese Warren Hound (Portuguese Podengo)",
    nameTH: "โปรตุกีส วอร์เรน ฮาวนด์ (โปรตุกีส  โปเดนโก)",
  },
  { id: 269, nameEN: "Portuguese Water Dog", nameTH: "โปรตุกีส วอเตอร์ ด็อก" },
  { id: 270, nameEN: "Prague Ratter", nameTH: "ปราก แรตเตอร์" },
  { id: 271, nameEN: "Pudelpointer", nameTH: "พุดเดิ้ล พอยน์เตอร์" },
  { id: 272, nameEN: "Pug", nameTH: "ปั๊ก" },
  { id: 273, nameEN: "Puli", nameTH: "ผู่หลี่" },
  { id: 274, nameEN: "Pumi", nameTH: "ปูมิ" },
  { id: 275, nameEN: "Pyrenean Mastiff", nameTH: "ไพรีเนียน มาสทิฟฟ์" },
  {
    id: 276,
    nameEN: "Pytenean Mountain Dog",
    nameTH: "ไพรีเนียน เมาน์เทน ด็อก",
  },
  {
    id: 277,
    nameEN: "Pyrenean Sheepdog, Longhaired",
    nameTH: "ไพรีเนียน ชีพด็อก ขนยาว",
  },
  {
    id: 278,
    nameEN: "Pyrenean Sheepdog, Smooth-faced",
    nameTH: "ไพรีเนียน ชีพด็อก หน้าเรียบ",
  },
  { id: 279, nameEN: "Rafeiro of Alentejo", nameTH: "ราแฟโร ออฟ อเลนเตโจ" },
  { id: 280, nameEN: "Rhodesian Ridgeback", nameTH: "โรดีเชียน ริดจ์แบ็ค" },
  { id: 281, nameEN: "Romagna Water Dog", nameTH: "โรแมกนา วอเตอร์ ด็อก" },
  {
    id: 282,
    nameEN: "Romanian Carpathian Shepherd Dog",
    nameTH: "โรมาเนียน คาร์พาเธียน เชพเพิร์ด ด็อก",
  },
  {
    id: 283,
    nameEN: "Romanian Mioritic Shepherd Dog",
    nameTH: "โรมาเนียน ไมโอริติก เชพเพิร์ด ด็อก",
  },
  { id: 284, nameEN: "Rottweiler", nameTH: "ร็อตไวเลอร์" },
  { id: 285, nameEN: "Royal Poodle", nameTH: "รอยัล พุดเดิ้ล" },
  {
    id: 286,
    nameEN: "Russian Black Terrier",
    nameTH: "รัสเซียน แบล็ค เทอร์เรียร์",
  },
  {
    id: 287,
    nameEN: "Russian Hunting Sighthound (Borzoi)",
    nameTH: "รัสเซียน ฮันติ้ง ไซท์ ฮาวนด์ (บอร์ซอย) ",
  },
  { id: 288, nameEN: "Russian Toy", nameTH: "รัสเซียน ทอย" },
  {
    id: 289,
    nameEN: "Russian-European Laika",
    nameTH: "รัสเซียน ยูโรเปียน ไลก้า",
  },
  { id: 290, nameEN: "Saarlood Wolfdog", nameTH: "ซาร์ลูส วูล์ฟด็อก" },
  { id: 291, nameEN: "Saint Bernard", nameTH: "เซนต์เบอร์นาร์ด" },
  {
    id: 292,
    nameEN: "Saint Miguel Cattle Dog",
    nameTH: "เซนต์ มิเกล แคตเทิล ด็อก",
  },
  {
    id: 293,
    nameEN: "Saint-Germain Pointer",
    nameTH: "เซนต์ เยอรมัน พอยเตอร์",
  },
  { id: 294, nameEN: "Saluki", nameTH: "ซาลูกิ" },
  { id: 295, nameEN: "Samoyed", nameTH: "ซามอยด์" },
  {
    id: 296,
    nameEN: "Save Valley Scenthound",
    nameTH: "เซฟ วอลลี่ สเคนท์ ฮาวนด์",
  },
  { id: 297, nameEN: "Schillerstovare", nameTH: "ชิลเลอร์ ชเตอวาเรอ" },
  { id: 298, nameEN: "Schipperke", nameTH: "สกิปเพอร์กี้" },
  { id: 299, nameEN: "Schnauzer", nameTH: "ชเนาเซอร์" },
  { id: 300, nameEN: "Scottish Terrier", nameTH: "สก็อตติช เทอร์เรีย" },
  { id: 301, nameEN: "Sealyham Terrier", nameTH: "ซีลีแฮม เทอร์เรีย" },
  { id: 302, nameEN: "Serbian Hound", nameTH: "เซอร์เบีย ฮาวนด์" },
  {
    id: 303,
    nameEN: "Serbian Tricolour Hound",
    nameTH: "เซอร์เบีย ฮาวนด์ สามสี",
  },
  { id: 304, nameEN: "Shar Pei", nameTH: "ชาเป่ย" },
  { id: 305, nameEN: "Shetland Sheepdog", nameTH: "เชทแลนด์ ชีพด็อก" },
  { id: 306, nameEN: "Shiba", nameTH: "ชิบะ" },
  { id: 307, nameEN: "Shih Tzu", nameTH: "ชิสุ" },
  { id: 308, nameEN: "Shikoku", nameTH: "ชิโกกุ" },
  { id: 309, nameEN: "Siberian Husky", nameTH: "ไซบีเรียน ฮัสกี" },
  { id: 310, nameEN: "Skye Terrier", nameTH: "สกาย เทอร์เรีย" },
  { id: 311, nameEN: "Slovakian Chuvach", nameTH: "สโลวาเกียน คูวัค" },
  { id: 312, nameEN: "Slovakian Hound", nameTH: "สโลวาเกียน ฮาวนด์" },
  { id: 313, nameEN: "Smalandsstovare", nameTH: "ซมาลันด์ชเตอวาเรอ" },
  { id: 314, nameEN: "Small Gascony Blue", nameTH: "สมอล กัสโคนี บลู" },
  { id: 315, nameEN: "Small Munsterlander", nameTH: "สมอล มึนสเตอร์ลานเดอร์" },
  { id: 316, nameEN: "Small Swiss Hound", nameTH: "สมอล สวิส ฮาวนด์" },
  {
    id: 317,
    nameEN: "South Russian Shepherd Dog",
    nameTH: "เซาท์ รัสเซียน เชพเพิร์ด ด็อก",
  },
  { id: 318, nameEN: "Spanish Greyhound", nameTH: "สแปนิช เกรย์ฮาวนด์" },
  { id: 319, nameEN: "Spanish Hound", nameTH: "สแปนิช ฮาวนด์" },
  { id: 320, nameEN: "Spanish Mastiff", nameTH: "สแปนิช มาสทิฟฟ์" },
  { id: 321, nameEN: "Spanish Water Dog", nameTH: "สแปนิช วอเตอร์ด็อก" },
  { id: 322, nameEN: "Spinone", nameTH: "สปิโนเน่" },
  {
    id: 323,
    nameEN: "Staffordshire Bull Terrier",
    nameTH: "สแตฟฟอร์ดเชียร์ บูล เทอร์เรีย",
  },
  {
    id: 324,
    nameEN: "Styrian Coarsehaired Hound",
    nameTH: "สตีเรียน คอร์สแฮร์ ฮาวนด์",
  },
  { id: 325, nameEN: "Sussex Spaniel", nameTH: "ซัสเซ็กซ์ สแปเนียล" },
  { id: 326, nameEN: "Swedish Lapphund", nameTH: "สวีดิช แลปป์ฮุนด์" },
  { id: 327, nameEN: "Swedish Vallhund", nameTH: "สวีดิช วาลฮุนด์" },
  { id: 328, nameEN: "Swiss Hound", nameTH: "สวิส ฮาวนด์" },
  { id: 329, nameEN: "Taiwan Dog", nameTH: "สุนัขพันธุ์ไต้หวัน" },
  { id: 330, nameEN: "Tatra Shepherd Dog", nameTH: "ทาทรา เชพเพิร์ด ด็อก" },
  { id: 331, nameEN: "Thai Ridgeback Dog", nameTH: "สุนัขพันธุ์ไทยหลังอาน" },
  { id: 332, nameEN: "Tibetan Mastiff", nameTH: "ทิเบตัน มาสทิฟฟ์" },
  { id: 333, nameEN: "Tibetan Spaniel", nameTH: "ทิเบตัน สแปเนียล" },
  { id: 334, nameEN: "Tibetan Terrier", nameTH: "ทิเบตัน เทอร์เรีย" },
  { id: 335, nameEN: "Tosa", nameTH: "โทสะ" },
  { id: 336, nameEN: "Tosa Inu", nameTH: "โทสะ อินุ" },
  { id: 337, nameEN: "Toy Poodle", nameTH: "ทอย พุดเดิ้ล" },
  { id: 338, nameEN: "Transylvanian Hound", nameTH: "ทรานซิลวาเนียน ฮาวด์" },
  { id: 339, nameEN: "Tyrolean Hound", nameTH: "ไทโรเลียน ฮาวนด์" },
  { id: 340, nameEN: "Uruguayan Cimarron", nameTH: "อุรุกวัย ซิมาร์รอน" },
  { id: 341, nameEN: "Weimaraner", nameTH: "ไวมาราเนอร์" },
  { id: 342, nameEN: "Welsh Corgi Cardigan", nameTH: "เวลช์ คอร์กี คาร์ดิแกน" },
  { id: 343, nameEN: "Welsh Corgi Pembroke", nameTH: "เวลช์ คอร์กี พ็อมโบรค " },
  {
    id: 344,
    nameEN: "Welsh Springer Spaniel",
    nameTH: "เวลช์ สปริงเกอร์ สแปเนียล",
  },
  { id: 345, nameEN: "Welsh Terrier", nameTH: "เวลช์ เทอร์เรีย" },
  {
    id: 346,
    nameEN: "West Highland White Terrier",
    nameTH: "เวสต์ ไฮแลนด์ ไวท์ เทอร์เรีย",
  },
  { id: 347, nameEN: "West Siberian Laika", nameTH: "เวสต์ ไซบีเรียน ไลก้า" },
  {
    id: 348,
    nameEN: "Westphalian Dachsbracke",
    nameTH: "เวสต์ ฟาเลียน ดัคส์บรัคเคอ",
  },
  { id: 349, nameEN: "Whippet", nameTH: "วิปเพ็ท" },
  {
    id: 350,
    nameEN: "White Swiss Shepherd Dog",
    nameTH: "ไวท์ สวิส เชพเพิร์ด ด็อก",
  },
  {
    id: 351,
    nameEN: "Wirehaired Pointing Griffon Korhals",
    nameTH: "ไวร์แฮร์ พอยทิง กริฟฟอน คอร์ทาลส์",
  },
  {
    id: 352,
    nameEN: "Wirehaired Slovakian Pointer",
    nameTH: "ไวร์แฮร์ สโลวาเกียน พอยเตอร์",
  },
  { id: 353, nameEN: "Xoloitzcuintle", nameTH: "โซโลอิตซ์คูอินทลี" },
  {
    id: 354,
    nameEN: "Yugoslavian Shepherd Dog (Sharplanina)",
    nameTH: "ยูโกสลาเวียน เชพเพิร์ด ด็อก (ชาพลานินา)",
  },
];
