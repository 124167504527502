import { Order } from "models/order";
import { authenHttpClient } from "./http-client";
import { APIResponse, SuccessResponse } from "./response";
import { Address } from "models/account";
import { Dog } from "models/mydog";

export type GetUploadSlipUrlResponse = {
  uploadUrl: string;
};

export type GetOrderResponse = {
  order: OrderResponse;
};

export type ListOrdersResponse = {
  orders: OrderResponse[];
  count: number;
};

export type OrderResponse = {} & Order;

export type InformBankTransferResponse = {};

class OrderService {
  async listOrders(): Promise<ListOrdersResponse> {
    const { data } = await authenHttpClient.get<
      APIResponse<ListOrdersResponse>
    >(`/v1/orders`);
    return data.data;
  }

  async getOrderById(orderId: string): Promise<OrderResponse> {
    const { data } = await authenHttpClient.get<APIResponse<GetOrderResponse>>(
      `/v1/orders/${orderId}`
    );
    return data.data.order;
  }

  async getAciveOrder(): Promise<OrderResponse> {
    const { data } = await authenHttpClient.get<APIResponse<GetOrderResponse>>(
      `/v1/orders/active`
    );
    return data.data.order;
  }

  async getSlipUploadUrl(orderId: string): Promise<string> {
    const { data } = await authenHttpClient.get<
      APIResponse<GetUploadSlipUrlResponse>
    >(`/v1/orders/${orderId}/slip-upload-url`);
    return data.data.uploadUrl;
  }

  async informBankTransfer(
    orderId: string
  ): Promise<InformBankTransferResponse> {
    const res = await authenHttpClient.post<
      APIResponse<InformBankTransferResponse>
    >(`/v1/orders/${orderId}/bank-transfer`, {
      orderId,
    });
    return res.data.data;
  }

  async renewOrder() {
    await authenHttpClient.post<APIResponse<SuccessResponse>>(
      `/v1/orders/renew`
    );
  }

  async changeDeliveryTime(
    orderId: string,
    date: Date
  ): Promise<SuccessResponse> {
    const res = await authenHttpClient.put<APIResponse<SuccessResponse>>(
      `/v1/orders/${orderId}/delivery`,
      {
        date,
      }
    );
    return res.data.data;
  }

  async changeDeliveryAddress(
    orderId: string,
    address: Address
  ): Promise<SuccessResponse> {
    const res = await authenHttpClient.put<APIResponse<SuccessResponse>>(
      `/v1/orders/${orderId}/address`,
      {
        address,
      }
    );
    return res.data.data;
  }

  async updateDogs(orderId: string, dogs: Dog[]): Promise<SuccessResponse> {
    const res = await authenHttpClient.put<APIResponse<SuccessResponse>>(
      `/v1/orders/${orderId}/dogs`,
      {
        dogs,
      }
    );
    return res.data.data;
  }
}

export const orderService = new OrderService();
