import ReactModal from "react-modal";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

function Modal({ isOpen, onClose, children, className }: ModalProps) {
  return (
    <ReactModal
      className={`font-mitr font-light absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white z-50 md:max-h-[95vh] ${className} ${
        isOpen
          ? "animate__animated animate__fadeIn"
          : "animate__animated animate__fadeOut"
      }`}
      overlayClassName="fixed top-0 left-0 right-0 bottom-0 backdrop-blur-xs bg-black bg-opacity-50 z-50"
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={300}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      onAfterOpen={() => {
        document.body.style.overflow = "hidden";
      }}
      onAfterClose={() => {
        document.body.style.overflow = "auto";
      }}
    >
      {children}
    </ReactModal>
  );
}

export default Modal;
