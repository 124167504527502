import { useTranslation } from "react-i18next";

type UnderlinedTextButtonProps = {
  caption: string;
  onClick: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const UnderlinedTextButton = ({
  caption,
  onClick,
  className,
}: UnderlinedTextButtonProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center">
      <div
        className={`mt-8 underline cursor-pointer font-normal text-xl ${className}`}
        onClick={onClick}
      >
        {t(caption)}
      </div>
    </div>
  );
};

export default UnderlinedTextButton;
