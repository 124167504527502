import { range } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

const yearOptions = range(0, 31).map((num) => ({
  value: num,
  label: num.toString(),
}));

const monthOptions = range(0, 12).map((num) => ({
  value: num,
  label: num.toString(),
}));

const weekOptions = range(0, 5).map((num) => ({
  value: num,
  label: num.toString(),
}));

export type AgeType = "year" | "month" | "week";

type AgeMapRange = Record<AgeType, { value: number; label: string }[]>;
type AgeMapSuffix = Record<AgeType, string>;

const ageMapRange: AgeMapRange = {
  year: yearOptions,
  month: monthOptions,
  week: weekOptions,
};

type AgeSelectProps = {
  defaultAge: number;
  type: AgeType;
  onChange: (value: number | undefined) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue" | "onChange">;

function AgeSelect({ defaultAge, type, onChange }: AgeSelectProps) {
  const { t } = useTranslation();

  const ageMapSuffix: AgeMapSuffix = {
    year: `${t("form.year.label")}`,
    month: `${t("form.month.label")}`,
    week: `${t("form.week.label")}`,
  };
  const options = ageMapRange[type];
  const defaultValue = { value: defaultAge, label: defaultAge.toString() };

  return (
    <Select
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          height: "64px",
          borderRadius: "8px",
          border: "1px solid #A0B3C1",
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 20,
        }),
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#075DEB",
          primary25: "#F5F7F9",
        },
      })}
      isClearable={false}
      defaultValue={defaultValue}
      options={options}
      onChange={(value) => {
        onChange(value?.value);
      }}
      components={{
        // DropdownIndicator: DropdownIndicator,
        SingleValue: ({ children, ...props }) => {
          return (
            <components.SingleValue {...props}>
              <div className="flex justify-center gap-x-6">
                <div>{children}</div>
                <div className="text-gray font-light">{ageMapSuffix[type]}</div>
              </div>
            </components.SingleValue>
          );
        },
        Placeholder: ({ children, ...props }) => {
          return (
            <components.Placeholder {...props}>
              {children + " Years"}
            </components.Placeholder>
          );
        },
        IndicatorSeparator: () => null,
      }}
      isSearchable={false}
    />
  );
}

export default AgeSelect;
