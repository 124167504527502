import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import th from "./locales/th.json";
import "dayjs/locale/en";
import "dayjs/locale/th";
import { getLanguage } from "features/common/storage";
import dayjs from "dayjs";
const localizedFormat = require("dayjs/plugin/localizedFormat");

const currentLang = getLanguage();

dayjs.extend(localizedFormat);
dayjs.locale(currentLang);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      th: {
        translation: th,
      },
    },
    // resources: translation,
    lng: currentLang, // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
