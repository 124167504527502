import { useTranslation } from "react-i18next";
import { ApiError } from "resources/http-client";
import ErrorText from "./ErrorText";

type APIErrorBoxProps = {
  err: ApiError | undefined;
} & React.HTMLAttributes<HTMLDivElement>;
function APIErrorBox({ err }: APIErrorBoxProps) {
  const { t } = useTranslation();
  return (
    <>
      {err && (
        <ErrorText
          error={t(`api.error.code.${err.code}`)}
          className="mt-2 text-left"
        />
      )}
    </>
  );
}

export default APIErrorBox;
