import { MenusSelectField } from "features/common/components/MenusSelect";
import Modal from "features/common/modals/Modal";
import { Delivery } from "models/account";
import { MenusSelectionFormValues } from "models/meal";
import { Dog } from "models/mydog";
import { Product } from "models/product";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type MenusSelectionModalProps = {
  delivery: Delivery;
  dogs: Dog[];
  products: Product[];
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (dogs: Dog[]) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue">;

function MenusSelectionModal({
  delivery,
  dogs,
  products,
  isOpen,
  onClose,
  onConfirm,
}: MenusSelectionModalProps) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<MenusSelectionFormValues>({
    defaultValues: {
      menus: dogs.map((dog) => dog.menus),
    },
  });

  const onSubmit: SubmitHandler<MenusSelectionFormValues> = async (data) => {
    const updatedDogs = dogs.map((dog, index) => ({
      ...dog,
      menus: data.menus[index],
    }));

    onConfirm(updatedDogs);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} className="rounded-2xl modal-lg">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col h-full max-h-full1 md:max-h-[95vh]"
      >
        <h3 className="text-2xl font-normal px-7 py-5">
          {t("component.modal.menus-selection.title")}
        </h3>
        <div className="px-7 flex-grow flex-shrink overflow-y-auto">
          <MenusSelectField
            control={control}
            deliveryFrequency={delivery.frequency}
            dogs={dogs}
            errors={errors}
            products={products}
          />
        </div>
        <div className="bg-lightGray grid grid-cols-2 gap-x-4 py-5 px-7 rounded-b-2xl">
          <input
            className="form-submit back-button button rounded-lg shadow-none border border-gray"
            type="button"
            onClick={onClose}
            value={`${t("dialog.button.cancel")}`}
          />
          <input
            className="form-submit button primary-button rounded-lg shadow-none"
            type="submit"
            value={`${t("dialog.button.confirm")}`}
          />
        </div>
      </form>
    </Modal>
  );
}

export default MenusSelectionModal;
