import { ButtonSelectOption } from "features/common/components/ButtonSelect";
import { Dog } from "./mydog";

export type UserDetailsFormValues = Pick<
  User,
  "firstName" | "lastName" | "email" | "agreeTerms"
>;

// export type UserDetails = UserDetailsFormValues;

export type DeliveryAddressFormValues = {
  deliveryDate: Date;
} & Address &
  Pick<User, "telno">;

// export type DeliveryInfo = AddressFormValues;

export type Delivery = {
  frequency: number;
  deliveryDate: Date;
};

export type UserTitle = "mr" | "mrs";

export type User = {
  // title: UserTitle;
  firstName: string;
  lastName: string;
  email: string;
  telno: string;
  agreeTerms: boolean;
};

export type Address = {
  address: string;
  subdistrict: string;
  district: string;
  province: string;
  postalCode: string;
  country: string;
  isActive: boolean;
};

export type Account = {
  dogs: Dog[];
  user: User;
  addresses: Address[];
  delivery: Delivery;
};

export type DeliveryFrequencyFormValues = {
  frequency: number;
};

export const frequencyOptions: ButtonSelectOption<number>[] = [
  {
    value: 14,
    label: "2 สัปดาห์",
  },
  {
    value: 28,
    label: "4 สัปดาห์",
  },
];

export function daysToWeeks(days: number): number {
  return Math.floor(days / 7);
}
