import { SpecialCondition, specialConditions } from "models/mydog";
import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

type SpecialConditionSelectProps = {
  defaultValue: number | null;
  onChange: (value: number | undefined) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue" | "onChange">;

function SpecialConditionSelect({
  defaultValue,
  onChange,
}: SpecialConditionSelectProps) {
  const { t, i18n } = useTranslation();

  const defaultSpecialCondition = specialConditions.find(
    (specialCondition) => specialCondition.id === defaultValue
  );

  return (
    <Select
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          height: "64px",
          borderRadius: "8px",
          border: "1px solid #A0B3C1",
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999, // Specify the desired z-index value
          fontFamily: "Mitr, sans-serif",
          fontWeight: 300,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 20,
        }),
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#075DEB",
          primary25: "#F5F7F9",
        },
      })}
      defaultValue={defaultSpecialCondition}
      getOptionLabel={(specialCondition: SpecialCondition) => {
        return i18n.language === "en"
          ? specialCondition.nameEN
          : specialCondition.nameTH;
      }}
      getOptionValue={(specialCondition: SpecialCondition) =>
        specialCondition.id.toString()
      }
      isClearable={false}
      options={specialConditions}
      onChange={(value) => {
        onChange(value?.id);
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
      isSearchable={false}
      placeholder={t("component.special-condition-select.placeholder")}
    />
  );
}

export default SpecialConditionSelect;
