import { Account } from "models/account";
import { AccountResponse } from "resources/account";

export function getLanguage() {
  return localStorage.getItem("language") ?? "th";
}

export function getToken() {
  return localStorage.getItem("token");
}

export function setToken(token: string) {
  localStorage.setItem("token", token);
}

export function removeToken() {
  localStorage.removeItem("token");
}

export function setLanguage(language: string) {
  localStorage.setItem("language", language);
}

export function setOnboarding(onboarding: Account | AccountResponse) {
  localStorage.setItem("onboarding", JSON.stringify(onboarding));
}

export function getOnboarding() {
  return localStorage.getItem("onboarding");
}

export function removeOnboarding() {
  localStorage.removeItem("onboarding");
}

export function getEmail() {
  return localStorage.getItem("email");
}

export function setEmail(email: string) {
  localStorage.setItem("email", email);
}

export function removeEmail() {
  localStorage.removeItem("email");
}

export function getStartRecentPath() {
  return localStorage.getItem("start:recentPath");
}

export function setStartRecentPath(path: string) {
  localStorage.setItem("start:recentPath", path);
}

export function removeStartRecentPath() {
  localStorage.removeItem("start:recentPath");
}
