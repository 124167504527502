import { Address } from "models/account";

type AddressInfoProps = {
  address: Address;
} & React.HTMLAttributes<HTMLDivElement>;
function AddressInfo({ address, className }: AddressInfoProps) {
  return (
    <div className={className}>
      <div>{`${address.address}`}</div>
      <div>{`${address.subdistrict} ${address.district}`}</div>
      <div>{`${address.province} ${address.postalCode}`}</div>
    </div>
  );
}

export default AddressInfo;
