import { IconBox } from "features/common/icon";
import { Delivery, daysToWeeks } from "models/account";
import { Dog, calculateTotalPrice, fillMenusToDogs } from "models/mydog";
import { Product } from "models/product";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ProductResponse } from "resources/product";
import DeliveryFrequencyModal from "./DeliveryFrequencyModal";
import MenusSelectionModal from "./MenusSelectionModal";
import { round } from "lodash";

type EditPaneProps = {
  header: string;
  button: string;
  onClick: () => void;
} & React.HTMLAttributes<HTMLDivElement>;
function SubscriptionEditPane({ header, button, onClick }: EditPaneProps) {
  return (
    <>
      <div className="flex justify-between items-center pb-4 mb-4 border-b border-b-lightGray">
        <div className="font-normal text-xl">{header}</div>
        <button className="font-normal text-blue1" onClick={onClick}>
          {button}
        </button>
      </div>
    </>
  );
}

type EditPaneDogMenuEditPaneProps = {
  dog: Dog;
  delivery: Delivery;
  products: Product[];
  onDogUpdated: (dog: Dog) => void;
} & React.HTMLAttributes<HTMLDivElement>;
function DogMenuEditPane({
  dog,
  delivery,
  products,
  onDogUpdated,
}: EditPaneDogMenuEditPaneProps) {
  const [showMenusSelectionModal, setShowMenusSelectionModal] = useState(false);
  const menuImages = dog.menus.map((menu) => menu.imageUrl);

  return (
    <div className="border-b border-b-lightGray pb-4 mb-4">
      <div className="flex justify-between items-center pb-4">
        <div className="font-normal text-xl">{dog.name}</div>
        <button
          className="font-normal text-blue1"
          onClick={() => {
            setShowMenusSelectionModal(true);
          }}
        >
          Edit
        </button>
      </div>
      <div className="text-left">
        {dog.menus &&
          dog.menus.map((menu, idx) => (
            <div key={idx} className="flex items-center gap-3">
              {menu.name} X {menu.boxNum} box ({menu.amountPerBox}g/box)
            </div>
          ))}
      </div>
      <div className="flex mt-4 gap-3">
        {menuImages &&
          menuImages.map((image, idx) => (
            <img key={idx} src={image} alt="menu" className="w-10 h-10" />
          ))}
      </div>
      <MenusSelectionModal
        delivery={delivery}
        dogs={[dog]}
        products={products}
        isOpen={showMenusSelectionModal}
        onClose={() => {
          setShowMenusSelectionModal(false);
        }}
        onConfirm={(dogs) => {
          setShowMenusSelectionModal(false);
          onDogUpdated(dogs[0]);
        }}
      />
    </div>
  );
}

type MySubscriptionPaneProps = {
  dogs: Dog[];
  delivery: Delivery;
  products: ProductResponse[];
  onDogsUpdated: (dogs: Dog[]) => void;
  onFrequencyUpdated: (frequency: number) => void;
} & React.HTMLAttributes<HTMLDivElement>;
function MySubscriptionPane({
  dogs,
  delivery,
  products,
  onDogsUpdated,
  onFrequencyUpdated,
}: MySubscriptionPaneProps) {
  const { t } = useTranslation();
  const [showDeliveryFrequencyModal, setShowDeliveryFrequencyModal] =
    useState(false);

  const activeDogs = dogs.filter((dog) => !dog.isSuspended);
  fillMenusToDogs(activeDogs, products);
  const menus = activeDogs.map((dog) => dog.menus);
  const estimatedPrice = round(
    calculateTotalPrice(menus, products) / delivery.frequency
  );

  return (
    <div>
      <div className="flex items-center gap-3">
        <IconBox className="fill-orange" />
        <div className="text-2xl font-normal">
          {t("home.dashboard.my-subscription.title")}
        </div>
      </div>
      <div className="bg-white shadow-thick rounded-xl p-5 mt-4">
        <SubscriptionEditPane
          button="Edit"
          header={`Delivery every ${daysToWeeks(delivery.frequency)} weeks`}
          onClick={() => {
            setShowDeliveryFrequencyModal(true);
          }}
        />
        {/* <SubscriptionEditPane
          button="Add"
          header="Extras"
          onClick={() => {
            alert("");
          }}
        /> */}
        {activeDogs.map((dog, idx) => (
          <DogMenuEditPane
            key={idx}
            dog={dog}
            delivery={delivery}
            products={products}
            onDogUpdated={async (updatedDog) => {
              const updatedDogs = [...dogs];
              updatedDogs[idx] = updatedDog;
              onDogsUpdated(updatedDogs);
            }}
          />
        ))}
      </div>
      <DeliveryFrequencyModal
        defaultValue={delivery.frequency}
        price={estimatedPrice}
        isOpen={showDeliveryFrequencyModal}
        onClose={() => {
          setShowDeliveryFrequencyModal(false);
        }}
        onConfirm={(frequency) => {
          setShowDeliveryFrequencyModal(false);
          onFrequencyUpdated(frequency);
        }}
      />
    </div>
  );
}

export default MySubscriptionPane;
