import "rc-slider/assets/index.css";
import { useState } from "react";

type CounterInputProps = {
  unit: string;
  defaultValue: number;
  disableIncrease: boolean;
  disableDecrease: boolean;
  onChange: (amount: number) => void;
  onIncrease: (amount: number) => void;
  onDecrease: (amount: number) => void;
};

const CounterInput = ({
  unit,
  defaultValue,
  disableIncrease,
  disableDecrease,
  onChange,
  onIncrease,
  onDecrease,
}: CounterInputProps) => {
  const [count, setCount] = useState(defaultValue);

  return (
    <div className="flex items-center justify-between space-x-4 border-t border-b border-opacity-30 border-gray rounded-md">
      <button
        disabled={disableDecrease || count === 1}
        className="px-4 py-1 bg-blue1 text-white rounded-l-md text-2xl disabled:opacity-50"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const newCount = count - 1;
          setCount(newCount);
          onChange(newCount);
          onDecrease(newCount);
        }}
      >
        -
      </button>
      <div>
        <input
          type="text"
          className="w-10 text-center"
          value={count}
          readOnly
        />
        <span className="">{unit}</span>
      </div>
      <button
        disabled={disableIncrease}
        className="px-4 py-1 bg-blue1 text-white rounded-r-md text-2xl disabled:opacity-50"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const newCount = count + 1;
          setCount(newCount);
          onChange(newCount);
          onIncrease(newCount);
        }}
      >
        +
      </button>
    </div>
  );
};

export default CounterInput;
