import imgLogoFooter from "assets/images/home/logo-footer.png";
import imgLogoHeader from "assets/images/home/logo-header.png";
import imgLogoMobile from "assets/images/logo-mobile.png";
import LanguageSwitcher from "features/common/components/LanguageSwitcher";
import ConfirmModal from "features/common/modals/ConfirmModal";
import { removeToken, setLanguage } from "features/common/storage";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

type MenuProps = {
  name: string;
  to: string;
  onClick?: () => void;
};
function Menu({ name, to, onClick }: MenuProps) {
  const { t } = useTranslation();
  return (
    <li>
      <NavLink
        className={({ isActive }) => {
          if (isActive) {
            return "text-orange";
          } else {
            return "text-white";
          }
        }}
        to={to}
        onClick={() => {
          onClick && onClick();
        }}
      >
        {t(name)}
      </NavLink>
    </li>
  );
}

function Header() {
  const { i18n } = useTranslation();
  const [menuShown, setMenuShown] = useState(false);
  const navigate = useNavigate();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setLanguage(language);
    setMenuShown(false);
  };

  return (
    <>
      <header className="bg-blue1 text-white font-normal hidden xl:block">
        <div className="flex justify-between container mx-auto items-center py-5">
          <ul className="flex justify-between gap-x-10 items-center">
            <li>
              <img
                src={imgLogoHeader}
                alt="logo-header"
                className="w-[200px] h-[60px]"
              />
            </li>
            <Menu name="home.header.menu.home" to="/home/dashboard" />
            <Menu name="home.header.menu.deliveries" to="/home/deliveries" />
            <Menu
              name="home.header.menu.subscription"
              to="/home/subscriptions"
            />
            {/* <Menu name="home.header.menu.referals" to="/home/referals" /> */}
            <Menu name="home.header.menu.history" to="/home/history" />
            <Menu name="home.header.menu.mydata" to="/home/my-data" />
          </ul>
          <ul className="flex justify-end gap-x-10">
            <LanguageSwitcher paneClass="text-black top-8 right-0" />
            <li
              className="cursor-pointer"
              onClick={() => {
                setShowLogoutDialog(true);
              }}
            >
              Logout
            </li>
          </ul>
        </div>
      </header>
      <header className="flex justify-between xl:hidden bg-blue1 text-white h-[70px] items-center px-3">
        <img src={imgLogoMobile} alt="logo" className="w-[146px] h-[44px]" />
        <div
          className="text-white text-4xl"
          onClick={() => {
            setMenuShown(!menuShown);
          }}
        >
          {menuShown ? "x" : "☰"}
        </div>
      </header>
      <ul
        className={`flex flex-col gap-y-3 items-start px-3 pyy-4 bg-blue1 shadow-thick transition-all duration-300 ease-in-out overflow-hidden ${
          menuShown ? `h-[330px]` : "h-0"
        }`}
      >
        <Menu
          name="home.header.menu.home"
          to="/home/dashboard"
          onClick={() => {
            setMenuShown(false);
          }}
        />
        <Menu
          name="home.header.menu.deliveries"
          to="/home/deliveries"
          onClick={() => {
            setMenuShown(false);
          }}
        />
        <Menu
          name="home.header.menu.subscription"
          to="/home/subscriptions"
          onClick={() => {
            setMenuShown(false);
          }}
        />
        {/* <Menu
          name="home.header.menu.referals"
          to="/home/referals"
          onClick={() => {
            setMenuShown(false);
          }}
        /> */}
        <Menu
          name="home.header.menu.history"
          to="/home/history"
          onClick={() => {
            setMenuShown(false);
          }}
        />
        <Menu
          name="home.header.menu.mydata"
          to="/home/my-data"
          onClick={() => {
            setMenuShown(false);
          }}
        />
        <div className="text-white">{i18n.language.toUpperCase()}</div>
        <ul className="flex flex-col text-white text-left gap-y-3 pl-5">
          <li
            onClick={() => {
              changeLanguage("en");
            }}
          >
            English
          </li>
          <li
            onClick={() => {
              changeLanguage("th");
            }}
          >
            ไทย
          </li>
        </ul>
        <Menu
          name="home.header.menu.logout"
          to="/home/my-data"
          onClick={() => {
            setShowLogoutDialog(true);
          }}
        />
      </ul>
      <ConfirmModal
        header="home.dialog.logout.title"
        description="home.dialog.logout.description"
        image={<div></div>}
        isOpen={showLogoutDialog}
        onClose={() => {
          setShowLogoutDialog(false);
        }}
        onConfirm={() => {
          removeToken();
          navigate("/login");
        }}
      />
    </>
  );
}

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto flex justify-between items-center border-t border-t-gray pt-4 mb-4 p-3 sm:p-0">
      <div className="text-left mt-2">
        <img
          src={imgLogoFooter}
          alt="logo-footer"
          className="w-[233px] h-[70px]"
        />
        <div className="mt-4">{t("home.footer.title")}</div>
      </div>
      <div className="text-right self-end">
        <div>{t("home.footer.need-help")}</div>
        <div className="mt-2">
          <a
            className="text-blue1"
            href="https://app.malenour.com/home/dashboard/#"
          >
            {t("home.footer.chat-with-us")}
          </a>
        </div>
      </div>
    </div>
  );
}

function HomePage() {
  return (
    <div className="h-screen flex flex-col">
      <div className="flex-1 bg-lightGray">
        <Header />
        <Outlet />
      </div>
      <div className="bg-lightGray">
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
