export function querySelectorWithTimeout(
  selector: string,
  timeout: number
): Promise<Element | void> {
  return new Promise((resolve) => {
    const interval = 100; // Interval to check the selector
    let elapsedTime = 0;
    let timeoutId: NodeJS.Timeout | null = null;

    const checkSelector = () => {
      const element = document.querySelector(selector);
      if (element) {
        clearTimeout(timeoutId!);
        resolve(element);
      } else {
        elapsedTime += interval;

        if (elapsedTime >= timeout) {
          clearTimeout(timeoutId!);
          resolve(); // Do nothing if timeout is reached
        }
      }
    };

    timeoutId = setTimeout(checkSelector, interval);
  });
}

export function scrollToElement(element: Element, offset = 0): void {
  const elementPosition =
    element.getBoundingClientRect().top + window.pageYOffset;
  const scrollOffset = elementPosition - offset;

  window.scrollTo({
    top: scrollOffset,
    behavior: "smooth",
  });
}

export function onInvalidScrollToElement() {
  querySelectorWithTimeout(".has-error", 1000).then((element) => {
    if (element) {
      scrollToElement(element, 30);
    }
  });
}

export const scrollToElementAndMakeItTop = (elementId: string) => {
  querySelectorWithTimeout(`#${elementId}`, 1000).then((element) => {
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  });
};
