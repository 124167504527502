import { omit } from "lodash";
import { Reducer } from "react";
import { Account, Address, Delivery, User } from "../../../models/account";
import { Menu } from "../../../models/meal";
import {
  ActivityHabitType,
  BodyType,
  Dog,
  DogAndFoodRestriction,
  DogAndSpecialCondition,
  DogIdAndAge,
  DogIdAndBreed,
  DogIdAndName,
  EatingHabitType,
  MainFoodType,
  SexType,
} from "../../../models/mydog";

// Inspired by https://medium.com/codex/typescript-and-react-usereducer-943e4f8d1ad4

export enum OnboardingActionType {
  ADD_DOG,
  UPDATE_DOG_NAME,
  DELETE_DOG,
  UPDATE_DOG_SEX,
  UPDATE_DOG_NEUTERED,
  UPDATE_DOG_AGE,
  UPDATE_DOG_BREED,
  UPDATE_DOG_MAIN_FOOD,
  UPDATE_DOG_EATING_HABIT,
  UPDATE_DOG_WEIGHT,
  UPDATE_DOG_BODY_TYPE,
  UPDATE_DOG_ACTIVITY_HABIT_TYPE,
  UPDATE_DOG_FOOD_RESTRICTION,
  UPDATE_DOG_SPECIAL_CONDITION,
  UPDATE_DOG_DAILY_ENERGY,
  UPDATE_USER_DETAILS,
  CHOOSE_MEAL_PLANS,
  CHOOSE_MENUS,
  UPDATE_ADDRESS,
  UPDATE_DELIVERY,
}

export type OnboardingState = Account;

export type AddDogAction = {
  type: OnboardingActionType.ADD_DOG;
  payload: Dog;
};

export type UpdateDogNameAction = {
  type: OnboardingActionType.UPDATE_DOG_NAME;
  payload: DogIdAndName;
};

export type DeleteDogAction = {
  type: OnboardingActionType.DELETE_DOG;
  payload: Partial<Dog>;
};

export type UpdateDogSexAction = {
  type: OnboardingActionType.UPDATE_DOG_SEX;
  payload: SexType[];
};

export type UpdateDogNeuteredAction = {
  type: OnboardingActionType.UPDATE_DOG_NEUTERED;
  payload: boolean[];
};

export type UpdateDogAgeAction = {
  type: OnboardingActionType.UPDATE_DOG_AGE;
  payload: DogIdAndAge[];
};

export type UpdateDogBreedAction = {
  type: OnboardingActionType.UPDATE_DOG_BREED;
  payload: DogIdAndBreed[];
};

export type UpdateDogMainFoodAction = {
  type: OnboardingActionType.UPDATE_DOG_MAIN_FOOD;
  payload: MainFoodType[];
};

export type UpdateDogEatingHabitAction = {
  type: OnboardingActionType.UPDATE_DOG_EATING_HABIT;
  payload: EatingHabitType[];
};

export type UpdateDogWeightAction = {
  type: OnboardingActionType.UPDATE_DOG_WEIGHT;
  payload: number[];
};

export type UpdateDogBodyTypeAction = {
  type: OnboardingActionType.UPDATE_DOG_BODY_TYPE;
  payload: BodyType[];
};

export type UpdateDogActivityHabitTypeAction = {
  type: OnboardingActionType.UPDATE_DOG_ACTIVITY_HABIT_TYPE;
  payload: ActivityHabitType[];
};

export type UpdateDogFoodRestrictionAction = {
  type: OnboardingActionType.UPDATE_DOG_FOOD_RESTRICTION;
  payload: DogAndFoodRestriction[];
};

export type UpdateDogSpecialConditionAction = {
  type: OnboardingActionType.UPDATE_DOG_SPECIAL_CONDITION;
  payload: DogAndSpecialCondition[];
};

export type UpdateDogDailyEnergyAction = {
  type: OnboardingActionType.UPDATE_DOG_DAILY_ENERGY;
  payload: number[];
};

export type UpdateUserDetailsAction = {
  type: OnboardingActionType.UPDATE_USER_DETAILS;
  payload: User;
};

export type ChooseMenusAction = {
  type: OnboardingActionType.CHOOSE_MENUS;
  payload: Menu[][];
};

export type UpdateAddressAction = {
  type: OnboardingActionType.UPDATE_ADDRESS;
  payload: Address;
};

export type UpdateDeliveryAction = {
  type: OnboardingActionType.UPDATE_DELIVERY;
  payload: Delivery;
};

export type OnboardingAction =
  | AddDogAction
  | UpdateDogNameAction
  | DeleteDogAction
  | UpdateDogSexAction
  | UpdateDogNeuteredAction
  | UpdateDogAgeAction
  | UpdateDogBreedAction
  | UpdateDogMainFoodAction
  | UpdateDogEatingHabitAction
  | UpdateDogWeightAction
  | UpdateDogBodyTypeAction
  | UpdateDogActivityHabitTypeAction
  | UpdateDogFoodRestrictionAction
  | UpdateDogSpecialConditionAction
  | UpdateDogDailyEnergyAction
  | UpdateUserDetailsAction
  | ChooseMenusAction
  | UpdateAddressAction
  | UpdateDeliveryAction;

export const onboardingReducer: Reducer<OnboardingState, OnboardingAction> = (
  state: OnboardingState,
  action: OnboardingAction
): OnboardingState => {
  const { type, payload } = action;

  switch (type) {
    case OnboardingActionType.ADD_DOG:
      return {
        ...state,
        dogs: [...state.dogs, payload],
      };
    case OnboardingActionType.DELETE_DOG:
      return {
        ...state,
        dogs: state.dogs.filter((dog) => dog.id !== payload.id),
      };
    case OnboardingActionType.UPDATE_DOG_NAME:
      return {
        ...state,
        dogs: state.dogs.map((dog) => {
          if (dog.id === action.payload.id) {
            return { ...dog, name: payload.name };
          } else {
            return dog;
          }
        }),
      };
    case OnboardingActionType.UPDATE_DOG_SEX:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            sex: payload[idx],
          };
        }),
      };
    case OnboardingActionType.UPDATE_DOG_NEUTERED:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            isNeutered: payload[idx],
          };
        }),
      };
    case OnboardingActionType.UPDATE_DOG_AGE:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            ageType: payload[idx].ageType,
            year: payload[idx].year,
            month: payload[idx].month,
            week: payload[idx].week,
          };
        }),
      };
    case OnboardingActionType.UPDATE_DOG_BREED:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            breedId: payload[idx].breedId,
          };
        }),
      };
    case OnboardingActionType.UPDATE_DOG_MAIN_FOOD:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            mainFoodType: payload[idx],
          };
        }),
      };
    case OnboardingActionType.UPDATE_DOG_EATING_HABIT:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            eatingHabitType: payload[idx],
          };
        }),
      };
    case OnboardingActionType.UPDATE_DOG_WEIGHT:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            weight: payload[idx],
          };
        }),
      };
    case OnboardingActionType.UPDATE_DOG_BODY_TYPE:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            bodyType: payload[idx],
          };
        }),
      };
    case OnboardingActionType.UPDATE_DOG_ACTIVITY_HABIT_TYPE:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            activityHabitType: payload[idx],
          };
        }),
      };
    case OnboardingActionType.UPDATE_DOG_FOOD_RESTRICTION:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            hasFoodRestriction: payload[idx].hasFoodRestriction,
            foodRestrictionTypes: payload[idx].foodRestrictionTypes,
          };
        }),
      };
    case OnboardingActionType.UPDATE_DOG_SPECIAL_CONDITION:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            hasSpecialCondition: payload[idx].hasSpecialCondition,
            specialConditionId: payload[idx].specialConditionId,
          };
        }),
      };
    case OnboardingActionType.UPDATE_DOG_DAILY_ENERGY:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            dailyEnergy: payload[idx],
          };
        }),
      };
    case OnboardingActionType.UPDATE_USER_DETAILS:
      return {
        ...state,
        user: payload,
      };
    case OnboardingActionType.CHOOSE_MENUS:
      return {
        ...state,
        dogs: state.dogs.map((dog, idx): Dog => {
          return {
            ...dog,
            menus: payload[idx],
          };
        }),
      };
    case OnboardingActionType.UPDATE_ADDRESS:
      return {
        ...state,
        addresses: [
          {
            // ...omit(payload, "telno", "deliveryDate"),
            // ...omit(payload, "telno"),
            ...payload,
          },
        ],
      };
    case OnboardingActionType.UPDATE_DELIVERY:
      return {
        ...state,
        delivery: payload,
      };
    default:
      return state;
  }
};
