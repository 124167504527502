import BreedSelect from "features/common/components/BreedSelect";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import EachDogName from "features/common/components/EachDogName";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { pick } from "lodash";
import { DogBreedFormValues, DogIdAndBreed } from "models/mydog";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function DogBreedPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dogs } = useOnboarding();
  const { updateDogBreed } = useOnboardingDispatch();

  const { handleSubmit, control, getValues } = useForm<DogBreedFormValues>({
    defaultValues: {
      breeds: dogs.map((dog) => pick(dog, ["id", "name", "breedId"])),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "breeds",
  });

  const updateToStore = (data: DogBreedFormValues) => {
    const breeds: DogIdAndBreed[] = data.breeds.map((dog) => ({
      id: dog.id,
      name: dog.name,
      breedId: dog.breedId,
    }));
    updateDogBreed(breeds);
  };

  const onSubmit: SubmitHandler<DogBreedFormValues> = async (data) => {
    updateToStore(data);
    navigate("/start/my-dog/6/main-food");
  };

  return (
    <Page title="start.my-dog.breed.title">
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <div key={index} className="mt-4 md:px-48 flex flex-col gap-y-4 mb-8">
            <EachDogName template="my-dog.breed" name={field.name} />
            <div className="">
              <Controller
                control={control}
                name={`breeds.${index}.breedId`}
                render={({ field: { onChange, value } }) => {
                  return (
                    <BreedSelect defaultValue={value} onChange={onChange} />
                  );
                }}
              />
            </div>
          </div>
        ))}
        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/my-dog/4/age");
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default DogBreedPage;
