type WarningBoxProps = {} & React.HTMLAttributes<HTMLDivElement>;

function WarningBox({ children, className }: WarningBoxProps) {
  return (
    <div
      className={`bg-lightOrange text-orange p-5 rounded-2xl text-left ${className}`}
    >
      {children}
    </div>
  );
}

export default WarningBox;
