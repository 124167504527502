import { useTranslation } from "react-i18next";

import SuccessBox from "features/common/components/SuccessBox";
import WarningBox from "features/common/components/WarningBox";
import {
  SpecialCondition,
  specialConditions as specialConditionsOptions,
} from "models/mydog";

type SpecialConditionRecommendationProps = {
  specialConditionId: number;
};
function SpecialConditionRecommendation({
  specialConditionId,
}: SpecialConditionRecommendationProps) {
  const { i18n } = useTranslation();
  const specialCondition = specialConditionsOptions.find((spo) => {
    return spo.id === specialConditionId;
  });

  if (!specialCondition) {
    return <></>;
  }

  const description =
    specialCondition[
      `description${i18n.language.toUpperCase()}` as keyof SpecialCondition
    ];

  if (specialCondition.showWarning) {
    return <WarningBox>{description}</WarningBox>;
  } else {
    return <SuccessBox>{description}</SuccessBox>;
  }
}

export default SpecialConditionRecommendation;
