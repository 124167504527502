import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export type MultipleButtonSelectOption<Type> = {
  label: string;
  value: Type;
};

export type ButtonSelectItemProps = {
  name: string;
  selected: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

function ButtonSelectItem({ name, selected }: ButtonSelectItemProps) {
  let itemClass = selected
    ? "bg-blue1 shadow-lg text-white"
    : "bg-white border border-gray";
  return (
    <div>
      <div
        className={`h-16 rounded-lg flex justify-center items-center cursor-pointer ${itemClass}`}
      >
        {name}
      </div>
    </div>
  );
}

type MultipleButtonSelectProps<ValueType> = {
  defaultValue: ValueType[];
  options: MultipleButtonSelectOption<ValueType>[];
  onChange: (value: ValueType[]) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue" | "onChange">;

function MultipleButtonSelect<Type>({
  defaultValue,
  options,
  onChange,
  className,
}: MultipleButtonSelectProps<Type>) {
  const initialState = defaultValue ? defaultValue : [];
  const [selectedValues, setSelectedValues] = useState<Type[]>(initialState);
  const { t } = useTranslation();

  return (
    <div className={`${className}`}>
      {options.map((option, idx) => (
        <div
          key={idx}
          onClick={() => {
            const alreadySelected = selectedValues.some(
              (value) => value === option.value
            );
            let newSelectedValues: Type[] = [];
            if (alreadySelected) {
              newSelectedValues = selectedValues.filter(
                (value) => value !== option.value
              );
            } else {
              newSelectedValues = [...selectedValues, option.value];
            }
            setSelectedValues(newSelectedValues);
            onChange(newSelectedValues);
          }}
        >
          <ButtonSelectItem
            name={t(option.label)}
            selected={selectedValues.some((value) => value === option.value)}
          />
        </div>
      ))}
    </div>
  );
}

export default MultipleButtonSelect;
