type ErrorTextProps = {
  error: any;
} & React.HTMLAttributes<HTMLDivElement>;

function ErrorText({ error, className }: ErrorTextProps) {
  return (
    <>
      {error && (
        <div
          className={`has-error text-red text-xs font-light text-left animate__animated animate__flash ${className}`}
        >
          {error}
        </div>
      )}
    </>
  );
}

export default ErrorText;
