import { useNavigate } from "react-router-dom";
import { getStartRecentPath, getToken } from "./common/storage";
import { useEffect } from "react";
import imgLogo from "assets/images/logo.png";

const SplashPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    var element = document.getElementById("logo");
    element?.classList.add(
      "animate__animated",
      "animate__pulse",
      "animate__infinite"
    );
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      const token = getToken();
      if (token) {
        navigate("/home/dashboard", { replace: true });
      } else {
        const recentPath = getStartRecentPath();
        if (recentPath) {
          navigate(recentPath, { replace: true });
        } else {
          navigate("/start/my-dog/1/name", { replace: true });
        }
      }
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <>
      <div className="flex justify-center items-center h-screen bg-blue1">
        <div id="logo">
          <img src={imgLogo} alt="logo" className="w-36 h-44 " />
        </div>
      </div>
    </>
  );
};

export default SplashPage;
