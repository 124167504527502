import ButtonSelect from "features/common/components/ButtonSelect";
import EachDogName from "features/common/components/EachDogName";
import ErrorText from "features/common/components/ErrorText";
import MultipleButtonSelect from "features/common/components/MultipleButtonSelect";
import {
  onInvalidScrollToElement,
  scrollToElementAndMakeItTop,
} from "features/common/utils";
import { useValidate } from "features/hook/useValidate";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { get, pick } from "lodash";
import {
  DogAndFoodRestriction,
  DogFoodRestrictionFormValues,
  foodRestrictionOptions,
  hasFoodRestrictionOptions,
} from "models/mydog";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function DogFoodRestrictionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dogs } = useOnboarding();
  const { updateDogFoodRestriction } = useOnboardingDispatch();
  const { validateFoodRestrictions } = useValidate();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    getValues,
  } = useForm<DogFoodRestrictionFormValues>({
    defaultValues: {
      foodRestrictions: dogs.map((dog) =>
        pick(dog, ["name", "hasFoodRestriction", "foodRestrictionTypes"])
      ),
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "foodRestrictions",
  });
  const foodRestrictions = watch("foodRestrictions");

  const updateToStore = (data: DogFoodRestrictionFormValues) => {
    const foodRestrictions: DogAndFoodRestriction[] = data.foodRestrictions.map(
      (dog) => ({
        name: dog.name,
        hasFoodRestriction: dog.hasFoodRestriction,
        foodRestrictionTypes: dog.hasFoodRestriction
          ? dog.foodRestrictionTypes
          : [],
      })
    );
    updateDogFoodRestriction(foodRestrictions);
  };

  const onSubmit: SubmitHandler<DogFoodRestrictionFormValues> = async (
    data
  ) => {
    updateToStore(data);
    navigate("/start/my-dog/12/special-condition");
  };

  return (
    <Page
      title="start.my-dog.food-restriction.title"
      subtitle="start.my-dog.food-restriction.subtitle"
    >
      <form onSubmit={handleSubmit(onSubmit, onInvalidScrollToElement)}>
        {fields.map((field, index) => (
          <div key={index} className="mt-4 mb-8 flex flex-col gap-y-4 md:px-36">
            <EachDogName template="my-dog.food-restriction" name={field.name} />
            <Controller
              control={control}
              name={`foodRestrictions.${index}.hasFoodRestriction`}
              render={({ field: { onChange, value } }) => (
                <ButtonSelect
                  options={hasFoodRestrictionOptions}
                  defaultValue={value}
                  className="grid grid-cols-2 space-x-4"
                  onChange={(yes) => {
                    onChange(yes);
                    if (yes) {
                      scrollToElementAndMakeItTop(
                        `multiple-button-select-${index}`
                      );
                    }
                  }}
                />
              )}
            />

            {foodRestrictions[index].hasFoodRestriction && (
              <div
                id={`multiple-button-select-${index}`}
                className="animate__animated animate__fadeIn animate__faster"
              >
                <h4 className="mt-4 font-normal text-xl">
                  {t("start.my-dog.food-restriction.special-conditions.title")}
                </h4>
                <Controller
                  control={control}
                  name={`foodRestrictions.${index}.foodRestrictionTypes`}
                  rules={{
                    validate: validateFoodRestrictions,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <MultipleButtonSelect
                      options={foodRestrictionOptions}
                      defaultValue={value}
                      className="grid grid-cols-2 gap-4"
                      onChange={onChange}
                    />
                  )}
                />
                <ErrorText
                  error={get(
                    errors,
                    `foodRestrictions[${index}].foodRestrictionTypes.message`
                  )}
                />
              </div>
            )}
          </div>
        ))}
        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/my-dog/10/activity-habit");
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default DogFoodRestrictionPage;
