import dayjs from "dayjs";
import AddressInfo from "features/common/components/AddressInfo";
import FormattedAmount from "features/common/components/FormattedAmount";
import Modal from "features/common/modals/Modal";
import { Account } from "models/account";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoaderFunction, useLoaderData } from "react-router";
import { accountService } from "resources/account";
import { orderService } from "resources/order";
import { Order, getTagFromOrderStatus } from "../../../models/order";
import Page from "../components/Page";
import PillButton from "../components/PillButton";
import { PDFDownloadLink } from "@react-pdf/renderer";
import OrderReportView from "../components/OrderReportView";

export const loader: LoaderFunction = async () => {
  const [me, { orders }] = await Promise.all([
    accountService.getMe(),
    orderService.listOrders(),
  ]);
  return { me, orders };
};

type OrderDetailSmallBoxProps = {
  title: string;
} & React.HTMLAttributes<HTMLDivElement>;
function OrderDetailSmallBox({ title, children }: OrderDetailSmallBoxProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-y-2">
      <div className="text-xl font-normal">{t(title)}</div>
      <div>{children}</div>
    </div>
  );
}

type OrderDetailModalProps = {
  isOpen: boolean;
  order: Order;
  me: Account;
  onClose: () => void;
  onPrint: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

function OrderDetailModal({
  isOpen,
  order,
  me,
  onClose,
  onPrint,
}: OrderDetailModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={() => {}} className="rounded-2xl modal-lg">
      <div className="flex flex-col h-full">
        <h3 className="text-xl sm:text-2xl font-normal px-7 py-4">
          {t("home.history.order.modal.title", {
            id: order.id,
          })}
        </h3>
        <div className="flex-grow flex-shrink overflow-y-auto">
          <div className="grid sm:grid-cols-2 gap-y-6 p-7">
            <OrderDetailSmallBox title="home.history.order.modal.orderBy.title">
              <div>
                {order.user.firstName} {order.user.lastName}
              </div>
              <div>{order.user.email}</div>
            </OrderDetailSmallBox>
            <OrderDetailSmallBox title="home.history.order.modal.address.title">
              <AddressInfo address={order.address} />
            </OrderDetailSmallBox>
            <OrderDetailSmallBox title="home.history.order.modal.shippingDate.title">
              {order.shippedAt
                ? `${dayjs(order.shippedAt).format("dddd DD MMMM")} (${
                    order.trackingNumber
                  })`
                : "-"}
            </OrderDetailSmallBox>
            <OrderDetailSmallBox title="home.history.order.modal.deliveryDate.title">
              {dayjs(order.deliveredAt).format("dddd DD MMMM")}
            </OrderDetailSmallBox>
          </div>
          <div className="bg-lightGray px-7 py-4 flex justify-between text-lg sm:text-xl font-normal">
            <div>
              {t("home.history.order.modal.subscription", {
                frequency: order.frequency,
              })}
            </div>
            <div>
              <FormattedAmount value={order.total} />
            </div>
          </div>
          <div className="bg-white px-7 py-4 flex flex-col gap-y-4">
            {order.dogs.map((dog) => (
              <div>
                <div className="font-normal">{dog.name}</div>
                <div>
                  {dog.menus.map((menu) => (
                    <div>
                      {menu.name} X {menu.boxNum} box ({menu.amountPerBox}g/box)
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="bg-lightGray px-7 py-4 flex justify-between text-lg sm:text-xl font-normal">
            <div>{t("home.history.order.modal.deliveryFee")}</div>
            <div>0 ฿</div>
          </div>
          <div className="grid grid-cols-2 gap-y-6 px-7 py-4 mb-4">
            <div>
              <div>
                {t("home.history.order.modal.paymentDate", {
                  paymentDate: order.paidAt
                    ? dayjs(order.paidAt).format("DD/MM/YYYY")
                    : "-",
                })}
              </div>
              {order.paidAt && (
                <div>
                  {t("home.history.order.modal.paymentOption", {
                    paymentOption: "Bank transfer",
                  })}
                </div>
              )}
            </div>
            <div>
              <div className="flex justify-between text-lg sm:text-xl">
                <div>{t("home.history.order.modal.total")}</div>
                <div className="font-normal">
                  <FormattedAmount value={order.total} />
                </div>
              </div>
              <div className="flex justify-between text-xl">
                <div>{t("home.history.order.modal.vat")}</div>
                <div className="font-normal">
                  -{/* <FormattedAmount value={order.total} /> */}
                </div>
              </div>
              <div className="flex justify-between text-xl">
                <div>{t("home.history.order.modal.totalVatIncl")}</div>
                <div className="font-normal">
                  <FormattedAmount value={order.total} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lightGray grid grid-cols-2 gap-x-4 py-5 px-7 rounded-b-2xl">
          <input
            className="form-submit back-button button rounded-lg shadow-none border border-gray"
            type="button"
            onClick={onClose}
            value={`${t("dialog.button.close")}`}
          />
          <PDFDownloadLink
            className="form-submit button primary-button rounded-lg shadow-none text-center"
            document={<OrderReportView order={order} />}
            fileName={`${order.id}.pdf`}
          >
            {() => t("dialog.button.download-invoice")}
          </PDFDownloadLink>
        </div>
      </div>
    </Modal>
  );
}

type OrderProps = {
  order: Order;
  onSelect: (order: Order) => void;
};
function OrderRow({ order, onSelect }: OrderProps) {
  return (
    <tr className="bg-white h-24 rounded-lg">
      <td className="p-2 border-b-8 border-lightGray">Order #{order.id}</td>
      <td className="p-2 border-b-8 border-lightGray">
        <div className="flex justify-center">
          {getTagFromOrderStatus(order)}
        </div>
      </td>
      <td className="p-2 border-b-8 border-lightGray">
        {dayjs(order.deliveredAt).format("DD/MM/YYYY")}
      </td>
      <td className="p-2 border-b-8 border-lightGray">
        {order.total.toLocaleString("th-TH")} ฿
      </td>
      <td className="p-2 border-b-8 border-lightGray">
        {order.total.toLocaleString("th-TH")} ฿
      </td>
      <td className="p-2 border-b-8 border-lightGray">Data 2</td>
      <td className="p-2 border-b-8 border-lightGray">
        <PillButton
          name="Details"
          onClick={() => {
            onSelect(order);
          }}
        />
      </td>
    </tr>
  );
}

function HistoryPage() {
  const { t } = useTranslation();
  const { me, orders } = useLoaderData() as {
    me: Account;
    orders: Order[];
  };
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(orders[0]);

  return (
    <Page
      title={`${t("home.history.jumbotron.title")}`}
      subtitle="home.history.jumbotron.subtitle"
    >
      <div className="overflow-x-auto">
        <table
          className="table-auto border-collapse w-full"
          style={{ borderSpacing: "0.6rem" }}
        >
          <thead>
            <tr>
              <th className="p-2">{t("home.history.table.order.col")}</th>
              <th className="p-2">{t("home.history.table.status.col")}</th>
              <th className="p-2">{t("home.history.table.date.col")}</th>
              <th className="p-2">{t("home.history.table.total.col")}</th>
              <th className="p-2">{t("home.history.table.paid.col")}</th>
              <th className="p-2">{t("home.history.table.topay.col")}</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, idx) => (
              <OrderRow
                key={idx}
                order={order}
                onSelect={(order) => {
                  setSelectedOrder(order);
                  setShowOrderDetailModal(true);
                }}
              />
            ))}
          </tbody>
        </table>
      </div>
      {orders.length !== 0 && (
        <OrderDetailModal
          isOpen={showOrderDetailModal}
          me={me}
          order={selectedOrder}
          onClose={() => setShowOrderDetailModal(false)}
          onPrint={() => {}}
        ></OrderDetailModal>
      )}
    </Page>
  );
}

export default HistoryPage;
