// import scbLogo from "assets/images/bank/scb.png";
import ktbLogo from "assets/images/bank/ktb.png";
import imgUploadSlip from "assets/images/start/checkout/summary/upload-slip.png";
import axios from "axios";
import Clipboard from "clipboard";
import MenuGenerationSpinner from "features/start/components/MenuGenerationSpinner";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { IconBank } from "../icon";

export type SlipUploadState =
  | "idle"
  | "uploading"
  | "verifying"
  | "verified"
  | "error";

type BankTransferPaymentViewProps = {
  onUploaded: () => Promise<void>;
  getUploadUrl: () => Promise<string>;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue" | "onChange">;

function BankTransferPaymentView({
  onUploaded,
  getUploadUrl,
  className,
}: BankTransferPaymentViewProps) {
  const { t } = useTranslation();
  const [slipState, setSlipState] = useState<SlipUploadState>("idle");
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const uploadUrl = await getUploadUrl();
    const file = acceptedFiles[0];

    try {
      setSlipState("uploading");
      await axios.put(uploadUrl, acceptedFiles[0], {
        headers: {
          "Content-Type": file.type,
        },
      });
      setSlipState("verifying");
      await onUploaded();
      setSlipState("verified");
    } catch (error) {
      setSlipState("error");
      console.error(error);
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    // https://github.com/react-dropzone/react-dropzone/issues/1196
    noClick: true,
    onDrop,
    accept: { "image/*": [] },
    maxFiles: 1,
  });

  const bankAccount = process.env.REACT_APP_BANK_TRANSFER_ACCOUNT!;

  return (
    <div
      id="bankTransferPaymentView"
      className={`border-2 border-blue1 rounded-lg py-4 px-5 flex flex-col gap-y-5 ${className}`}
    >
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-normal">
          {t("home.component.bank-transfer.title")}
        </h3>
        <div className="fill-blue1 w-8 h-8 border border-lightGray flex justify-center items-center rounded-md">
          <IconBank />
        </div>
      </div>
      <div className="flex justify-between bg-blue1 items-center p-5 rounded-lg">
        <div className="flex rounded-lg gap-x-3">
          <img src={ktbLogo} alt="ktb" className="w-12 h-12" />
          <div className="text-white text-left">
            <div className="text-xl font-normal">KTB</div>
            <div className="font-normal">{bankAccount}</div>
          </div>
        </div>
        <div>
          <button
            id="btnCopy"
            className="text-blue1 border border-blue1 bg-white rounded-[32px] px-4 py-2"
            onClick={() => {
              const clipboard = new Clipboard("#btnCopy", {
                text: () => bankAccount,
              });

              clipboard.on("success", () => {
                alert("คัดลอกข้อมูลเรียบร้อย");
                clipboard.destroy();
              });

              clipboard.on("error", () => {
                alert("ไม่สามารถคัดลอกข้อมูลได้ โปรดดำเนินการด้วยตนเอง");
                clipboard.destroy();
              });
            }}
          >
            {t("dialog.button.copy")}
          </button>
        </div>
      </div>
      <div
        {...getRootProps()}
        className="rounded-lg border border-dashed border-blue1 bg-lightGray border-separate border-spacing-4 py-10 flex flex-col gap-y-5 items-center xl:mx-20"
      >
        <div className="w-16 h-16 bg-white rounded-full flex justify-center items-center">
          <img src={imgUploadSlip} alt="scb" className="w-9 h-8" />
        </div>
        <input {...getInputProps()} />
        <div
          className={`${slipState === "error" && "text-red"} ${
            slipState === "verified" && "text-green"
          }`}
        >
          {t(`home.component.bank-transfer.dropzone.${slipState}.description`)}
        </div>
        <button
          className={`button primary-button h-10 py-2 ${
            slipState !== "idle" && slipState !== "error" && "hidden"
          }`}
          type="button"
          onClick={open}
        >
          {t("home.component.bank-transfer.button")}
        </button>
        {slipState === "uploading" ||
          (slipState === "verifying" && (
            <MenuGenerationSpinner className="z-0" />
          ))}
      </div>
    </div>
  );
}

export default BankTransferPaymentView;
