import Input from "features/common/components/Input";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  LoaderFunction,
  redirect,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { anonymousService } from "resources/anonymous";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getEmail,
  removeEmail,
  removeOnboarding,
  removeStartRecentPath,
  setToken,
} from "features/common/storage";

export const loader: LoaderFunction = async () => {
  let needRedirect = false;
  const email = getEmail();
  if (!email) {
    needRedirect = true;
    return needRedirect;
  }

  const { verified } = await anonymousService.doesEmailExist(email);
  if (verified) {
    needRedirect = true;
  }

  return needRedirect;
};

export type CreateAccountFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

function CreateAccountPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const needRedirect = useLoaderData() as boolean;

  if (needRedirect) {
    redirect("/home/dashboard");
  }

  const formSchema = yup.object().shape({
    password: yup
      .string()
      .required(`${t("form.validation.required")}`)
      .min(8, `${t("form.validation.tooshort")}`),
    confirmPassword: yup
      .string()
      .required(`${t("form.validation.required")}`)
      .oneOf(
        [yup.ref("password")],
        `${t("form.validation.password-not-match")}`
      ),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateAccountFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: getEmail() || "",
    },
  });

  const onSubmit: SubmitHandler<CreateAccountFormValues> = async (data) => {
    const token = await anonymousService.createPassword({
      email: data.email,
      password: data.password,
      code: queryParams.get("code") || "",
    });
    removeEmail();
    removeOnboarding();
    removeStartRecentPath();
    setToken(token);
    navigate("/home/dashboard");
  };

  return (
    <Page
      title="start.create-account.title"
      subtitle="start.create-account.subtitle"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4 md:px-24 flex flex-col gap-y-3">
          <div className="grid sm:grid-cols-5 gap-2 sm:gap-6">
            <Input
              disabled
              className="sm:col-span-3 sm:col-start-2"
              label="start.create-account.form.email.label"
              placeholder="start.create-account.form.email.label"
              {...register("email")}
              errors={errors}
            />
          </div>
          <div className="grid sm:grid-cols-5 gap-2 sm:gap-6">
            <Input
              className="sm:col-span-3 sm:col-start-2"
              type="password"
              label="start.create-account.form.password.label"
              placeholder="start.create-account.form.password.label"
              {...register("password")}
              errors={errors}
            />
          </div>
          <div className="grid sm:grid-cols-5 gap-2 sm:gap-6">
            <Input
              className="sm:col-span-3 sm:col-start-2"
              type="password"
              label="start.create-account.form.password-again.label"
              placeholder="start.create-account.form.password-again.label"
              {...register("confirmPassword")}
              errors={errors}
            />
          </div>
        </div>
        <BottomNavigationBar>
          <div className="flex justify-end w-full">
            <input
              className="form-submit button primary-button"
              type="submit"
              value={`${t("start.navbuttonbar.create-account")}`}
            />
          </div>
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default CreateAccountPage;
