import { useTranslation } from "react-i18next";

type TagProps = {
  tag: string;
  color: string;
  className?: string;
};
function Tag({ tag, color, className = "" }: TagProps) {
  const { t } = useTranslation();

  return (
    <div
      className={`rounded-md bg-${color} bg-opacity-10 text-${color} p-2 ${className}`}
    >
      {t(tag)}
    </div>
  );
}
export default Tag;
