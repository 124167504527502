import imgAge from "assets/images/start/menu/recommended-portion/age.png";
import imgDogAvatar from "assets/images/start/menu/recommended-portion/dog-avatar.png";
import imgForbidden from "assets/images/start/menu/recommended-portion/forbidden.png";
import imgWeight from "assets/images/start/menu/recommended-portion/weight.png";
import Tag from "features/common/components/Tag";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  Dog,
  SexType,
  SpecialCondition,
  calculateFoodAmountPerBox,
  getActivityHabitTypeLabelByValue,
  getBodyTypeLabelByValue,
  getEatingHabitTypeLabelByValue,
  getFoodRestrictionTypeLabelByValue,
  specialConditions,
} from "models/mydog";
import { useOnboarding } from "features/start/state/context";
import { useTranslation } from "react-i18next";
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom";
import { Breed, breeds } from "models/dog-breed";
import { productService } from "resources/product";
import { Product } from "models/product";
import { round } from "lodash";

export const loader: LoaderFunction = async () => {
  const products = await productService.getProducts();
  return { products };
};

type SexIconProps = {
  sex: SexType;
};
function SexIcon({ sex }: SexIconProps) {
  if (sex === "boy") {
    return <span>{"\u2642"}</span>;
  } else {
    return <span>{"\u2640"}</span>;
  }
}

type ForbiddenTagProps = {
  tag: string;
};
function ForbiddenTag({ tag }: ForbiddenTagProps) {
  return (
    <div className="rounded-md bg-white text-orange p-2 flex gap-x-2 items-center">
      <img src={imgForbidden} alt="forbidden" className="w-5 h-5" />
      {tag}
    </div>
  );
}

type RecommendedDailyPortionProps = {
  dog: Dog;
  averageFoodEnergy: number;
};
function RecommendedDailyPortionBox({
  dog,
  averageFoodEnergy,
}: RecommendedDailyPortionProps) {
  const { i18n, t } = useTranslation();
  const breed = breeds[dog.breedId][
    `name${i18n.language.toUpperCase()}` as keyof Breed
  ] as string;

  let specialCondition = "";
  if (dog.specialConditionId) {
    specialCondition = specialConditions[dog.specialConditionId - 1][
      `name${i18n.language.toUpperCase()}` as keyof SpecialCondition
    ] as string;
  }

  return (
    <div className="bg-lightGray rounded-lg px-5 py-4 flex flex-col gap-y-4">
      <div className="font-normal flex gap-x-3 justify-start items-center">
        <div className="bg-white rounded-full px-3 py-4">
          <img src={imgDogAvatar} alt="dog avatar" className="w-10 h-8" />
        </div>
        <div className="flex flex-col gap-y-2 items-start">
          <div className="text-2xl">
            {dog.name} <SexIcon sex={dog.sex!} />
          </div>
          <div className="flex gap-x-4 font-light">
            <div className="flex gap-x-2">
              <img src={imgWeight} alt="weight" className="w-5 h-5" />
              <span>{dog.weight} kg</span>
            </div>
            <div className="flex gap-x-2">
              <img src={imgAge} alt="weight" className="w-5 h-5" />
              <span>
                {dog.year},{dog.month} yo
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-2 font-light">
        <Tag tag={breed} color="blue1" />
        <Tag tag={t(getBodyTypeLabelByValue(dog.bodyType))} color="blue1" />
        <Tag
          tag={t(getActivityHabitTypeLabelByValue(dog.activityHabitType))}
          color="blue1"
        />
        <Tag
          tag={t(getEatingHabitTypeLabelByValue(dog.eatingHabitType))}
          color="blue1"
        />
      </div>
      <div className="flex flex-wrap gap-2 font-light">
        {dog.foodRestrictionTypes &&
          dog.foodRestrictionTypes.map((foodRestrictionType, index) => (
            <ForbiddenTag
              key={index}
              tag={t(getFoodRestrictionTypeLabelByValue(foodRestrictionType))}
            />
          ))}
        {specialCondition && <ForbiddenTag tag={specialCondition} />}
      </div>
      <div className="rounded-lg bg-blue1 py-3 px-5 text-white text-left">
        <div>Needs per day : {dog.dailyEnergy} kcal </div>
        <div>
          Meaning about{" "}
          {calculateFoodAmountPerBox(dog.dailyEnergy, averageFoodEnergy)} grams
          of Malenour food
        </div>
      </div>
    </div>
  );
}

function MenuRecommendedDailyPortionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { dogs } = useOnboarding();
  const { products } = useLoaderData() as {
    products: Product[];
  };

  const totalEnergy = products.reduce(
    (sum, product) => sum + product.energy,
    0
  );
  const averageEnergy = round(totalEnergy / products.length);

  return (
    <Page
      title="start.menu.recommended-daily-portion.title"
      subtitle="start.menu.recommended-daily-portion.subtitle"
    >
      <div className="grid sm:grid-cols-5 gap-y-4 mt-8">
        {dogs.map((dog) => (
          <div key={dog.id} className="col-span-3 col-start-2">
            <RecommendedDailyPortionBox
              dog={dog}
              averageFoodEnergy={averageEnergy}
            />
          </div>
        ))}
      </div>
      <BottomNavigationBar>
        <input
          className="form-submit back-button button"
          type="button"
          onClick={() => {
            navigate("/start/menu/user-details");
          }}
          value={`${t("start.navbuttonbar.back")}`}
        />
        <input
          className="form-submit button primary-button"
          type="button"
          onClick={() => {
            navigate("/start/menu/delivery-frequency");
          }}
          value={`${t("start.navbuttonbar.choose-delivery-frequency")}`}
        />
      </BottomNavigationBar>
    </Page>
  );
}

export default MenuRecommendedDailyPortionPage;
