import { Order, OrderStatus } from "models/order";
import { authenHttpClient } from "./http-client";
import { APIResponse } from "./response";

export type ListOrderRequest = {
  search?: string;
  status?: OrderStatus;
};

export type MarkAsShippedRequest = {
  trackingNumber: string;
};

export type GetUploadSlipUrlResponse = {
  uploadUrl: string;
};

export type ListOrdersResponse = {
  orders: OrderResponse[];
  count: number;
};

export type OrderResponse = {} & Order;

class AdminService {
  async listOrders(req: ListOrderRequest): Promise<ListOrdersResponse> {
    const { data } = await authenHttpClient.get<
      APIResponse<ListOrdersResponse>
    >(`/v1/admin/orders`, {
      params: req,
    });
    return data.data;
  }

  async markOrderAsPaid(orderId: string): Promise<void> {
    await authenHttpClient.put<APIResponse<ListOrdersResponse>>(
      `/v1/admin/orders/${orderId}/mark-as-paid`
    );
  }

  async markOrderAsShipped(
    orderId: string,
    req: MarkAsShippedRequest
  ): Promise<void> {
    await authenHttpClient.put<APIResponse<ListOrdersResponse>>(
      `/v1/admin/orders/${orderId}/mark-as-shipped`,
      req
    );
  }
}

export const adminService = new AdminService();
