import { useEffect } from "react";
import { Outlet, useLocation, useNavigation } from "react-router-dom";

import "./App.css";
import "animate.css/animate.min.css";
import "./i18n";
import LoadingSpinner from "features/start/components/LoadingSpinner";

function App() {
  const location = useLocation();
  const { state } = useNavigation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App font-mitr font-light text-black">
      {state === "loading" && <LoadingSpinner />}
      <Outlet></Outlet>
    </div>
  );
}

export default App;
