import dayjs from "dayjs";
import AddressInfo from "features/common/components/AddressInfo";
import BankTransferPaymentView from "features/common/components/BankTransferPaymentView";
import FormattedAmount from "features/common/components/FormattedAmount";
import { IconCalendar, IconMobilePhone, IconTruck } from "features/common/icon";
import {
  querySelectorWithTimeout,
  scrollToElementAndMakeItTop,
} from "features/common/utils";
import { Account, Delivery, daysToWeeks } from "models/account";
import { calculateTotalPrice, dogsToMenus } from "models/mydog";
import { Order, getTagFromOrderStatus } from "models/order";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom";
import { accountService } from "resources/account";
import { orderService } from "resources/order";
import DeliveryFrequencyModal from "../components/DeliveryFrequencyModal";
import Page from "../components/Page";
import PillButton from "../components/PillButton";
import { productService } from "resources/product";
import { Product } from "models/product";
import { round } from "lodash";

export const loader: LoaderFunction = async () => {
  const [me, { orders }, products] = await Promise.all([
    accountService.getMe(),
    orderService.listOrders(),
    productService.getProducts(),
  ]);
  return { me, orders, products };
};

type FrequencyPaneProps = {
  delivery: Delivery;
  price: number;
} & React.HTMLAttributes<HTMLDivElement>;
function FrequencyPane({ delivery, price }: FrequencyPaneProps) {
  const { t } = useTranslation();
  const [showDeliveryFrequencyDialog, setShowDeliveryFrequencyDialog] =
    useState(false);
  const [deliveryFrequency, setDeliveryFrequency] = useState(
    delivery.frequency
  );

  return (
    <>
      <div className="text-lg font-normal mb-5">
        {t("home.deliveries.frequency.title", {
          frequency: daysToWeeks(deliveryFrequency),
        })}

        <span
          onClick={() => {
            setShowDeliveryFrequencyDialog(true);
          }}
          className="underline cursor-pointer text-blue1 ml-2"
        >
          ({t("home.deliveries.frequency.button")})
        </span>
      </div>
      <DeliveryFrequencyModal
        defaultValue={deliveryFrequency}
        price={price}
        isOpen={showDeliveryFrequencyDialog}
        onClose={() => {
          setShowDeliveryFrequencyDialog(false);
        }}
        onConfirm={(frequency) => {
          setShowDeliveryFrequencyDialog(false);
          setDeliveryFrequency(frequency);
        }}
      />
    </>
  );
}

const changeablePeriod: number = parseInt(
  process.env.REACT_APP_ORDER_CHANGEABLE_PERIOD || "",
  10
);

type DeliveryPaneProps = {
  order: Order;
  delivery: Delivery;
  onClick: () => void;
} & React.HTMLAttributes<HTMLDivElement>;
function DeliveryPane({ order, delivery, onClick }: DeliveryPaneProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const aggregatedMenus = dogsToMenus(order.dogs);
  const [showBankTransferPayment, setShowBankTransferPayment] = useState(false);
  const changeableUntil = dayjs(order.deliveredAt).subtract(
    changeablePeriod,
    "day"
  );
  const tag = getTagFromOrderStatus(order, "inline");

  return (
    <>
      <div className="flex text-2xl font-normal items-center gap-x-5 mt-8">
        <IconCalendar className="fill-orange w-6 h-6" />
        {dayjs(order.deliveredAt).format("MMMM YYYY")}
      </div>
      <div className="bg-white shadow-thick rounded-xl p-8 mt-2 flex flex-col gap-y-4">
        <div className="text-left font-normal">
          {dayjs(order.deliveredAt).format("dddd DD")}
        </div>
        <div className="grid md:grid-cols-4 gap-x-4 gap-y-4 mt-5">
          <div className="md:col-span-2 grid md:grid-cols-2 sm:gap-5 gap-y-3">
            {aggregatedMenus.map((menu) => (
              <div key={menu.menu.id} className="flex gap-x-4 items-start">
                <img
                  src={menu.menu.imageUrl}
                  className="w-16 h-16 object-cover"
                  alt={menu.menu.name}
                />
                <div className="text-left">
                  <div className="font-normal">{menu.menu.name}</div>
                  <div className="flex flex-col gap-2 mt-2">
                    {menu.dogs.map((dog, idx) => (
                      <div
                        key={idx}
                        className="flex gap-x-3 text-xs bg-lightGray py-1 px-2 rounded-lg"
                      >
                        {t("home.deliveries.delivery-pane.grams-for.label", {
                          boxNum: dog.menus.find(
                            (m) => m.name === menu.menu.name
                          )!.boxNum,
                          amount: dog.menus.find(
                            (m) => m.name === menu.menu.name
                          )!.amountPerBox,
                          dogName: dog.name,
                        })}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="md:col-span-2 md:flex md:justify-end">
            <div className="flex flex-col text-left">
              <div className="font-normal flex items-center gap-x-2">
                <IconTruck />
                {t("home.deliveries.delivery-pane.delivery-details.label")}
              </div>
              <AddressInfo address={order.address} />
              <div className="flex items-center gap-x-2 mt-3">
                <IconCalendar className="w-4 h-4" />
                {dayjs(order.deliveredAt).format("dddd DD MMMM")}
              </div>
              <div className="flex items-center gap-x-2">
                <IconMobilePhone className="h-4" />
                {order.user.telno}
              </div>
              {order.trackingNumber && (
                <div className="flex items-center gap-x-2">
                  Tracking Number: {order.trackingNumber}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-4 bg-lightGray p-5 rounded-lg mt-8">
          <div className="sm:col-span-2 flex flex-col gap-4 text-left">
            <div className="font-normal">
              {t("home.deliveries.delivery-pane.your-box.title")} :
            </div>
            <div className="">
              {t("home.deliveries.delivery-pane.your-box.description", {
                dayNum: order.frequency,
                dogNum: order.dogs.length,
              })}
            </div>
            <div className="">
              <span className="font-normal">
                {t("home.deliveries.delivery-pane.your-box.delivery.label")}:{" "}
              </span>
              <span>0 ฿</span>
              <span className="font-normal">
                {" "}
                | {t(
                  "home.deliveries.delivery-pane.your-box.total.label"
                )}:{" "}
              </span>
              <span>
                <FormattedAmount value={order.total} />
              </span>
              {}
            </div>
          </div>
          <div className="sm:col-span-2">
            <div className="grid md:grid-cols-3">
              <div className="md:col-span-1 md:col-start-3 text-right">
                <PillButton
                  name={
                    order.status === "pending"
                      ? "button.view-edit"
                      : "button.view"
                  }
                  onClick={() => {
                    navigate(`/home/deliveries/${order.id}`);
                  }}
                />
                {order.status === "pending" ? (
                  <PillButton
                    name="button.paynow"
                    onClick={() => {
                      setShowBankTransferPayment(true);
                      querySelectorWithTimeout(
                        "#bankTransferPaymentView",
                        1000
                      ).then((element) => {
                        if (element) {
                          scrollToElementAndMakeItTop(
                            "bankTransferPaymentView"
                          );
                        }
                      });
                    }}
                  />
                ) : (
                  <div className="mt-4">{tag}</div>
                )}
              </div>
            </div>
            {showBankTransferPayment && (
              <BankTransferPaymentView
                className="mt-4 animate__animated animate__fadeIn"
                getUploadUrl={async () => {
                  const uploadUrl = await orderService.getSlipUploadUrl(
                    order.id
                  );
                  return uploadUrl;
                }}
                onUploaded={async () => {
                  await orderService.informBankTransfer(order.id);
                  window.location.reload();
                }}
              />
            )}
          </div>
        </div>
        <div className="mt-3 flex justify-end gap-x-2">
          {t("home.deliveries.delivery-pane.order-edit-warning")}
          <span className="font-normal">
            {changeableUntil.format("DD/MM/YYYY")}
          </span>
        </div>
      </div>
    </>
  );
}

function DeliveriesPage() {
  const { t } = useTranslation();
  const { me, orders, products } = useLoaderData() as {
    me: Account;
    orders: Order[];
    products: Product[];
  };

  const menus = me.dogs
    .filter((dog) => !dog.isSuspended)
    .map((dog) => dog.menus);
  const estimatedPrice = round(
    calculateTotalPrice(menus, products) / me.delivery.frequency
  );

  return (
    <Page
      title={`${t("home.deliveries.jumbotron.title")}`}
      subtitle="home.deliveries.jumbotron.subtitle"
    >
      <FrequencyPane delivery={me.delivery} price={estimatedPrice} />
      {orders.map((order) => (
        <DeliveryPane
          key={order.id}
          delivery={me.delivery}
          order={order}
          onClick={() => {}}
        />
      ))}
      <button
        className="form-submit button primary-button mt-5"
        onClick={async () => {
          await orderService.renewOrder();
          window.location.reload();
        }}
      >
        สร้างออเดอร์ใหม่
      </button>
    </Page>
  );
}

export default DeliveriesPage;
