import FormattedAmount from "features/common/components/FormattedAmount";
import { MenusSelectField } from "features/common/components/MenusSelect";
import { onInvalidScrollToElement } from "features/common/utils";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { round } from "lodash";
import { MenusSelectionFormValues } from "models/meal";
import { Dog, calculateTotalPrice } from "models/mydog";
import { Product } from "models/product";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom";
import { anonymousService } from "resources/anonymous";
import { productService } from "resources/product";

type TotalPriceStripProps = {
  totalPrice: number;
  dogNum: number;
  frequency: number;
};
export function TotalPriceStrip({
  totalPrice,
  dogNum,
  frequency,
}: TotalPriceStripProps) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        background:
          "linear-gradient(270deg, #FFF 0%, #075DEB 49.47%, #FFF 100%)",
      }}
      className="fixed left-0 bottom-[86px] w-full xl:w-3/4 py-4 z-10 text-white text-xl font-normal uppercase flex justify-center gap-x-2"
    >
      ~
      <FormattedAmount value={round(totalPrice / frequency)} />
      <span>
        {t("component.menus-selection.totalPrice.label", {
          dogNum: dogNum,
        })}
      </span>
    </div>
  );
}

export const loader: LoaderFunction = async () => {
  const productsRes = await productService.getProducts();
  // FIXME: calculate the amoun to each menu here....
  const products: Product[] = productsRes.map((product) => ({
    ...product,
  }));

  return products;
};

export function MenuSelectionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chooseMenus } = useOnboardingDispatch();
  const { user, dogs, addresses, delivery } = useOnboarding();

  const products = useLoaderData() as Product[];
  const defaultMenus = dogs.map((dog) => dog.menus);

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
  } = useForm<MenusSelectionFormValues>({
    defaultValues: {
      menus: defaultMenus,
    },
  });

  const onSubmit: SubmitHandler<MenusSelectionFormValues> = async (data) => {
    const mappedDogs = dogs.map((dog, index): Dog => {
      return {
        ...dog,
        menus: data.menus[index],
      };
    });
    await anonymousService.saveAccountWithMealPlan({
      user,
      addresses,
      dogs: mappedDogs,
      delivery,
    });
    chooseMenus(data.menus);
    navigate("/start/delivery");
  };

  const menus = watch("menus");
  const totalPrice = calculateTotalPrice(menus, products);

  return (
    <Page
      title="start.menu.recommended-recipes.title"
      subtitle="start.menu.recommended-recipes.subtitle"
    >
      <form onSubmit={handleSubmit(onSubmit, onInvalidScrollToElement)}>
        <div className="pb-12">
          <MenusSelectField
            control={control}
            deliveryFrequency={delivery.frequency}
            dogs={dogs}
            errors={errors}
            products={products}
          />
        </div>

        <TotalPriceStrip
          totalPrice={totalPrice}
          dogNum={dogs.length}
          frequency={delivery.frequency}
        />

        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              chooseMenus(getValues().menus);
              navigate("/start/menu/delivery-frequency");
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}
