import ImageSelect from "features/common/components/ImageSelect";
import { useTranslation } from "react-i18next";

import EachDogName from "features/common/components/EachDogName";
import WarningBox from "features/common/components/WarningBox";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { pick } from "lodash";
import {
  ActivityHabitType,
  DogActivityHabitTypeFormValues,
  activityHabitTypeOptions,
} from "models/mydog";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";

function DogActivityHabitTypePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateDogActivityHabitType } = useOnboardingDispatch();
  const { dogs } = useOnboarding();

  const { handleSubmit, control, watch, getValues } =
    useForm<DogActivityHabitTypeFormValues>({
      defaultValues: {
        activityHabitTypes: dogs.map((dog) =>
          pick(dog, ["id", "name", "activityHabitType"])
        ),
      },
    });
  const { fields } = useFieldArray({
    control,
    name: "activityHabitTypes",
  });

  const updateToStore = (data: DogActivityHabitTypeFormValues) => {
    const activityHabitTypes = data.activityHabitTypes.map(
      (dog) => dog.activityHabitType!
    );
    updateDogActivityHabitType(activityHabitTypes);
  };

  const onSubmit: SubmitHandler<DogActivityHabitTypeFormValues> = async (
    data
  ) => {
    updateToStore(data);
    navigate("/start/my-dog/11/food-restriction");
  };

  const activityHabitTypes = watch("activityHabitTypes");

  return (
    <Page title="start.my-dog.activity-type.subtitle">
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <div key={index} className="mb-8 flex flex-col gap-y-4 md:px-24">
            <EachDogName template="my-dog.activity-type" name={field.name} />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name={`activityHabitTypes.${index}.activityHabitType`}
              render={({ field: { onChange, value } }) => (
                <ImageSelect<ActivityHabitType>
                  className="grid grid-cols-2 sm:grid-cols-3 gap-4"
                  options={activityHabitTypeOptions}
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
            <WarningBox className="sm:mx-28 text-left">
              {activityHabitTypes[index].activityHabitType}:&nbsp;
              {t(
                `component.activity-habit-type-select.${activityHabitTypes[index].activityHabitType}.description`,
                {
                  name: activityHabitTypes[index].name,
                }
              )}
            </WarningBox>
          </div>
        ))}
        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/my-dog/9/body-type");
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default DogActivityHabitTypePage;
