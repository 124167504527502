import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { Order } from "../../../models/order";
import { Address } from "models/account";
import dayjs from "dayjs";
import FormattedAmount from "features/common/components/FormattedAmount";

type OrderReportViewProps = {
  order: Order;
} & React.HTMLAttributes<HTMLDivElement>;

// const fontUrl = "https://fonts.googleapis.com/css?family=Mitr";
// https://github.com/diegomura/react-pdf/issues/1075
Font.register({
  family: "Mitr",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8fMZFJDUc1NECPY.ttf",
      fontWeight: 200,
    },
    {
      src: "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8ZcaFJDUc1NECPY.ttf",
      fontWeight: 300,
    },
    {
      src: "https://fonts.gstatic.com/s/mitr/v11/pxiLypw5ucZFyTsyMJj_b1o.ttf",
      fontWeight: 400,
    },
    {
      src: "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8c8bFJDUc1NECPY.ttf",
      fontWeight: 500,
    },
    {
      src: "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8eMcFJDUc1NECPY.ttf",
      fontWeight: 600,
    },
    {
      src: "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8YcdFJDUc1NECPY.ttf",
      fontWeight: 700,
    },
  ],
});

// Font.register({ src: fontUrl, family: "Mitr" });

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    fontFamily: "Mitr",
    fontWeight: "light",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    width: "50%",
    padding: "20px",
  },
  strip: {
    width: "50%",
    padding: "20px",
  },
});

type OrderDetailSmallBoxProps = {
  title: string;
} & React.HTMLAttributes<HTMLDivElement>;
function OrderDetailSmallBox({ title, children }: OrderDetailSmallBoxProps) {
  const { t } = useTranslation();
  return (
    <View style={styles.column}>
      <Text style={{ fontSize: "20px", fontWeight: "normal" }}>{t(title)}</Text>
      <View>{children}</View>
    </View>
  );
}

type AddressInfoProps = {
  address: Address;
} & React.HTMLAttributes<HTMLDivElement>;
function AddressInfo({ address, className }: AddressInfoProps) {
  return (
    <View style={{ display: "flex", flexDirection: "column" }}>
      <Text>{`${address.address}`}</Text>
      <Text>{`${address.subdistrict} ${address.district}`}</Text>
      <Text>{`${address.province} ${address.postalCode}`}</Text>
    </View>
  );
}

type SummaryStripProps = {
  text: string;
  number: number;
} & React.HTMLAttributes<HTMLDivElement>;
function SummaryStrip({ text, number }: SummaryStripProps) {
  return (
    <View style={{ ...styles.container, backgroundColor: "#F5F7F9" }}>
      <View style={styles.column}>
        <Text style={{ fontWeight: "normal" }}>{text}</Text>
      </View>
      <View style={styles.column}>
        <Text style={{ textAlign: "right", fontWeight: "bold" }}>
          {<FormattedAmount value={number} />}
        </Text>
      </View>
    </View>
  );
}

function OrderReportView({ order }: OrderReportViewProps) {
  const { t } = useTranslation();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            backgroundColor: "#075DEB",
            flexDirection: "row",
            justifyContent: "center",
            paddingVertical: 10,
            marginBottom: 14,
          }}
          fixed
        >
          <Image
            style={{ width: 200, height: 60 }}
            src="/images/report/logo.png"
          />
        </View>
        <View style={styles.section}>
          <Text style={{ fontSize: 24, fontWeight: "demibold" }}>
            {t("home.history.order.modal.title", {
              id: order.id,
            })}
          </Text>
        </View>
        <View style={styles.container}>
          <OrderDetailSmallBox
            title="home.history.order.modal.orderBy.title"
            style={styles.column}
          >
            <Text>
              {order.user.firstName} {order.user.lastName}
            </Text>
            <Text>{order.user.email}</Text>
          </OrderDetailSmallBox>
          <OrderDetailSmallBox title="home.history.order.modal.address.title">
            <Text>
              <AddressInfo address={order.address} />
            </Text>
          </OrderDetailSmallBox>
        </View>

        <View style={styles.container}>
          <OrderDetailSmallBox
            title="home.history.order.modal.shippingDate.title"
            style={styles.column}
          >
            <Text>
              {order.shippedAt
                ? `${dayjs(order.shippedAt).format("dddd DD MMMM")} (${
                    order.trackingNumber
                  })`
                : "-"}
            </Text>
            <Text>{order.user.email}</Text>
          </OrderDetailSmallBox>
          <OrderDetailSmallBox title="home.history.order.modal.deliveryDate.title">
            <Text>{dayjs(order.deliveredAt).format("dddd DD MMMM")}</Text>
          </OrderDetailSmallBox>
        </View>

        <SummaryStrip
          text={t("home.history.order.modal.subscription", {
            frequency: order.frequency,
          })}
          number={order.total}
        ></SummaryStrip>

        <View style={{ padding: "20" }}>
          {order.dogs.map((dog) => (
            <View style={{ marginBottom: 20 }} wrap={false}>
              <View>
                <Text style={{ fontWeight: "normal" }}>{dog.name}</Text>
              </View>
              <View>
                {dog.menus.map((menu) => (
                  <Text>
                    {menu.name} X {menu.boxNum} box ({menu.amountPerBox}g/box)
                  </Text>
                ))}
              </View>
            </View>
          ))}
        </View>

        <SummaryStrip
          text={t("home.history.order.modal.deliveryFee", {
            frequency: order.frequency,
          })}
          number={0}
        ></SummaryStrip>

        <View style={styles.container}>
          <View style={styles.column}>
            <Text>
              {t("home.history.order.modal.paymentDate", {
                paymentDate: order.paidAt
                  ? dayjs(order.paidAt).format("DD/MM/YYYY")
                  : "-",
              })}
            </Text>
            {order.paidAt && (
              <Text>
                {t("home.history.order.modal.paymentOption", {
                  paymentOption: "Bank transfer",
                })}
              </Text>
            )}
          </View>
          <View
            style={{
              ...styles.column,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text>{t("home.history.order.modal.total")}</Text>
              <Text style={{ fontWeight: "bold" }}>
                <FormattedAmount value={order.total} />{" "}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text>{t("home.history.order.modal.vat")}</Text>
              <Text>-{/* <FormattedAmount value={order.total} /> */}</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text>{t("home.history.order.modal.totalVatIncl")}</Text>
              <Text style={{ fontWeight: "bold" }}>
                <FormattedAmount value={order.total} />{" "}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default OrderReportView;
