import ButtonSelect from "features/common/components/ButtonSelect";
import EachDogName from "features/common/components/EachDogName";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { pick } from "lodash";
import { DogEatingHabitFormValues, eatingHabitOptions } from "models/mydog";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function DogEatingHabitPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dogs } = useOnboarding();
  const { updateDogEatingHabit } = useOnboardingDispatch();

  const { handleSubmit, control, getValues } =
    useForm<DogEatingHabitFormValues>({
      defaultValues: {
        eatingHabits: dogs.map((dog) =>
          pick(dog, ["id", "name", "eatingHabitType"])
        ),
      },
    });
  const { fields } = useFieldArray({
    control,
    name: "eatingHabits",
  });

  const updateToStore = (data: DogEatingHabitFormValues) => {
    const eatingHabits = data.eatingHabits.map((dog) => dog.eatingHabitType!);
    updateDogEatingHabit(eatingHabits);
  };

  const onSubmit: SubmitHandler<DogEatingHabitFormValues> = async (data) => {
    updateToStore(data);
    navigate("/start/my-dog/8/weight");
  };

  return (
    <Page title="start.my-dog.eating-habit.title">
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <div key={index} className="mt-4 md:px-20 flex flex-col gap-y-4 mb-8">
            <EachDogName template="my-dog.eating-habit" name={field.name} />
            <Controller
              control={control}
              name={`eatingHabits.${index}.eatingHabitType`}
              render={({ field: { onChange, value } }) => (
                <div>
                  <ButtonSelect
                    options={eatingHabitOptions}
                    defaultValue={value}
                    className="grid gap-4 sm:grid-cols-3"
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </div>
        ))}
        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/my-dog/6/main-food");
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default DogEatingHabitPage;
