import { Breed, breeds } from "models/dog-breed";
import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

type BreedSelectProps = {
  defaultValue: number;
  onChange: (value: number | undefined) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue" | "onChange">;

function BreedSelect({ defaultValue, onChange }: BreedSelectProps) {
  const { i18n } = useTranslation();

  const defaultBreed = breeds.find((breed) => breed.id === defaultValue);

  return (
    <Select
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          height: "64px",
          borderRadius: "8px",
          border: "1px solid #A0B3C1",
          // textAlign: "left",
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
          fontFamily: "Mitr, sans-serif",
          fontWeight: 300,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 20,
        }),
      }}
      menuPortalTarget={document.body}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#075DEB",
          primary25: "#F5F7F9",
        },
      })}
      defaultValue={defaultBreed}
      getOptionLabel={(breed: Breed) => {
        return i18n.language === "en" ? breed.nameEN : breed.nameTH;
      }}
      getOptionValue={(breed: Breed) => breed.id.toString()}
      isClearable={false}
      options={breeds}
      onChange={(value) => {
        onChange(value?.id);
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
}

export default BreedSelect;
