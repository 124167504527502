import AgeSelect from "features/common/components/AgeSelect";
import BreedSelect from "features/common/components/BreedSelect";
import ButtonSelect from "features/common/components/ButtonSelect";
import EachDogName from "features/common/components/EachDogName";
import ErrorBox from "features/common/components/ErrorBox";
import ErrorText from "features/common/components/ErrorText";
import ImageSelect from "features/common/components/ImageSelect";
import Input from "features/common/components/Input";
import { MenusSelect } from "features/common/components/MenusSelect";
import MultipleButtonSelect from "features/common/components/MultipleButtonSelect";
import SpecialConditionRecommendation from "features/common/components/SpecialConditionRecommendation";
import SpecialConditionSelect from "features/common/components/SpecialConditionSelect";
import WarningBox from "features/common/components/WarningBox";
import WeightSlider from "features/common/components/WeightSlider";
import Modal from "features/common/modals/Modal";
import { useValidate } from "features/hook/useValidate";
import { has } from "lodash";
import {
  ActivityHabitType,
  BodyType,
  Dog,
  SexType,
  activityHabitTypeOptions,
  ageTypeOptions,
  bodyTypeOptions,
  calculateDailyEnergyRequirement,
  eatingHabitOptions,
  foodRestrictionOptions,
  getMainSubFromAge,
  getYearMonthWeekFromMainSub,
  hasFoodRestrictionOptions,
  hasSpecialConditionOptions,
  mainFoodOptions,
  neuteredOptions,
  sexOptions,
} from "models/mydog";
import { Product } from "models/product";
import { useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormRegister,
  UseFormWatch,
  get,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

type PageProps = {
  name?: string;
  templateKey: string;
} & React.HTMLAttributes<HTMLDivElement>;

function BaseForm({ name, templateKey, children }: PageProps) {
  const { t } = useTranslation();
  return (
    <div className="animate__animated animate__fadeIn">
      <h3 className="font-normal text-xl text-orange">
        {t(`start.my-dog.${templateKey}.title`)}
      </h3>
      {/* {subtitle && (
        <div className="mt-8 text-xl font-normal">{t(subtitle)}</div>
      )} */}
      {name && (
        <EachDogName
          className="mt-5"
          template={`my-dog.${templateKey}`}
          name={name}
        />
      )}
      <div className="mt-8">{children}</div>
    </div>
  );
}

export type DogFormValues = Dog & {
  main: number;
  sub: number;
};

type NameFormProps = {
  register: UseFormRegister<DogFormValues>;
  errors: FieldErrors<DogFormValues>;
};
export function NameForm({ register, errors }: NameFormProps) {
  const { t } = useTranslation();

  return (
    <BaseForm templateKey="dog-name" title="start.my-dog.dog-name.title">
      <div className="mt-4 sm:px-32">
        <Input
          className=""
          label=""
          placeholder="start.my-dog.name.placeholder"
          {...register("name", {
            required: `${t("form.validation.required")}`,
          })}
          errors={errors}
        />
      </div>
    </BaseForm>
  );
}

type SexFormProps = {
  control: any;
  name: string;
  errors: FieldErrors<DogFormValues>;
};
export function SexForm({ control, errors, name }: SexFormProps) {
  const { t } = useTranslation();

  return (
    <BaseForm templateKey="sex" name={name}>
      <>
        <Controller
          control={control}
          rules={{
            required: `${t("form.validation.required")}`,
          }}
          name="sex"
          render={({ field: { onChange, value } }) => (
            <ImageSelect<SexType>
              className="grid grid-cols-2 gap-4 mt-4"
              options={sexOptions}
              defaultValue={value}
              onChange={onChange}
            />
          )}
        />
        <ErrorText error={get(errors, `sex.message`)} />
      </>
    </BaseForm>
  );
}

type NeuteredFormProps = {
  control: Control<DogFormValues, any>;
  name: string;
  errors: FieldErrors<DogFormValues>;
};
export function NeuteredForm({ control, errors, name }: NeuteredFormProps) {
  return (
    <BaseForm name={name} templateKey="is-neutered">
      <Controller
        control={control}
        name="isNeutered"
        render={({ field: { onChange, value } }) => (
          <div>
            <ButtonSelect
              options={neuteredOptions}
              defaultValue={value}
              className="grid grid-cols-2 gap-4 mt-4"
              onChange={onChange}
            />
          </div>
        )}
      />
    </BaseForm>
  );
}

type AgeFormProps = {
  control: Control<DogFormValues, any>;
  watch: UseFormWatch<DogFormValues>;
  name: string;
  errors: FieldErrors<DogFormValues>;
};
export function AgeForm({ control, watch, errors, name }: AgeFormProps) {
  const { t } = useTranslation();
  const { validateAge } = useValidate();

  const ageType = watch("ageType");
  const sub = watch("sub");

  return (
    <BaseForm name={name} templateKey="age">
      <>
        <Controller
          control={control}
          name="ageType"
          render={({ field: { onChange, value } }) => (
            <div>
              <ButtonSelect
                options={ageTypeOptions}
                defaultValue={value}
                className="grid grid-cols-2 gap-4"
                onChange={onChange}
              />
            </div>
          )}
        />
        <div className="text-gray my-4 flex justify-center">
          {t("start.my-dog.age.dog.has-age.label")}
        </div>
        <div className="grid grid-cols-2 gap-4 sm:px-20">
          <Controller
            control={control}
            name="main"
            rules={{
              validate: (value) => validateAge(name, ageType, value, sub),
            }}
            render={({ field: { onChange, value } }) => (
              <AgeSelect
                defaultAge={value}
                type={ageType === "adult" ? "year" : "month"}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="sub"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <AgeSelect
                defaultAge={value}
                type={ageType === "adult" ? "month" : "week"}
                onChange={onChange}
              />
            )}
          />
        </div>
        {has(errors, `main.message`) && (
          <ErrorBox className="mt-3 has-error">
            <div>{get(errors, "main.message")}</div>
          </ErrorBox>
        )}
      </>
    </BaseForm>
  );
}

type BreedFormProps = {
  control: Control<DogFormValues, any>;
  name: string;
};
export function BreedForm({ control, name }: BreedFormProps) {
  return (
    <BaseForm name={name} templateKey="breed">
      <div className="sm:px-32">
        <Controller
          control={control}
          name="breedId"
          render={({ field: { onChange, value } }) => {
            return <BreedSelect defaultValue={value} onChange={onChange} />;
          }}
        />
      </div>
    </BaseForm>
  );
}

type MainFoodFormProps = {
  control: Control<DogFormValues, any>;
  name: string;
};
export function MainFoodForm({ control, name }: MainFoodFormProps) {
  return (
    <BaseForm name={name} templateKey="main-food">
      <Controller
        control={control}
        name="mainFoodType"
        render={({ field: { onChange, value } }) => (
          <ButtonSelect
            options={mainFoodOptions}
            defaultValue={value}
            className="grid grid-cols-2 gap-4"
            onChange={onChange}
          />
        )}
      />
    </BaseForm>
  );
}

type EatingHabitFoodFormProps = {
  control: Control<DogFormValues, any>;
  name: string;
};
export function EatingHabitFoodForm({
  control,
  name,
}: EatingHabitFoodFormProps) {
  return (
    <BaseForm name={name} templateKey="eating-habit">
      <Controller
        control={control}
        name="eatingHabitType"
        render={({ field: { onChange, value } }) => (
          <div>
            <ButtonSelect
              options={eatingHabitOptions}
              defaultValue={value}
              className="grid gap-4 sm:grid-cols-3"
              onChange={onChange}
            />
          </div>
        )}
      />
    </BaseForm>
  );
}

type WeightFormProps = {
  control: Control<DogFormValues, any>;
  name: string;
  errors: FieldErrors<DogFormValues>;
};
export function WeightForm({ control, name, errors }: WeightFormProps) {
  const { validateWeight } = useValidate();

  return (
    <BaseForm name={name} templateKey="weight">
      <Controller
        control={control}
        name="eatingHabitType"
        render={({ field: { onChange, value } }) => (
          <div>
            <Controller
              control={control}
              name="weight"
              rules={{
                validate: validateWeight,
              }}
              render={({ field: { onChange, value } }) => (
                <WeightSlider defaultValue={value} onChange={onChange} />
              )}
            />

            <ErrorText
              className="mt-12"
              error={get(errors, "weight.message")}
            />
          </div>
        )}
      />
    </BaseForm>
  );
}

type BodyTypeFormProps = {
  control: Control<DogFormValues, any>;
  name: string;
  watch: UseFormWatch<DogFormValues>;
};
export function BodyTypeForm({ control, name, watch }: BodyTypeFormProps) {
  const { t } = useTranslation();
  const bodyType = watch("bodyType");

  return (
    <BaseForm name={name} templateKey="body-type">
      <div>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="bodyType"
          render={({ field: { onChange, value } }) => (
            <ImageSelect<BodyType>
              className="grid grid-cols-2 sm:grid-cols-3 gap-4"
              options={bodyTypeOptions}
              defaultValue={value}
              onChange={onChange}
            />
          )}
        />
        <WarningBox className="mt-4 sm:mx-28 text-left">
          {bodyType}:&nbsp;
          {t(`component.body-type-select.${bodyType}.description`, {
            name,
          })}
        </WarningBox>
      </div>
    </BaseForm>
  );
}

type AcitivityHabitFormProps = {
  control: Control<DogFormValues, any>;
  name: string;
  watch: UseFormWatch<DogFormValues>;
};
export function ActivityHabitForm({
  control,
  name,
  watch,
}: AcitivityHabitFormProps) {
  const { t } = useTranslation();
  const activityHabitType = watch("activityHabitType");

  return (
    <BaseForm name={name} templateKey="activity-type">
      <div>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="activityHabitType"
          render={({ field: { onChange, value } }) => (
            <ImageSelect<ActivityHabitType>
              className="grid grid-cols-2 sm:grid-cols-3 gap-4"
              options={activityHabitTypeOptions}
              defaultValue={value}
              onChange={onChange}
            />
          )}
        />
        <WarningBox className="mt-4 sm:mx-28 text-left">
          {activityHabitType}:&nbsp;
          {t(
            `component.activity-habit-type-select.${activityHabitType}.description`,
            {
              name,
            }
          )}
        </WarningBox>
      </div>
    </BaseForm>
  );
}

type FoodRestrictionFormProps = {
  control: Control<DogFormValues, any>;
  name: string;
  watch: UseFormWatch<DogFormValues>;
  errors: FieldErrors<DogFormValues>;
};
export function FoodRestrictionForm({
  control,
  name,
  watch,
  errors,
}: FoodRestrictionFormProps) {
  const { t } = useTranslation();
  const { validateFoodRestrictions } = useValidate();

  const hasFoodRestriction = watch("hasFoodRestriction");

  return (
    <BaseForm name={name} templateKey="food-restriction">
      <div>
        <Controller
          control={control}
          name="hasFoodRestriction"
          render={({ field: { onChange, value } }) => (
            <ButtonSelect
              options={hasFoodRestrictionOptions}
              defaultValue={value}
              className="grid grid-cols-2 space-x-4"
              onChange={onChange}
            />
          )}
        />

        {hasFoodRestriction && (
          <>
            <h4 className="font-normal text-xl my-4 flex justify-center">
              {t("start.my-dog.food-restriction.special-conditions.title")}
            </h4>
            <Controller
              control={control}
              name="foodRestrictionTypes"
              rules={{
                validate: validateFoodRestrictions,
              }}
              render={({ field: { onChange, value } }) => (
                <MultipleButtonSelect
                  options={foodRestrictionOptions}
                  defaultValue={value}
                  className="grid grid-cols-2 gap-4"
                  onChange={onChange}
                />
              )}
            />
            <ErrorText error={get(errors, `foodRestrictionTypes.message`)} />
          </>
        )}
      </div>
    </BaseForm>
  );
}

type SpecialConditionFormProps = {
  control: Control<DogFormValues, any>;
  name: string;
  watch: UseFormWatch<DogFormValues>;
  errors: FieldErrors<DogFormValues>;
};
export function SpecialConditionForm({
  control,
  name,
  watch,
  errors,
}: SpecialConditionFormProps) {
  const { t } = useTranslation();

  const hasSpecialCondition = watch("hasSpecialCondition");
  const specialConditionId = watch("specialConditionId");

  return (
    <BaseForm name={name} templateKey="special-condition">
      <div>
        <Controller
          control={control}
          name="hasSpecialCondition"
          render={({ field: { onChange, value } }) => (
            <ButtonSelect
              options={hasSpecialConditionOptions}
              defaultValue={value}
              className="grid grid-cols-2 gap-4"
              onChange={onChange}
            />
          )}
        />

        {hasSpecialCondition && (
          <>
            <h4 className="my-4 font-normal text-xl flex justify-center">
              {t("start.my-dog.food-restriction.special-conditions.title")}
            </h4>
            <Controller
              control={control}
              name="specialConditionId"
              rules={{
                required: `${t("form.validation.required")}`,
              }}
              render={({ field: { onChange, value } }) => (
                <div className="sm:px-32">
                  <SpecialConditionSelect
                    defaultValue={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
            {specialConditionId && (
              <div className="mt-4">
                <SpecialConditionRecommendation
                  specialConditionId={specialConditionId!}
                />
              </div>
            )}
            <ErrorText error={get(errors, `specialConditionId.message`)} />
          </>
        )}
      </div>
    </BaseForm>
  );
}

type MenuSelectionFormProps = {
  control: Control<DogFormValues, any>;
  dog: Dog;
  products: Product[];
  deliveryFrequency: number;
  errors: FieldErrors<DogFormValues>;
};
export function MenuSelectionForm({
  control,
  dog,
  products,
  deliveryFrequency,
  errors,
}: MenuSelectionFormProps) {
  const { validateMenus } = useValidate();

  return (
    <BaseForm name={dog.name} templateKey="menu">
      <div className="mb-5">
        <Controller
          control={control}
          rules={{
            validate: (value) => validateMenus(value, deliveryFrequency),
          }}
          name="menus"
          render={({ field: { onChange, value } }) => (
            <div
              className={`${get(errors, "menus") && "border border-red p-2"}`}
            >
              <MenusSelect
                products={products}
                deliveryFrequency={deliveryFrequency}
                dog={dog}
                defaultValue={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <ErrorText error={get(errors, `menus.message`)} />
      </div>
    </BaseForm>
  );
}

export type Step =
  | "name"
  | "sex"
  | "neutered"
  | "age"
  | "breed"
  | "mainFood"
  | "eatingHabit"
  | "weight"
  | "body"
  | "activityHabit"
  | "foodRestriction"
  | "specialCondition"
  | "menu";

type DogModalProps = {
  products: Product[];
  deliveryFrequency: number;
  defaultValue: Dog;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (dog: Dog) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue">;

function DogModal({
  products,
  deliveryFrequency,
  defaultValue,
  isOpen,
  onClose,
  onConfirm,
}: DogModalProps) {
  const { t } = useTranslation();

  const [dog, setDog] = useState(defaultValue);
  const [step, setStep] = useState<Step>("name");

  const { main, sub } = getMainSubFromAge(dog);
  const {
    handleSubmit,
    register,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<DogFormValues>({
    // defaultValues: dog,
    // values: { ...dog, main, sub },
    // defaultValues: defaultValue,
    // defaultValues: { ...dog, main, sub },
    defaultValues: { ...dog, main, sub },
  });

  const onSubmit: SubmitHandler<DogFormValues> = async (data) => {
    if (step === "name") {
      setDog({ ...dog, name: data.name });
      setStep("sex");
    } else if (step === "sex") {
      setDog({ ...dog, sex: data.sex });
      setStep("neutered");
    } else if (step === "neutered") {
      setDog({ ...dog, isNeutered: data.isNeutered });
      setStep("age");
    } else if (step === "age") {
      const { year, month, week } = getYearMonthWeekFromMainSub(
        data.ageType,
        data.main,
        data.sub
      );
      setDog({
        ...dog,
        ageType: data.ageType,
        year,
        month,
        week,
      });
      setStep("breed");
    } else if (step === "breed") {
      setDog({
        ...dog,
        breedId: data.breedId,
      });
      setStep("mainFood");
    } else if (step === "mainFood") {
      setDog({
        ...dog,
        mainFoodType: data.mainFoodType,
      });
      setStep("eatingHabit");
    } else if (step === "eatingHabit") {
      setDog({
        ...dog,
        eatingHabitType: data.eatingHabitType,
      });
      setStep("weight");
    } else if (step === "weight") {
      setDog({
        ...dog,
        weight: data.weight,
      });
      setStep("body");
    } else if (step === "body") {
      setDog({
        ...dog,
        bodyType: data.bodyType,
      });
      setStep("activityHabit");
    } else if (step === "activityHabit") {
      setDog({
        ...dog,
        activityHabitType: data.activityHabitType,
      });
      setStep("foodRestriction");
    } else if (step === "foodRestriction") {
      setDog({
        ...dog,
        foodRestrictionTypes: data.foodRestrictionTypes,
      });
      setStep("specialCondition");
    } else if (step === "specialCondition") {
      const energies = calculateDailyEnergyRequirement([dog]);
      setDog({
        ...dog,
        specialConditionId: data.specialConditionId,
        dailyEnergy: energies[0],
      });
      setStep("menu");
    } else if (step === "menu") {
      onConfirm({ ...dog, id: dog.id && uuidv4(), menus: data.menus });
      setStep("name");
    }
  };

  let page;

  switch (step) {
    case "name":
      page = <NameForm errors={errors} register={register} />;
      break;
    case "sex":
      page = <SexForm control={control} errors={errors} name={dog.name} />;
      break;
    case "neutered":
      page = <NeuteredForm control={control} errors={errors} name={dog.name} />;
      break;
    case "age":
      page = (
        <AgeForm
          control={control}
          watch={watch}
          errors={errors}
          name={dog.name}
        />
      );
      break;
    case "breed":
      page = <BreedForm control={control} name={dog.name} />;
      break;
    case "mainFood":
      page = <MainFoodForm control={control} name={dog.name} />;
      break;
    case "eatingHabit":
      page = <EatingHabitFoodForm control={control} name={dog.name} />;
      break;
    case "weight":
      page = <WeightForm control={control} name={dog.name} errors={errors} />;
      break;
    case "body":
      page = <BodyTypeForm control={control} name={dog.name} watch={watch} />;
      break;
    case "activityHabit":
      page = (
        <ActivityHabitForm control={control} name={dog.name} watch={watch} />
      );
      break;
    case "foodRestriction":
      page = (
        <FoodRestrictionForm
          control={control}
          name={dog.name}
          watch={watch}
          errors={errors}
        />
      );
      break;
    case "specialCondition":
      page = (
        <SpecialConditionForm
          control={control}
          name={dog.name}
          watch={watch}
          errors={errors}
        />
      );
      break;
    case "menu":
      page = (
        <MenuSelectionForm
          control={control}
          dog={dog}
          products={products}
          deliveryFrequency={deliveryFrequency}
          errors={errors}
        />
      );
      break;
  }

  return (
    <Modal isOpen={isOpen} onClose={() => {}} className="rounded-2xl modal-lg">
      <form
        // onSubmit={handleSubmit(onSubmit, onInvalidScrollToElement)}
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col h-full max-h-full md:max-h-[95vh]"
      >
        <h3 className="p-7 text-2xl font-normal">
          {t("component.modal.dog.title")}
        </h3>
        <div className="px-7 flex-grow flex-shrink overflow-y-auto flex flex-col gap-y-2">
          {page}
        </div>
        <div className="bg-lightGray grid grid-cols-3 sm:grid-cols-4 gap-x-4 py-5 px-7 rounded-b-2xl">
          <input
            className="form-submit back-button button rounded-lg shadow-none border border-gray px-0"
            type="button"
            onClick={() => {
              setStep("name");
              setDog(defaultValue);
              reset();
              onClose();
            }}
            value={`${t("dialog.button.cancel")}`}
          />
          <input
            className="form-submit back-button button rounded-lg shadow-none border border-gray sm:col-start-3 px-0"
            type="button"
            onClick={() => {
              if (step === "sex") {
                setStep("name");
              } else if (step === "neutered") {
                setStep("sex");
              } else if (step === "age") {
                setStep("neutered");
              } else if (step === "breed") {
                setStep("age");
              } else if (step === "mainFood") {
                setStep("breed");
              } else if (step === "eatingHabit") {
                setStep("mainFood");
              } else if (step === "weight") {
                setStep("eatingHabit");
              } else if (step === "body") {
                setStep("weight");
              } else if (step === "activityHabit") {
                setStep("body");
              } else if (step === "foodRestriction") {
                setStep("activityHabit");
              } else if (step === "specialCondition") {
                setStep("foodRestriction");
              } else if (step === "menu") {
                setStep("specialCondition");
              }
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button rounded-lg shadow-none px-0"
            type="submit"
            value={`${
              step === "menu"
                ? t("dialog.button.confirm")
                : t("dialog.button.continue")
            }`}
          />
        </div>
      </form>
    </Modal>
  );
}

export default DogModal;
