import { yupResolver } from "@hookform/resolvers/yup";
import APIErrorBox from "features/common/components/APIErrorBox";
import Input from "features/common/components/Input";
import Modal from "features/common/modals/Modal";
import { IconSimulation } from "features/common/icon";
import { setOnboarding } from "features/common/storage";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { anonymousService } from "resources/anonymous";
import { ApiError } from "resources/http-client";
import * as yup from "yup";

export type ContinuePreviousSimulationFormValues = {
  email: string;
};

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

function ContinuePreviousSimulationModal({
  isOpen,
  onClose,
  onConfirm,
}: ModalProps) {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<ApiError | undefined>();

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .required(`${t("form.validation.required")}`)
      .email(`${t("form.validation.invalid-email")}`),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContinuePreviousSimulationFormValues>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<ContinuePreviousSimulationFormValues> = async (
    data
  ) => {
    try {
      setApiError(undefined);
      const acc = await anonymousService.getAccount(data.email);
      setOnboarding(acc);
      window.location.reload();
      onConfirm();
    } catch (err) {
      setApiError(err as ApiError);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} className="rounded-2xl modal-sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-7">
          <div className="bg-blue1 bg-opacity-10 rounded-full w-[60px] h-[60px] flex justify-center items-center">
            <IconSimulation className="fill-blue1" />
          </div>

          <h3 className="text-2xl mt-8 font-normal">
            {t("start.my-dog.dog-name.dialog.previous-simulation.title")}
          </h3>
          <div className="font-light mt-6">
            {t("start.my-dog.dog-name.dialog.previous-simulation.description")}
          </div>
          <Input
            className="mt-4"
            label=""
            placeholder="start.menu.user-details.email.label"
            {...register("email")}
            errors={errors}
          />
          <APIErrorBox err={apiError} />
        </div>
        <div className="bg-lightGray grid grid-cols-2 gap-x-4 py-5 px-7 rounded-b-2xl">
          <input
            className="form-submit back-button button rounded-lg shadow-none border border-gray"
            type="button"
            onClick={onClose}
            value={`${t("dialog.button.cancel")}`}
          />
          <input
            className="form-submit button primary-button rounded-lg shadow-none px-0"
            type="submit"
            value={`${t("dialog.button.continue")}`}
          />
        </div>
      </form>
    </Modal>
  );
}

export default ContinuePreviousSimulationModal;
