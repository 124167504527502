import AgeSelect from "features/common/components/AgeSelect";
import ButtonSelect from "features/common/components/ButtonSelect";
import EachDogName from "features/common/components/EachDogName";
import ErrorBox from "features/common/components/ErrorBox";
import { onInvalidScrollToElement } from "features/common/utils";
import { useValidate } from "features/hook/useValidate";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { get, has, pick } from "lodash";
import {
  DogAgeFormValues,
  DogIdAndAge,
  ageTypeOptions,
  getMainSubFromAge,
  getYearMonthWeekFromMainSub,
} from "models/mydog";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function DogAgePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dogs } = useOnboarding();
  const { updateDogAge } = useOnboardingDispatch();
  const { validateAge } = useValidate();

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm<DogAgeFormValues>({
    defaultValues: {
      ages: dogs.map((dog) => {
        const { main, sub } = getMainSubFromAge(dog);
        return {
          ...pick(dog, ["id", "name", "ageType"]),
          main,
          sub,
        };
      }),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "ages",
  });

  const updateToStore = (data: DogAgeFormValues) => {
    const ages: DogIdAndAge[] = data.ages.map((dog) => {
      const { year, month, week } = getYearMonthWeekFromMainSub(
        dog.ageType,
        dog.main,
        dog.sub
      );

      return {
        id: dog.id,
        name: dog.name,
        ageType: dog.ageType,
        year,
        month,
        week,
      };
    });
    updateDogAge(ages);
  };

  const onSubmit: SubmitHandler<DogAgeFormValues> = async (data) => {
    updateToStore(data);
    navigate("/start/my-dog/5/breed");
  };

  const validateAges = (index: number) => {
    const age = getValues("ages")[index];
    return validateAge(age.name, age.ageType, age.main, age.sub);
  };

  const ages = watch("ages");

  return (
    <Page title="start.my-dog.age.title">
      <form onSubmit={handleSubmit(onSubmit, onInvalidScrollToElement)}>
        {fields.map((field, index) => (
          <div key={index} className="mt-4 md:px-36 flex flex-col gap-y-4 mb-8">
            <EachDogName template="my-dog.age" name={field.name} />
            <Controller
              control={control}
              name={`ages.${index}.ageType`}
              render={({ field: { onChange, value } }) => (
                <div>
                  <ButtonSelect
                    options={ageTypeOptions}
                    defaultValue={field.ageType}
                    className="grid grid-cols-2 gap-4"
                    onChange={onChange}
                  />
                </div>
              )}
            />
            <div className="text-gray">
              {t("start.my-dog.age.dog.has-age.label")}
            </div>
            <div className="grid grid-cols-2 gap-4 sm:px-20">
              <Controller
                control={control}
                name={`ages.${index}.main`}
                rules={{
                  validate: (value) => validateAges(index),
                }}
                render={({ field: { onChange, value } }) => (
                  <AgeSelect
                    defaultAge={value}
                    type={ages[index].ageType === "adult" ? "year" : "month"}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                control={control}
                name={`ages.${index}.sub`}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <AgeSelect
                    defaultAge={value}
                    type={ages[index].ageType === "adult" ? "month" : "week"}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            {has(errors, `ages[${index}].main.message`) && (
              <ErrorBox className="mt-3 has-error">
                <div>{get(errors, `ages[${index}].main.message`)}</div>
              </ErrorBox>
            )}
          </div>
        ))}
        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/my-dog/3/neutered");
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default DogAgePage;
