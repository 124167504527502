import imgError from "assets/images/form/error.png";
import imgRemoveInactive from "assets/images/form/remove-inactive.png";
import imgRemove from "assets/images/form/remove.png";
import { has } from "lodash";
import React, { useState } from "react";
import { FieldErrors, get } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorText from "./ErrorText";

type TextInputProps = {
  name: string;
  label: string;
  placeholder: string;
  errors: FieldErrors;
  removeable?: boolean;
  onRemove?: () => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      name,
      label,
      type,
      placeholder,
      errors,
      onBlur,
      onChange,
      onRemove,
      className,
      removeable,
      disabled,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [isInputFocused, setIsInputFocused] = useState(false);

    return (
      <>
        <div className={`flex flex-col items-start ${className}`}>
          {label && (
            <label htmlFor={name} className="text-gray font-light">
              {t(label)}
            </label>
          )}
          <div className="w-full relative">
            <input
              className={`form-text w-full mt-1 font-light placeholder-font-light border border-gray focus:border-blue1 ${
                get(errors, `${name}.message`) ? "border-red" : ""
              }`}
              onFocus={() => setIsInputFocused(true)}
              onBlur={(e) => {
                setIsInputFocused(false);
                onBlur && onBlur(e);
              }}
              ref={ref}
              type={type}
              name={name}
              placeholder={`${t(placeholder)}`}
              onChange={onChange}
              disabled={disabled}
            />
            {has(errors, name) && (
              <img
                src={imgError}
                alt="error"
                className="w-6 h-6 absolute top-4 right-3"
              />
            )}
            {removeable && onRemove && !has(errors, name) && (
              <img
                src={isInputFocused ? imgRemove : imgRemoveInactive}
                alt="remove"
                className="w-6 h-6 absolute top-4 right-3 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  onRemove();
                }}
              />
            )}
          </div>

          <ErrorText className="mt-1" error={get(errors, `${name}.message`)} />
        </div>
      </>
    );
  }
);

export default Input;
