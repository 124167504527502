import { useTranslation } from "react-i18next";

type TitleProps = {
  title: string;
} & React.HTMLAttributes<HTMLDivElement>;

function PageTitle({ title }: TitleProps) {
  const { t } = useTranslation();
  return <h3 className="font-normal text-3xl text-orange">{t(title)}</h3>;
}

export default PageTitle;
