import { anonymousHttpClient } from "./http-client";
import { APIResponse } from "./response";

export type ProductResponse = {
  id: string;
  name: string;
  description: string;
  ingredients: [string];
  imageUrl: string;
  price: number;
  energy: number;
  createdAt: Date;
  updatedAt: Date;
};

export type ListProductsResponse = {
  products: ProductResponse[];
};

class ProductService {
  async getProducts(): Promise<ProductResponse[]> {
    const { data } = await anonymousHttpClient.get<
      APIResponse<ListProductsResponse>
    >(`/v1/products`);
    return data.data.products;
  }
}
export const productService = new ProductService();
