import dayjs from "dayjs";
import AddressInfo from "features/common/components/AddressInfo";
import imgDogAvatar from "assets/images/start/menu/recommended-portion/dog-avatar.png";
import FormattedAmount from "features/common/components/FormattedAmount";
import imgAge from "assets/images/start/menu/recommended-portion/age.png";
import imgForbidden from "assets/images/start/menu/recommended-portion/forbidden.png";
import imgWeight from "assets/images/start/menu/recommended-portion/weight.png";
import Modal from "features/common/modals/Modal";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoaderFunction, useLoaderData } from "react-router";
import { adminService } from "resources/admin";
import {
  Order,
  OrderStatus,
  getTagFromOrderStatus,
} from "../../../models/order";
import Page from "../../home/components/Page";
import PillButton from "../../home/components/PillButton";
import {
  Dog,
  SpecialCondition,
  getActivityHabitTypeLabelByValue,
  getBodyTypeLabelByValue,
  getEatingHabitTypeLabelByValue,
  getFoodRestrictionTypeLabelByValue,
  specialConditions,
} from "models/mydog";
import { Breed, breeds } from "models/dog-breed";
import Tag from "features/common/components/Tag";

export const loader: LoaderFunction = async () => {
  const [{ orders }] = await Promise.all([
    adminService.listOrders({
      status: "pending",
    }),
  ]);
  return { orders };
};

type OrderDetailSmallBoxProps = {
  title: string;
} & React.HTMLAttributes<HTMLDivElement>;
function OrderDetailSmallBox({ title, children }: OrderDetailSmallBoxProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-y-2">
      <div className="text-xl font-normal">{t(title)}</div>
      <div>{children}</div>
    </div>
  );
}

type ForbiddenTagProps = {
  tag: string;
};
function ForbiddenTag({ tag }: ForbiddenTagProps) {
  return (
    <div className="rounded-md bg-white text-orange p-2 flex gap-x-2 items-center">
      <img src={imgForbidden} alt="forbidden" className="w-5 h-5" />
      {tag}
    </div>
  );
}

type DogDetailBoxProps = {
  dog: Dog;
};
function DogDetailBox({ dog }: DogDetailBoxProps) {
  const { i18n, t } = useTranslation();
  const breed = breeds[dog.breedId][
    `name${i18n.language.toUpperCase()}` as keyof Breed
  ] as string;

  let specialCondition = "";
  if (dog.specialConditionId) {
    specialCondition = specialConditions[dog.specialConditionId - 1][
      `name${i18n.language.toUpperCase()}` as keyof SpecialCondition
    ] as string;
  }

  return (
    <div className="bg-lightGray rounded-lg px-5 py-4 flex flex-col gap-y-4 w-1/2">
      <div className="font-normal flex gap-x-3 justify-start items-center">
        <div className="bg-white rounded-full px-3 py-4">
          <img src={imgDogAvatar} alt="dog avatar" className="w-10 h-8" />
        </div>
        <div className="flex flex-col gap-y-2 items-start">
          <div className="text-2xl">
            {dog.name} {dog.sex}
          </div>
          <div className="flex gap-x-4 font-light">
            <div className="flex gap-x-2">
              <img src={imgWeight} alt="weight" className="w-5 h-5" />
              <span>{dog.weight} kg</span>
            </div>
            <div className="flex gap-x-2">
              <img src={imgAge} alt="weight" className="w-5 h-5" />
              <span>
                {dog.year},{dog.month} yo
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-2 font-light">
        <Tag tag={breed} color="blue1" />
        <Tag tag={t(getBodyTypeLabelByValue(dog.bodyType))} color="blue1" />
        <Tag
          tag={t(getActivityHabitTypeLabelByValue(dog.activityHabitType))}
          color="blue1"
        />
        <Tag
          tag={t(getEatingHabitTypeLabelByValue(dog.eatingHabitType))}
          color="blue1"
        />
      </div>
      <div className="flex flex-wrap gap-2 font-light">
        {dog.foodRestrictionTypes &&
          dog.foodRestrictionTypes.map((foodRestrictionType, index) => (
            <ForbiddenTag
              key={index}
              tag={t(getFoodRestrictionTypeLabelByValue(foodRestrictionType))}
            />
          ))}
        {specialCondition && <ForbiddenTag tag={specialCondition} />}
      </div>
      <div className="rounded-lg text-left">
        <div>Needs per day : {dog.dailyEnergy} kcal </div>
      </div>
    </div>
  );
}

type OrderDetailModalProps = {
  isOpen: boolean;
  order: Order;
  onClose: () => void;
  onAction: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

function OrderDetailModal({
  isOpen,
  order,
  onClose,
  onAction,
}: OrderDetailModalProps) {
  const { t } = useTranslation();

  const markAsPaid = async () => {
    var result = window.confirm(
      "Are you sure you want to mark this order as paid?"
    );
    if (result) {
      await adminService.markOrderAsPaid(order.id);
      onAction();
    }
  };

  const markAsShipped = async () => {
    var trackingNumber = window.prompt(
      "Are you sure you want to mark this order as shipped?, if so, please enter tracking number"
    );
    if (trackingNumber) {
      await adminService.markOrderAsShipped(order.id, {
        trackingNumber,
      });
      onAction();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} className="rounded-2xl modal-lg">
      <div className="flex flex-col h-full">
        <h3 className="text-xl sm:text-2xl font-normal px-7 py-4">
          {t("home.history.order.modal.title", {
            id: order.id,
          })}
        </h3>
        <div className="flex-grow flex-shrink overflow-y-auto">
          <div className="grid sm:grid-cols-2 gap-y-6 p-7">
            <OrderDetailSmallBox title="home.history.order.modal.orderBy.title">
              <div>
                {order.user.firstName} {order.user.lastName}
              </div>
              <div>{order.user.email}</div>
              <div>{order.user.telno}</div>
            </OrderDetailSmallBox>
            <OrderDetailSmallBox title="home.history.order.modal.address.title">
              <AddressInfo address={order.address} />
            </OrderDetailSmallBox>
            <OrderDetailSmallBox title="home.history.order.modal.shippingDate.title">
              {order.shippedAt
                ? `${dayjs(order.shippedAt).format("dddd DD MMMM")} (${
                    order.trackingNumber
                  })`
                : "-"}
            </OrderDetailSmallBox>
            <OrderDetailSmallBox title="home.history.order.modal.deliveryDate.title">
              {dayjs(order.deliveredAt).format("dddd DD MMMM")}
            </OrderDetailSmallBox>
          </div>
          <div className="bg-lightGray px-7 py-4 flex justify-between text-lg sm:text-xl font-normal">
            <div>
              {t("home.history.order.modal.subscription", {
                frequency: order.frequency,
              })}
            </div>
            <div>
              <FormattedAmount value={order.total} />
            </div>
          </div>
          <div className="bg-white px-7 py-4 flex flex-col gap-y-4">
            {order.dogs.map((dog) => (
              <div className="flex gap-x-4">
                <DogDetailBox dog={dog} />
                <div>
                  <div className="font-bold">อาหารที่สั่ง</div>
                  {dog.menus.map((menu) => (
                    <div>
                      {menu.name} X {menu.boxNum} box ({menu.amountPerBox}g/box)
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="bg-lightGray px-7 py-4 flex justify-between text-lg sm:text-xl font-normal">
            <div>{t("home.history.order.modal.deliveryFee")}</div>
            <div>0 ฿</div>
          </div>
          <div className="grid grid-cols-2 gap-y-6 px-7 py-4 mb-4">
            <div>
              <div>
                {t("home.history.order.modal.paymentDate", {
                  paymentDate: order.paidAt
                    ? dayjs(order.paidAt).format("DD/MM/YYYY")
                    : "-",
                })}
              </div>
              {order.paidAt && (
                <>
                  <div>
                    {t("home.history.order.modal.paymentOption", {
                      paymentOption: "Bank transfer",
                    })}
                  </div>
                  <a
                    href={order.paymentSlipUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-700 underline"
                  >
                    View slip
                  </a>
                </>
              )}
            </div>
            <div>
              <div className="flex justify-between text-lg sm:text-xl">
                <div>{t("home.history.order.modal.total")}</div>
                <div className="font-normal">
                  <FormattedAmount value={order.total} />
                </div>
              </div>
              <div className="flex justify-between text-xl">
                <div>{t("home.history.order.modal.vat")}</div>
                <div className="font-normal">
                  -{/* <FormattedAmount value={order.total} /> */}
                </div>
              </div>
              <div className="flex justify-between text-xl">
                <div>{t("home.history.order.modal.totalVatIncl")}</div>
                <div className="font-normal">
                  <FormattedAmount value={order.total} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lightGray grid grid-cols-2 gap-4 py-5 px-7 rounded-b-2xl">
          <input
            className="form-submit back-button button rounded-lg shadow-none border border-gray"
            type="button"
            onClick={onClose}
            value={`${t("dialog.button.close")}`}
          />
          {order.status === "paid" && (
            <input
              className="form-submit primary-button button rounded-lg shadow-none border border-gray"
              type="button"
              onClick={markAsPaid}
              value="Mark as paid"
            />
          )}
          {order.status === "confirmedPaid" && (
            <input
              className="form-submit primary-button button rounded-lg shadow-none border border-gray"
              type="button"
              onClick={markAsShipped}
              value="Mark as shipped"
            />
          )}
          {/* <PDFDownloadLink
            className="form-submit button primary-button rounded-lg shadow-none text-center"
            document={<OrderReportView order={order} />}
            fileName={`${order.id}.pdf`}
          >
            {() => t("dialog.button.download-invoice")}
          </PDFDownloadLink> */}
        </div>
      </div>
    </Modal>
  );
}

type OrderProps = {
  order: Order;
  onSelect: (order: Order) => void;
};
function OrderRow({ order, onSelect }: OrderProps) {
  return (
    <tr className="bg-white h-24 rounded-lg">
      <td className="p-2 border-b-8 border-lightGray">#{order.id}</td>
      <td className="p-2 border-b-8 border-lightGray">{order.user.email}</td>
      <td className="p-2 border-b-8 border-lightGray">
        <div className="flex justify-center">
          {getTagFromOrderStatus(order)}
        </div>
      </td>
      <td className="p-2 border-b-8 border-lightGray">
        {dayjs(order.deliveredAt).format("DD/MM/YYYY")}
      </td>
      <td className="p-2 border-b-8 border-lightGray">
        {order.total.toLocaleString("th-TH")} ฿
      </td>
      <td className="p-2 border-b-8 border-lightGray">
        {order.total.toLocaleString("th-TH")} ฿
      </td>
      <td className="p-2 border-b-8 border-lightGray">
        <PillButton
          name="Details"
          onClick={() => {
            onSelect(order);
          }}
        />
      </td>
    </tr>
  );
}

type SearchFilterFormValues = {
  status: OrderStatus;
  search: string;
};

function OrderManagementPage() {
  const { t } = useTranslation();
  const { orders } = useLoaderData() as {
    orders: Order[];
  };
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(orders[0]);
  const [tableOrders, setTableOrders] = useState(orders);

  const { register, handleSubmit, getValues } = useForm<SearchFilterFormValues>(
    {
      defaultValues: {
        status: "pending",
      },
    }
  );

  const onSubmit: SubmitHandler<SearchFilterFormValues> = async (data) => {
    const { orders } = await adminService.listOrders(data);
    setTableOrders(orders);
  };

  return (
    <Page
      title="Order Management"
      subtitle="View, mark paid and ship your orders"
    >
      <div className="overflow-x-auto">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-gray p-2 sm:p-10 flex flex-col sm:flex-row items-center justify-start sm:justify-between gap-4"
        >
          <div className="flex items-center gap-4">
            <label htmlFor="status">Status:</label>
            <select
              {...register("status", { required: true })}
              id="status"
              className="form-text"
            >
              <option value="">Select a status</option>
              <option value="pending">Pending</option>
              <option value="paid">Paid</option>
              <option value="confirmedPaid">Confirmed Paid</option>
              <option value="shipped">Shipped</option>
            </select>

            {/* <label htmlFor="search">Search:</label>
            <input
              {...register("search")}
              id="search"
              type="text"
              className="form-text"
            /> */}
          </div>
          <button type="submit" className="primary-button rounded-sm">
            ค้นหา
          </button>
        </form>
        <table
          className="table-auto border-collapse w-full mt-10"
          style={{ borderSpacing: "0.6rem" }}
        >
          <thead>
            <tr>
              <th className="p-2">{t("home.history.table.order.col")}</th>
              <th className="p-2">User</th>
              <th className="p-2">{t("home.history.table.status.col")}</th>
              <th className="p-2">{t("home.history.table.date.col")}</th>
              <th className="p-2">{t("home.history.table.total.col")}</th>
              <th className="p-2">{t("home.history.table.paid.col")}</th>
            </tr>
          </thead>
          <tbody>
            {tableOrders.map((order, idx) => (
              <OrderRow
                key={idx}
                order={order}
                onSelect={(order) => {
                  setSelectedOrder(order);
                  setShowOrderDetailModal(true);
                }}
              />
            ))}
          </tbody>
        </table>
      </div>
      {tableOrders.length !== 0 && (
        <OrderDetailModal
          isOpen={showOrderDetailModal}
          order={selectedOrder}
          onClose={() => setShowOrderDetailModal(false)}
          onAction={async () => {
            setShowOrderDetailModal(false);
            const { orders } = await adminService.listOrders(getValues());
            setTableOrders(orders);
          }}
        ></OrderDetailModal>
      )}
    </Page>
  );
}

export default OrderManagementPage;
