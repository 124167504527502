type InfoBoxProps = {} & React.HTMLAttributes<HTMLDivElement>;

function InfoBox({ children, className }: InfoBoxProps) {
  return (
    <div
      className={`bg-blue1 bg-opacity-10 text-blue1 p-5 rounded-2xl ${className}`}
    >
      {children}
    </div>
  );
}

export default InfoBox;
