import { useRef } from "react";
import { useOutsideClick } from "../hooks/use-outside-click";

export type MenuOption<ValueType> = {
  name: string;
  value: ValueType;
};

type MenuProps = {
  name: string;
};
function Menu({ name }: MenuProps) {
  return (
    <div className="flex justify-start items-center space-x-2 p-2 focus:bg-darkwhite hover:bg-lightGray ho focus:shadow-outline-blue cursor-pointer">
      <div>{name}</div>
    </div>
  );
}

type ContextMenuProps<ValueType> = {
  options: MenuOption<ValueType>[];
  show: boolean;
  defaultValue: ValueType;
  onChoose: (type: ValueType) => void;
  onClose: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

function ContextMenu<ValueType>({
  options,
  show,
  onChoose,
  onClose,
  className,
  defaultValue,
}: ContextMenuProps<ValueType>) {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    onClose();
  });

  const showCls = show ? "" : "hidden";
  return (
    <div
      ref={wrapperRef}
      className={`flex flex-col rounded-lg p-2 bg-white font-light ${showCls} animate__animated animate__bounceIn animate__faster ${className}`}
      style={{
        boxShadow: "0px 24px 40px rgba(0, 0, 0, 0.16)",
      }}
    >
      {options.map((option, idx) => (
        <div
          key={idx}
          onClick={() => {
            onChoose(option.value);
          }}
        >
          <Menu name={option.name} />
        </div>
      ))}
    </div>
  );
}

export default ContextMenu;
