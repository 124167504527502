import { useTranslation } from "react-i18next";
import PageTitle from "./PageTitle";

type PageProps = {
  title: string;
  subtitle?: string;
} & React.HTMLAttributes<HTMLDivElement>;

function Page({ title, subtitle, children, className }: PageProps) {
  const { t } = useTranslation();
  return (
    <div className={`${className} animate__animated animate__fadeIn`}>
      <PageTitle title={title} />

      {subtitle && (
        <div className="text-gray font-light mt-4">{t(subtitle)}</div>
      )}
      <div className="mt-8">{children}</div>
    </div>
  );
}

export default Page;
