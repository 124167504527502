import { ReactNode } from "react";
import { Address, User } from "./account";
import { Dog } from "./mydog";
import Tag from "features/common/components/Tag";

export type OrderStatus =
  | "pending"
  | "paid"
  | "confirmedPaid"
  | "shipped"
  | "completed";

// export type OrderModel = {
//   id: number;
//   status: OrderStatus;
//   shippingDate: Date;
//   deliveryDate: Date;
//   paymentDate: Date;
//   price: number;
//   orderBy: User;
//   dogs: Dog[];
// };

export type Order = {
  id: string;
  status: OrderStatus;
  shippedAt: Date;
  deliveredAt: Date;
  frequency: number;
  paidAt: Date;
  paymentChannel: string;
  paymentSlipUrl: string;
  user: User;
  address: Address;
  dogs: Dog[];
  total: number;
  trackingNumber: string;
  createdAt: Date;
  updatedAt: Date;
};

export const getTagFromOrderStatus = (order: Order, className: string = "") => {
  let tag: ReactNode;
  if (order.status === "confirmedPaid") {
    tag = (
      <Tag tag="component.badge.paid" color="green" className={className} />
    );
  } else if (order.status === "pending") {
    tag = (
      <Tag tag="component.badge.planned" color="blue1" className={className} />
    );
  } else if (order.status === "shipped") {
    tag = (
      <Tag tag="component.badge.shipped" color="green" className={className} />
    );
  }
  return tag;
};
