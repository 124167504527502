export type MenuGenerationSpinnerProps =
  {} & React.HTMLAttributes<HTMLDivElement>;

function MenuGenerationSpinner({ className }: MenuGenerationSpinnerProps) {
  return (
    <div
      className={`w-full z-50 overflow-hidden opacity-70 flex flex-col items-center justify-center ${className}`}
    >
      <div
        className="loader ease-linear rounded-full border-8 border-t-8 border-lightGray h-20 w-20 mb-4"
        style={{ borderTopColor: "#A0B3C1" }}
      ></div>
    </div>
  );
}

export default MenuGenerationSpinner;
