import React from "react";
import { useTranslation } from "react-i18next";

type PillButtonProps = {
  name: string;
  onClick: () => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue" | "onChange">;

function PillButton({ name, onClick }: PillButtonProps) {
  const { t } = useTranslation();
  return (
    <button
      onClick={onClick}
      className="mt-3 rounded-[32px] border border-blue1 text-blue1 justify-center items-center px-5 py-3"
    >
      {t(name)}
    </button>
  );
}

export default PillButton;
