import { useTranslation } from "react-i18next";

type EachDogNameProps = {
  template: string;
  name: string;
} & React.HTMLAttributes<HTMLDivElement>;

function EachDogName({ template, name, className }: EachDogNameProps) {
  const { t } = useTranslation();
  return (
    <div className={`flex items-center ${className}`}>
      <div className="flex-1 h-px bg-lightGray"></div>
      <div className="mx-3 font-normal text-xl">
        {t(`start.${template}.dog.each`, {
          name: name,
        })}
      </div>
      <div className="flex-1 h-px bg-lightGray"></div>
    </div>
  );
}

export default EachDogName;
