import { intersection, round, uniqBy } from "lodash";
import { Breed } from "./dog-breed";
import { Menu } from "./meal";
import { ImageSelectOption } from "features/common/components/ImageSelect";
import { ReactComponent as IconBoy } from "assets/images/start/sex/boy.svg";
import { ReactComponent as IconGirl } from "assets/images/start/sex/girl.svg";
import { ButtonSelectOption } from "features/common/components/ButtonSelect";
import { ReactComponent as IconChunky } from "assets/images/start/body-type/chunky.svg";
import { ReactComponent as IconIdeal } from "assets/images/start/body-type/ideal.svg";
import { ReactComponent as IconObese } from "assets/images/start/body-type/obese.svg";
import { ReactComponent as IconSkinny } from "assets/images/start/body-type/skinny.svg";
import { Product } from "./product";

export type DogIdAndName = Pick<Dog, "name" | "id">;
export type DogNameFormValues = {
  names: DogIdAndName[];
};

export type DogIdAndSex = Pick<Dog, "id" | "name" | "sex">;
export type DogSexFormValues = {
  sexs: DogIdAndSex[];
};

export type DogIdAndIsNeutered = Pick<Dog, "id" | "name" | "isNeutered">;
export type DogNeuteredFormValues = {
  isNeutereds: DogIdAndIsNeutered[];
};

export type DogIdAndAge = Pick<
  Dog,
  "id" | "name" | "ageType" | "year" | "month" | "week"
>;

export type DogAgeForm = {
  main: number;
  sub: number;
} & Pick<Dog, "id" | "name" | "ageType">;
export type DogAgeFormValues = {
  ages: DogAgeForm[];
};

export type DogIdAndBreed = Pick<Dog, "id" | "name" | "breedId">;
export type DogBreedFormValues = {
  breeds: DogIdAndBreed[];
};

export type DogIdAndMainFood = Pick<Dog, "id" | "name" | "mainFoodType">;
export type DogMainFoodFormValues = {
  mainFoods: DogIdAndMainFood[];
};

export type DogIdAndEatingHabit = Pick<Dog, "id" | "name" | "eatingHabitType">;
export type DogEatingHabitFormValues = {
  eatingHabits: DogIdAndEatingHabit[];
};

export type DogIdAndWeight = Pick<Dog, "id" | "name" | "weight">;
export type DogWeightFormValues = {
  weights: DogIdAndWeight[];
};

export type DogAndBodyType = Pick<Dog, "id" | "name" | "bodyType">;
export type DogBodyTypeFormValues = {
  bodyTypes: DogAndBodyType[];
};

export type DogAndActivityHabitType = Pick<Dog, "name" | "activityHabitType">;
export type DogActivityHabitTypeFormValues = {
  activityHabitTypes: DogAndActivityHabitType[];
};

export type DogAndFoodRestriction = Pick<
  Dog,
  "name" | "hasFoodRestriction" | "foodRestrictionTypes"
>;
export type DogFoodRestrictionFormValues = {
  foodRestrictions: DogAndFoodRestriction[];
};

export type DogAndSpecialCondition = Pick<
  Dog,
  "name" | "hasSpecialCondition" | "specialConditionId"
>;
export type DogSpecialConditionFormValues = {
  specialConditions: DogAndSpecialCondition[];
};

export type SexType = "boy" | "girl";

export type AgeType = "puppy" | "adult";

export type MainFoodType = "kibble" | "wet" | "rawMeat" | "homemade";

export type EatingHabitType = "picky" | "goodEater" | "willEatAnything";

// export type BodyType = "skinny" | "ideal" | "chunky" | "obese";
export type BodyType = "skinny" | "ideal" | "obese";

export type ActivityHabitType = "lazy" | "active" | "athletic";

export const foodMeatIngredientTypes: FoodRestrictionType[] = [
  "beef",
  "chicken",
  "duck",
  "fish",
  "pork",
];

export type FoodRestrictionType =
  | "beef"
  | "potato"
  | "chicken"
  | "oat"
  | "duck"
  | "rice"
  | "fish"
  | "sweetPotato"
  | "pork"
  | "greenBeans"
  | "carrot"
  | "broccoli"
  | "pumpkin"
  | "apple"
  | "oliveOil"
  | "buckWheat"
  | "chiaSeed";

export type SpecialConditionType =
  | "diseases"
  | "cancer"
  | "epilepsy"
  | "cushingIsease"
  | "hypothyroidism";

export type Dog = {
  id: string;
  name: string;
  sex: SexType | null;
  isNeutered: boolean;
  ageType: AgeType;
  year: number;
  month: number;
  week: number;
  breedId: Breed["id"];
  mainFoodType: MainFoodType;
  eatingHabitType: EatingHabitType;
  weight: number;
  bodyType: BodyType;
  activityHabitType: ActivityHabitType;
  hasFoodRestriction: boolean;
  foodRestrictionTypes: FoodRestrictionType[];
  hasSpecialCondition: boolean;
  specialConditionId: number | null;
  menus: Menu[];
  dailyEnergy: number;
  isSuspended: boolean;
};

export type SpecialCondition = {
  id: number;
  nameTH: string;
  nameEN: string;
  descriptionEN: string;
  descriptionTH: string;
  showWarning: boolean;
};

export const specialConditions: SpecialCondition[] = [
  {
    id: 1,
    nameEN: "Cancer",
    nameTH: "มะเร็ง",
    descriptionEN:
      "For your dog with cancer, our Malenour fresh recipes or kibble are suitable, as they contain vitamins and antioxidants that support the immune system. If your dog needs to follow a diet or has a very specific cancer, do not hesitate to contact our veterinarians to guide you.",
    descriptionTH:
      "สำหรับสุนัขที่กำลังเผชิญกับโรคมะเร็ง มาลีนัวมีสูตรอาหารที่เหมาะสมกับโรคโดยเฉพาะ เนื่องจากมีวิตามินและสารต้านอนุมูลอิสระที่ช่วยกระตุ้นระบบภูมิคุ้มกัน หากสุนัขของคุณต้องควบคุมอาหารหรือเป็นเนื้อร้ายในที่เฉพาะเจาะจง อย่าลังเลที่จะรับคำแนะนำจากคุณหมอของเราในตอนนี้",
    showWarning: false,
  },
  {
    id: 2,
    nameEN: "Epilepsy",
    nameTH: "โรคลมชัก",
    descriptionEN:
      "Your dog with epilepsy can eat our Malenour fresh recipes or kibble without any problem.",
    descriptionTH:
      "สำหรับสุนัขที่มีอาการลมชัก สามารถกินอาหารของครัวมาลีนัวได้โดยไม่มีปัญหา",
    showWarning: false,
  },
  {
    id: 3,
    nameEN: "Cushing’s disease",
    nameTH: "ภาวะต่อมหมวกไตทำงานมากกว่าปกติ",
    descriptionEN:
      "Your dog with Cushing's disease can eat our Malenour fresh recipes or kibble without any problem.",
    descriptionTH:
      "สำหรับสุนัขที่มีภาวะต่อมหมวกไตทำงานมากกว่าปกติ สามารถกินอาหารของครัวมาลีนัวได้โดยไม่มีปัญหา",
    showWarning: false,
  },
  {
    id: 4,
    nameEN: "Hypothyroidism",
    nameTH: "ภาวะพร่องฮอร์โมนไทรอยด์",
    descriptionEN:
      "Your dog with a slow working thyroid can eat our Malenour fresh recipes or kibble without any problems.",
    descriptionTH:
      "สำหรับสุนัขที่มีภาวะพร่องฮอร์โมนไทรอยด์ สามารถกินอาหารของครัวมาลีนัวได้โดยไม่มีปัญหา",
    showWarning: false,
  },
  {
    id: 5,
    nameEN: "Heart disease",
    nameTH: "โรคหัวใจ",
    descriptionEN:
      "Your dog with heart problems can eat our fresh recipes or kibble without any problems, as they are supplemented with taurine to support the heart muscle. For dogs in an advanced stage of cardiac failure, our fresh recipes with pork or beef and our kibble are the most suitable because they contain the least sodium. Do not hesitate to contact our veterinarians to guide you.",
    descriptionTH:
      "สำหรับสุนัขที่กำลังเผชิญกับโรคหัวใจ สามารถกินอาหารของครัวมาลีนัวได้โดยไม่มีปัญหา เนื่องจากมีการเสริมทอรีนเพื่อเสริมสร้างกล้ามเนื้อหัวใจ โดยเฉพาะสุนัขที่มีภาวะหัวใจล้มเหลว สูตรอาหารที่มีส่วนประกอบของเนื้อหมูหรือเนื้อวัวจะเหมาะสมที่สุด เนื่องจากมีโซเดียมในปริมาณที่ต่ำ",
    showWarning: false,
  },
  {
    id: 6,
    nameEN: "Diabetes",
    nameTH: "เบาหวาน",
    descriptionEN:
      "Diabetes patients can have a normal weight, be very fat or too thin. Because they need a low glycemic load in their daily diet to stabilize their sugar level, Malenour is suitable for some patients, but most patients need extra advice. Please contact our veterinarians for personalized advice.",
    descriptionTH:
      "สำหรับสุนัขที่เป็นโรคเบาหวาน มีน้ำหนักอยู่ในเกณฑ์ปกติ อ้วน หรือ ผอม เกินไป สามารถกินอาหารของครัวมาลีนัวได้ เนื่องจากแต่ละมื้อจะต้องการปริมาณน้ำตาลที่ต่ำ เพื่อให้ระดับน้ำตาลในเลือดคงที่ โดยอาหารของครัวมาลีนัวมีความเหมาะสมกับสุนัขที่ป่วยเป็นบางตัว",
    showWarning: true,
  },
  {
    id: 7,
    nameEN: "Inflammatory Bowel Disease ; IBD",
    nameTH: "โรคลำไส้อักเสบเรื้อรัง",
    descriptionEN:
      "Many dogs with intestinal problems feel better when they eat fresh meals, that's why we recommend our Malenour fresh recipes rather than kibble, even though the latter might suit some dogs. Inflamatory Bowel Disease also has an immunological component, which is why we recommend our hypoallergenic fresh recipes with duck and fish or our kibble with duck. Please contact our veterinarians for personalized advice.",
    descriptionTH:
      "รู้ไหมว่าสุนัขหลายตัวที่มีปัญหาเกี่ยวกับลำไส้ จะรู้สึกดีขึ้นเมื่อกินอาหารสด เราจึงอยากแนะนำสูตรอาหารต่างๆ ในครัวมาลีนัวให้สุนัขของคุณกินมากกว่าอาหารเม็ดสำเร็จรูป โดยเฉพาะเมนูที่มีส่วนประกอบของเนื้อปลาหรือเนื้อเป็ด จะช่วยให้สุนัขของคุณไม่เกิดอาการแพ้ได้ง่าย",
    showWarning: false,
  },
  {
    id: 8,
    nameEN: "Pancreatitis",
    nameTH: "ตับอ่อนอักเสบ",
    descriptionEN:
      "If your dog has pancreatitis, he can't digest fat very well. Our Malenour fresh recipe with pork contains the least fat so your dog will probably do fine with this one, if he suffers from chronic pancreatitis. Our kibble may be suitable if your dog can digest the fat percentage. For dogs with acute pancreatitis, our recipes contain too much fat. Please contact our veterinarians for personalized advice.",
    descriptionTH:
      "สำหรับสุนัขที่กำลังเผชิญกับโรคตับอ่อนอักเสบ จะมีระบบย่อยไขมันได้ไม่ดีเท่าที่ควร ซึ่งอาหารของครัวมาลีนัวเราใช้เนื้อหมูที่มีไขมันน้อย เหมาะกับสุนัขของคุณที่กำลังป่วย แต่สำหรับสุนัขที่เป็นโรคตับอ่อนอักเสบเฉียบพลัน สูตรอาหารของเรามีปริมาณไขมันเกินกำหนด ควรปรึกษาคุณหมอก่อนตัดสินใจ",
    showWarning: true,
  },
  {
    id: 9,
    nameEN: "Exocrine Pancreatic Insufficiency ; EPI",
    nameTH: "โรคตับอ่อนทำงานบกพร่อง",
    descriptionEN:
      "Dogs with exocrine pancreatic insufficiency have poor fat digestion because they don't produce enough enzymes. Many of these dogs feel better when they eat fresh meals, this is why we rather recommend our Malenour fresh recipes rather than kibble. Our fresh recipe with pork contains the least fat so your dog will probably do fine with this recipe. Our kibble are quite low in fat, but this may still be too much for some patients. One solution may be to add certain ingredients to the meal to make them less fat. Please contact our veterinarians for personalized advice.",
    descriptionTH:
      "สำหรับสุนัขที่มีภาวะตับอ่อนทำงานบกพร่อง จะมีการย่อยไขมันได้ไม่ดีเท่าที่ควร เนื่องจากมีการผลิตเอนไซม์ได้ไม่เพียงพอ นั่นจึงเป็นสาเหตุให้สุนัขหลายตัวรู้สึกดีขึ้นเมื่อได้กินอาหารที่มีความสดใหม่ ครัวมาลีนัวอยากแนะนำให้เลือกสูตรอาหารที่มีเนื้อหมูเป็นส่วนประกอบ ซึ่งเราใช้เนื้อหมูที่มีไขมันน้อย แต่อย่างไรก็ตามควรปรึกษาคุณหมอก่อนตัดสินใจ",
    showWarning: false,
  },
  {
    id: 10,
    nameEN: "Gastrointestinal problem",
    nameTH: "โรคเกี่ยวกับทางเดินอาหาร",
    descriptionEN:
      "Many dogs with stomach problems feel better when they eat fresh meals, that's why we recommend our Malenour fresh recipes rather than kibble, even though the latter might suit some dogs. Our fresh recipes are suitable for most stomach problems. Our kibble may be suitable for dogs with stomach problems, if they are not intolerant to chicken or duck. Choose the duck recipe when in doubt, as they are hypoallergenic for most dogs. Do not hesitate to contact our veterinarians for personalized advice.",
    descriptionTH:
      "สำหรับสุนัขที่มีปัญหาเกี่ยวกับทางเดินอาหาร จะรู้สึกดีขึ้นเมื่อได้กินอาหารที่สดใหม่เราจึงอยากแนะนำสูตรอาหารต่างๆ ในครัวมาลีนัวให้สุนัขของคุณกินมากกว่าอาหารเม็ดสำเร็จรูป โดยเฉพาะเมนูที่มีส่วนประกอบของเนื้อปลาหรือเนื้อเป็ด จะช่วยให้สุนัขของคุณไม่เกิดอาการแพ้ได้ง่าย",
    showWarning: false,
  },
  {
    id: 11,
    nameEN: "Urolithiasis",
    nameTH: "นิ่วในทางเดินปัสสาวะ",
    descriptionEN:
      "If your dog suffers from crystals or stones in his urine, we recommend you to follow the advice of your own veterinarian. Dissolving bladder stones with a Malenour recipe is not possible, but the meals can in a lot of cases be given for prevention, once your dog is doing fine. Please contact or veterinarians for personalized advice.",
    descriptionTH:
      "หากสุนัขของคุณมีผลึกนิ่วในปัสสาวะ คุณควรได้รับคำแนะนำจากคุณหมอของเราก่อนตัดสินใจ เนื่องจากอาหารของครัวมาลีนัวไม่สามารถละลายนิ่วในทางเดินปัสสาวะได้ แต่ในกรณีที่สุนัขของคุณมีสุขภาพแข็งแรง เราสามารถทำเมนูอาหารเพื่อป้องกันการเกิดนิ่วในปัสสาวะได้",
    showWarning: true,
  },
  {
    id: 12,
    nameEN: "Kidney Disease",
    nameTH: "โรคไต",
    descriptionEN:
      "Whether you can feed Malenour fresh recipes or kibble to your dog depends on your dog's stage of kidney failure. If he's in stage 1 IRIS, you can give him our fresh recipes or kibble. For other more advanced stages, we advise you to contact our veterinarian for more information before ordering. ",
    descriptionTH:
      "เนื่องจากโรคไตมีหลายระยะ โดยแต่ละระยะมีการควบคุมอาหารที่ไม่เหมือนกัน คุณควรได้รับคำแนะนำจากคุณหมอของเราก่อนตัดสินใจ เพื่อตรวจสอบว่าสุนัขของคุณอยู่ในภาวะโรคไตระดับใดก่อนเลือกซื้ออาหาร",
    showWarning: true,
  },
  {
    id: 13,
    nameEN: "Liver Disease",
    nameTH: "โรคตับ",
    descriptionEN:
      "We can help many patients with liver disease, but one liver disease is not the other. Some dogs do not digest fats, others benefit from it. Please contact our veterinarians to see what is possible in terms of Malenour food before ordering.",
    descriptionTH:
      "โรคตับเป็นอีกหนึ่งโรคที่มีข้อจำกัดในการกินอาหาร เพื่อไม่ให้อาการของโรคแย่ลง คุณควรได้รับคำแนะนำจากคุณหมอของเราก่อนตัดสินใจ",
    showWarning: true,
  },
  {
    id: 14,
    nameEN: "Skin Disease",
    nameTH: "โรคผิวหนัง",
    descriptionEN:
      "Your dog with skin problems can eat our Malenour recipes without any problem. Skin problems can also have an immunological component, that is why we recommend our hypoallergenic fresh recipes with duck and fish or our duck kibble.",
    descriptionTH:
      "สำหรับสุนัขที่กำลังมีปัญหาเกี่ยวกับผิวหนัง สามารถกินอาหารของครัวมาลีนัวได้โดยไม่มีปัญหา แต่ปัญหาผิวหนังบางกรณีอาจมีปัจจัยด้านภูมิคุ้มกันเข้ามาเกี่ยวข้อง เราจึงอยากแนะนำสูตรอาหารต่างๆ ในครัวมาลีนัวให้สุนัขของคุณกินมากกว่าอาหารเม็ดสำเร็จรูป โดยเฉพาะเมนูที่มีส่วนประกอบของเนื้อปลาหรือเนื้อเป็ด จะช่วยให้สุนัขของคุณไม่เกิดอาการแพ้ได้ง่าย",
    showWarning: false,
  },
  {
    id: 15,
    nameEN: "Bone and Joint Disease",
    nameTH: "โรคกระดูกและข้อ",
    descriptionEN:
      "Your dog with joint problems can eat our Malenour fresh recipes or kibble without any problem. Our kibble is definitely suitable, as they contain the right amount of vitamins, minerals and are supplemented with glucosamine, chondroitin and herbs wich support the cartilage. You can combine the Malenour meals with joint nourishing supplements.",
    descriptionTH:
      "สำหรับสุนัขที่กำลังมีปัญหาเกี่ยวกับกระดูกและข้อ สามารถกินอาหารของครัวมาลีนัวได้โดยไม่มีปัญหา",
    showWarning: false,
  },
  {
    id: 16,
    nameEN: "Allergic",
    nameTH: "ภูมิแพ้",
    descriptionEN:
      "If your dog is allergic to commonly used protein sources, we recommend trying our fresh recipes with duck and fish or our duck kibble, which are hypoallergenic in most cases.",
    descriptionTH:
      "สำหรับสุนัขที่มีอาการแพ้สารอาหารที่เป็นแหล่งโปรตีนทั่วไป ครัวมาลีนัวขอแนะนำให้เลือกเมนูที่มีส่วนประกอบของเนื้อปลาหรือเนื้อเป็ดแทน เพราะจะช่วยให้สุนัขของคุณไม่เกิดอาการแพ้ได้ง่าย",
    showWarning: false,
  },
  {
    id: 17,
    nameEN: "Gestation",
    nameTH: "ตั้งท้อง",
    descriptionEN:
      "We advise you to contact our veterinarians for tailor-made advice.",
    descriptionTH: "แนะนำให้ปรึกษาคุณหมอของเราก่อนตัดสินใจเลือกซื้ออาหาร",
    showWarning: false,
  },
  {
    id: 18,
    nameEN: "Lactation",
    nameTH: "ระยะให้นม",
    descriptionEN:
      "We advise you to contact our veterinarians for tailor-made advice.",
    descriptionTH: "แนะนำให้ปรึกษาคุณหมอของเราก่อนตัดสินใจเลือกซื้ออาหาร",
    showWarning: false,
  },
  {
    id: 19,
    nameEN: "Others",
    nameTH: "อื่นๆ",
    descriptionEN:
      "We advise you to contact our veterinarians for tailor-made advice.",
    descriptionTH: "แนะนำให้ปรึกษาคุณหมอของเราก่อนตัดสินใจเลือกซื้ออาหาร",
    showWarning: false,
  },
];

export function dogsToMenus(dogs: Dog[]) {
  let menus: Menu[] = [];
  menus = dogs.reduce((acc, dog): Menu[] => {
    return [...acc, ...dog.menus];
  }, menus);

  menus = uniqBy(menus, "name");

  const menusWithDogs = menus.map((menu) => {
    const dogsWithMenu = dogs.filter((dog) => {
      return dog.menus.some((dogMenu) => dogMenu.name === menu.name);
    });
    return { menu, dogs: dogsWithMenu };
  });

  return menusWithDogs;
}

export function newDog(id: string, name: string): Dog {
  return {
    id,
    name,
    sex: null,
    isNeutered: false,
    ageType: "adult",
    year: 0,
    month: 0,
    week: 0,
    breedId: 0,
    mainFoodType: "kibble",
    eatingHabitType: "goodEater",
    weight: 0,
    bodyType: "ideal",
    activityHabitType: "active",
    hasFoodRestriction: false,
    foodRestrictionTypes: [],
    hasSpecialCondition: false,
    specialConditionId: null,
    menus: [],
    dailyEnergy: 0,
    isSuspended: false,
  };
}

export const sexOptions: ImageSelectOption<SexType>[] = [
  {
    label: "component.dog-select.boy.label",
    icon: <IconBoy />,
    value: "boy",
  },
  {
    label: "component.dog-select.girl.label",
    icon: <IconGirl />,
    value: "girl",
  },
];

export const neuteredOptions: ButtonSelectOption<boolean>[] = [
  {
    label: "button.neutered.no",
    value: false,
  },
  {
    label: "button.neutered.yes",
    value: true,
  },
];

export const ageTypeOptions: ButtonSelectOption<AgeType>[] = [
  {
    label: "start.my-dog.age.dog.button.adult",
    value: "adult",
  },
  {
    label: "start.my-dog.age.dog.button.puppy",
    value: "puppy",
  },
];

export const mainFoodOptions: ButtonSelectOption<MainFoodType>[] = [
  {
    label: "start.my-dog.main-food.kibble.button",
    value: "kibble",
  },
  {
    label: "start.my-dog.main-food.wet-food.button",
    value: "wet",
  },
  {
    label: "start.my-dog.main-food.raw-meat.button",
    value: "rawMeat",
  },
  {
    label: "start.my-dog.main-food.home-made-food.button",
    value: "homemade",
  },
];

export const eatingHabitOptions: ButtonSelectOption<EatingHabitType>[] = [
  {
    label: "start.my-dog.eating-habit.picky.button",
    value: "picky",
  },
  {
    label: "start.my-dog.eating-habit.good-eater.button",
    value: "goodEater",
  },
  {
    label: "start.my-dog.eating-habit.will-eat-anything.button",
    value: "willEatAnything",
  },
];

export function getEatingHabitTypeLabelByValue(value: EatingHabitType) {
  return (
    eatingHabitOptions.find((option) => option.value === value)?.label || value
  );
}

export const bodyTypeOptions: ImageSelectOption<BodyType>[] = [
  {
    value: "skinny",
    label: "start.my-dog.body-type.skinny.button",
    icon: <IconSkinny />,
  },
  {
    value: "ideal",
    label: "start.my-dog.body-type.ideal.button",
    icon: <IconIdeal />,
  },
  // {
  //   value: "chunky",
  //   label: "start.my-dog.body-type.chunky.button",
  //   icon: <IconChunky />,
  // },
  {
    value: "obese",
    label: "start.my-dog.body-type.obese.button",
    icon: <IconObese />,
  },
];

export function getBodyTypeLabelByValue(value: BodyType) {
  return (
    bodyTypeOptions.find((option) => option.value === value)?.label || value
  );
}

export const activityHabitTypeOptions: ImageSelectOption<ActivityHabitType>[] =
  [
    {
      value: "lazy",
      label: "start.my-dog.activity-type.lazy.button",
      icon: <IconSkinny />,
    },
    {
      value: "active",
      label: "start.my-dog.activity-type.active.button",
      icon: <IconIdeal />,
    },
    {
      value: "athletic",
      label: "start.my-dog.activity-type.athletic.button",
      icon: <IconChunky />,
    },
  ];

export function getActivityHabitTypeLabelByValue(value: ActivityHabitType) {
  return (
    activityHabitTypeOptions.find((option) => option.value === value)?.label ||
    value
  );
}

export const hasFoodRestrictionOptions: ButtonSelectOption<boolean>[] = [
  {
    label: "button.no",
    value: false,
  },
  {
    label: "button.yes",
    value: true,
  },
];

export const foodRestrictionOptions: ButtonSelectOption<FoodRestrictionType>[] =
  [
    {
      label: "start.my-dog.food-restriction-type.beef.button",
      value: "beef",
    },
    {
      label: "start.my-dog.food-restriction-type.potato.button",
      value: "potato",
    },
    {
      label: "start.my-dog.food-restriction-type.chicken.button",
      value: "chicken",
    },
    {
      label: "start.my-dog.food-restriction-type.oat.button",
      value: "oat",
    },
    {
      label: "start.my-dog.food-restriction-type.duck.button",
      value: "duck",
    },
    {
      label: "start.my-dog.food-restriction-type.rice.button",
      value: "rice",
    },
    {
      label: "start.my-dog.food-restriction-type.fish.button",
      value: "fish",
    },
    {
      label: "start.my-dog.food-restriction-type.sweetPotato.button",
      value: "sweetPotato",
    },
    {
      label: "start.my-dog.food-restriction-type.pork.button",
      value: "pork",
    },
    {
      label: "start.my-dog.food-restriction-type.greenBeans.button",
      value: "greenBeans",
    },
    {
      label: "start.my-dog.food-restriction-type.carrot.button",
      value: "carrot",
    },
    {
      label: "start.my-dog.food-restriction-type.broccoli.button",
      value: "broccoli",
    },
    {
      label: "start.my-dog.food-restriction-type.pumpkin.button",
      value: "pumpkin",
    },
    {
      label: "start.my-dog.food-restriction-type.apple.button",
      value: "apple",
    },
    {
      label: "start.my-dog.food-restriction-type.oliveOil.button",
      value: "oliveOil",
    },
    {
      label: "start.my-dog.food-restriction-type.buckWheat.button",
      value: "buckWheat",
    },
    {
      label: "start.my-dog.food-restriction-type.chiaSeed.button",
      value: "chiaSeed",
    },
  ];

export function getFoodRestrictionTypeLabelByValue(value: FoodRestrictionType) {
  return (
    foodRestrictionOptions.find((option) => option.value === value)?.label ||
    value
  );
}

export const hasSpecialConditionOptions: ButtonSelectOption<boolean>[] = [
  {
    label: "button.no",
    value: false,
  },
  {
    label: "button.yes",
    value: true,
  },
];

export function getMainSubFromAge(dog: Dog) {
  const main = dog.ageType === "adult" ? dog.year : dog.month;
  const sub = dog.ageType === "adult" ? dog.month : dog.week;
  return { main, sub };
}

export function getYearMonthWeekFromMainSub(
  ageType: AgeType,
  main: number,
  sub: number
) {
  let year: number = 0,
    month: number = 0,
    week: number = 0;

  if (ageType === "adult") {
    year = main;
    month = sub;
    week = 0;
  } else if (ageType === "puppy") {
    year = 0;
    month = main;
    week = sub;
  }

  return { year, month, week };
}

export function fillMenusToDogs(dogs: Dog[], products: Product[]) {
  dogs.forEach((dog) => {
    dog.menus.forEach((menu) => {
      const product = products.find((product) => product.id === menu.id);
      if (product) {
        menu.imageUrl = product.imageUrl;
        menu.name = product.name;
        menu.description = product.description;
      } else {
        menu.imageUrl = "";
        menu.name = "";
        menu.description = "";
      }
    });
  });
}

export function calculateDailyEnergyRequirement(dogs: Dog[]) {
  const dailyEnergyRequirements = dogs.map((dog) => {
    const restingEnergyRequirement = 70 * Math.pow(dog.weight, 0.75);
    return round(restingEnergyRequirement * calculateConditionFactor(dog));
  });
  return dailyEnergyRequirements;
}

function calculateConditionFactor(dog: Dog) {
  if (dog.bodyType === "obese") {
    return 1.1;
  }

  if (dog.bodyType === "ideal") {
    if (dog.activityHabitType === "athletic") {
      return dog.isNeutered ? 1.2 : 1.4;
    } else if (dog.activityHabitType === "active") {
      return dog.isNeutered ? 1.1 : 1.2;
    } else {
      return 1.1;
    }
  }

  if (dog.bodyType === "skinny") {
    if (dog.activityHabitType === "athletic") {
      return dog.isNeutered ? 1.6 : 1.8;
    } else if (dog.activityHabitType === "active") {
      return 1.6;
    } else {
      return dog.isNeutered ? 1.1 : 1.2;
    }
  }

  return 1.1;
}

export function calculateFoodAmountPerBox(
  dogDailyEnergy: number,
  foodEneryPerDay: number
) {
  return round((dogDailyEnergy / foodEneryPerDay) * 100);
}

export function calculatePricePerBox(
  amountPerBox: number,
  productPrice: number
) {
  return round((amountPerBox / 100) * productPrice);
}

export const calculateTotalPrice = (
  menus: Menu[][],
  products: Product[]
): number => {
  const total: number = menus.reduce((acc: number, menus: Menu[]) => {
    const eachMenuSum: number = menus.reduce((acc: number, menu: Menu) => {
      const product = products.find((product) => product.id === menu.id);
      const price =
        calculatePricePerBox(menu.amountPerBox, product!.price) * menu.boxNum;
      return acc + price;
    }, 0);
    return acc + eachMenuSum;
  }, 0);

  return total;
};

export function findAllergicIngredientsInProduct(
  product: Product,
  allergicTo: FoodRestrictionType[]
) {
  const allergicIngredientsInProduct = intersection(
    product.ingredients,
    allergicTo
  );
  return allergicIngredientsInProduct;
}
