import dayjs from "dayjs";
import AddressInfo from "features/common/components/AddressInfo";
import WarningBox from "features/common/components/WarningBox";
import {
  IconCalendar,
  IconDoubleBathCoins,
  IconNoodleBowl,
  IconTruck,
} from "features/common/icon";
import { join, map } from "lodash";
import { Account } from "models/account";
import { Dog } from "models/mydog";
import { Order, getTagFromOrderStatus } from "models/order";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoaderFunction, NavLink, useLoaderData } from "react-router-dom";
import { accountService } from "resources/account";
import { orderService } from "resources/order";
import { ProductResponse, productService } from "resources/product";
import MySubscriptionPane from "../components/MySubscriptionPane";
import Page from "../components/Page";
import UpdateActiveOrderModal from "../components/UpdateActiveOrderModal";

export const loader: LoaderFunction = async () => {
  const [me, order, products] = await Promise.all([
    accountService.getMe(),
    orderService.getAciveOrder(),
    productService.getProducts(),
  ]);
  return { me, order, products };
};

type NextDeliveryDetailPaneProps = {
  icon: ReactNode;
  header: string;
} & React.HTMLAttributes<HTMLDivElement>;
function NextDeliveryDetailPane({
  icon,
  header,
  children,
}: NextDeliveryDetailPaneProps) {
  return (
    <>
      <div className="flex items-center gap-x-3 fill-black">
        {icon}
        <div className="font-normal text-xl">{header}</div>
      </div>
      <div className="text-left mt-2 pb-3 mb-3 border-b border-lightGray flex flex-col gap-y-4">
        {children}
      </div>
    </>
  );
}

type NextDeliveryPaneProps = {
  order: Order;
} & React.HTMLAttributes<HTMLDivElement>;
function NextDeliveryPane({ order }: NextDeliveryPaneProps) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex items-center gap-3">
        <IconCalendar className="fill-orange" />
        <div className="text-2xl font-normal">
          {t("home.dashboard.next-delivery.title")}
        </div>
      </div>
      <div className="bg-white shadow-thick rounded-xl p-5 mt-4">
        <div className="flex justify-between border-b border-lightGray pb-3 mb-3 items-center">
          <div className="font-normal text-xl">
            {dayjs(order.deliveredAt).format("dddd DD MMMM")}
          </div>
          <div className="flex items-center gap-x-3">
            {getTagFromOrderStatus(order)}
            <div className="text-blue1">
              <NavLink to={`/home/deliveries/${order.id}`}>
                {t(
                  order.status === "pending"
                    ? "button.view-edit"
                    : "button.view"
                )}
              </NavLink>
            </div>
          </div>
        </div>

        <NextDeliveryDetailPane icon={<IconNoodleBowl />} header="Fresh meals">
          {order.dogs.map((dog, idx) => (
            <div key={idx}>
              <div className="font-normal">{dog.name}</div>
              <div className="grid grid-cols-2 gap-x-4 gap-y-1" key={idx}>
                {dog.menus.map((menu, idx) => (
                  <div key={idx}>
                    {menu.name} X {menu.boxNum} box ({menu.amountPerBox}g/box)
                  </div>
                ))}
              </div>
            </div>
          ))}
        </NextDeliveryDetailPane>

        <NextDeliveryDetailPane icon={<IconDoubleBathCoins />} header="Extras">
          This delivery has no extras included
        </NextDeliveryDetailPane>

        <NextDeliveryDetailPane icon={<IconTruck />} header="Delivery address">
          <AddressInfo address={order.address} />
        </NextDeliveryDetailPane>

        <WarningBox className="text-left py-6 px-7 mt-2">
          <div className="font-normal">Your subscription starts here...</div>
          <div className="font-normal">
            {dayjs(order.deliveredAt).format("dddd DD MMMM")}
          </div>
          <div className="mt-4">
            You will get an mail before we start to cook to make sure you still
            want your second box.
          </div>
        </WarningBox>
        {/* <div className="flex items-center gap-x-3 text-blue1 mt-6">
          <div className="bg-blue1 rounded-full text-4xl text-white px-2 shadow-thick">
            +
          </div>
          <div className="font-normal text-xl">Add coupon</div>
        </div> */}
      </div>
    </div>
  );
}

export function DashboardPage() {
  const { t } = useTranslation();
  const { me, order, products } = useLoaderData() as {
    me: Account;
    order: Order;
    products: ProductResponse[];
  };
  const [activeOrder, setActiveOrder] = useState(order);
  const [dogs, setDogs] = useState(me.dogs);
  const [delivery, setDelivery] = useState(me.delivery);
  const [showUpdateActiveOrderModal, setShowUpdateActiveOrderModal] =
    useState(false);

  const dogNames = join(map(me.dogs, "name"), ", ");

  const updateDogs = async (updatedDogs: Dog[]) => {
    await accountService.updateDogs(updatedDogs);
    const { dogs } = await accountService.getMe();
    setDogs(dogs);
    if (activeOrder.status === "pending") {
      setShowUpdateActiveOrderModal(true);
    }
  };

  return (
    <Page
      title={`${t("home.dashboard.jumbotron.title", {
        name: me.user.firstName,
      })}`}
      subtitle={`${t("home.dashboard.jumbotron.subtitle", {
        dogNames: dogNames,
      })}`}
    >
      <div className="grid sm:grid-cols-2 gap-8">
        <NextDeliveryPane order={activeOrder} />
        <MySubscriptionPane
          dogs={dogs}
          delivery={delivery}
          products={products}
          onDogsUpdated={updateDogs}
          onFrequencyUpdated={(frequency) => {
            setDelivery({ ...delivery, frequency });
          }}
        />
      </div>
      <UpdateActiveOrderModal
        isOpen={showUpdateActiveOrderModal}
        onClose={() => {
          setShowUpdateActiveOrderModal(false);
        }}
        onConfirm={async () => {
          await orderService.updateDogs(activeOrder.id, dogs);
          const order = await orderService.getAciveOrder();
          setActiveOrder(order);
          setShowUpdateActiveOrderModal(false);
        }}
      />
    </Page>
  );
}
