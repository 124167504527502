import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import { IconExclamationMark } from "../icon";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  header: string;
  description: string;
  image: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

function ConfirmModal({
  isOpen,
  onClose,
  onConfirm,
  header,
  description,
}: ModalProps) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={() => {}} className="rounded-2xl modal-sm">
      <div className="p-7">
        <div className="bg-red bg-opacity-10 rounded-full w-[60px] h-[60px] flex justify-center items-center">
          <IconExclamationMark className="fill-red" />
        </div>

        <h3 className="text-2xl mt-8 font-normal">{t(header)}</h3>
        <div className="font-light mt-6">{t(description)}</div>
      </div>
      <div className="bg-lightGray grid grid-cols-2 gap-x-4 py-5 px-7 rounded-b-2xl">
        <input
          className="form-submit back-button button rounded-lg shadow-none border border-gray"
          type="button"
          onClick={onClose}
          value={`${t("dialog.button.cancel")}`}
        />
        <input
          className="form-submit button primary-button rounded-lg shadow-none"
          type="submit"
          onClick={onConfirm}
          value={`${t("dialog.button.yes")}`}
        />
      </div>
    </Modal>
  );
}

export default ConfirmModal;
