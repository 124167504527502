import EachDogName from "features/common/components/EachDogName";
import ErrorText from "features/common/components/ErrorText";
import WeightSlider from "features/common/components/WeightSlider";
import { onInvalidScrollToElement } from "features/common/utils";
import { useValidate } from "features/hook/useValidate";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { get, pick } from "lodash";
import { DogWeightFormValues } from "models/mydog";
import "rc-slider/assets/index.css";
import { useState } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function DogWeightPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dogs } = useOnboarding();
  const { updateDogWeight } = useOnboardingDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<DogWeightFormValues>({
    defaultValues: {
      weights: dogs.map((dog) => pick(dog, ["id", "name", "weight"])),
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "weights",
  });
  const { validateWeight } = useValidate();

  const updateToStore = (data: DogWeightFormValues) => {
    const weights = data.weights.map((dog) => dog.weight!);
    updateDogWeight(weights);
  };

  const onSubmit: SubmitHandler<DogWeightFormValues> = async (data) => {
    updateToStore(data);
    navigate("/start/my-dog/9/body-type");
  };

  const [weights, setWeights] = useState<number[]>(
    dogs.map((dog) => dog.weight)
  );

  return (
    <Page title="start.my-dog.weight.title">
      <form onSubmit={handleSubmit(onSubmit, onInvalidScrollToElement)}>
        {fields.map((field, index) => (
          <div
            key={index}
            className="mt-4 mb-10 flex flex-col py-4 px-2 sm:px-12 md:px-24"
          >
            <EachDogName template="my-dog.weight" name={field.name} />

            <Controller
              control={control}
              name={`weights.${index}.weight`}
              rules={{
                validate: validateWeight,
              }}
              render={({ field: { onChange, value } }) => (
                <WeightSlider
                  defaultValue={weights[index]}
                  onChange={onChange}
                />
              )}
            />

            <ErrorText
              className="mt-12"
              error={get(errors, `weights[${index}].weight.message`)}
            />
          </div>
        ))}
        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/my-dog/7/eating-habit");
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default DogWeightPage;
