import { yupResolver } from "@hookform/resolvers/yup";
import APIErrorBox from "features/common/components/APIErrorBox";
import Input from "features/common/components/Input";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EmailLoginRequest, anonymousService } from "resources/anonymous";
import { ApiError } from "resources/http-client";
import imgUser from "assets/images/login/user.png";
import * as yup from "yup";

type EmailPasswordLoginFormProps = {
  onSuccess: (token: string) => void;
};

const EmailPasswordLoginForm = ({ onSuccess }: EmailPasswordLoginFormProps) => {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<ApiError | undefined>();
  const formSchema = yup.object().shape({
    email: yup
      .string()
      .required(`${t("form.validation.required")}`)
      .email(`${t("form.validation.invalid-email")}`),
    password: yup.string().required(`${t("form.validation.required")}`),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailLoginRequest>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (data: EmailLoginRequest) => {
    setApiError(undefined);
    try {
      const token = await anonymousService.emailLogin(data);
      onSuccess(token);
    } catch (err) {
      setApiError(err as ApiError);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="shadow-thick p-8 rounded-2xl"
    >
      <img src={imgUser} alt="user" className="w-[93px] h-[88px] mx-auto" />
      <h3 className="mt-6 font-normal text-xl">{t("login.box.title")}</h3>
      <div className="text-gray mt-2">{t("login.box.description")}</div>
      <fieldset className="mt-7">
        <Input
          type="email"
          label="form.field.email"
          placeholder="form.field.email"
          {...register("email")}
          errors={errors}
        />
      </fieldset>
      <fieldset className="mt-2">
        <Input
          type="password"
          label="form.field.password"
          placeholder="form.field.password"
          {...register("password")}
          errors={errors}
        />
        <APIErrorBox err={apiError} />
      </fieldset>
      <div
        className="mt-2 text-right cursor-pointer"
        onClick={() => {
          alert("not available yet");
        }}
      >
        {t("login.box.recover-password.button")}
      </div>
      <input
        className="form-submit button primary-button mt-5"
        type="submit"
        value={`${t("button.signin")}`}
      />
    </form>
  );
};

export default EmailPasswordLoginForm;
