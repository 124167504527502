import DeliveryFrequencySelect from "features/common/components/DeliveryFrequencySelect";
import { onInvalidScrollToElement } from "features/common/utils";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { DeliveryFrequencyFormValues } from "models/account";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function DeliveryFrequencyPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { delivery } = useOnboarding();
  const { updateDelivery } = useOnboardingDispatch();

  const { handleSubmit, control, getValues } =
    useForm<DeliveryFrequencyFormValues>({
      defaultValues: {
        frequency: delivery.frequency,
      },
    });

  const updateToStore = (data: DeliveryFrequencyFormValues) => {
    updateDelivery({
      ...delivery,
      frequency: data.frequency,
    });
  };

  const onSubmit: SubmitHandler<DeliveryFrequencyFormValues> = async (data) => {
    updateToStore(data);
    navigate("/start/menu/selection");
  };

  return (
    <Page
      title="start.menu.delivery-frequency.title"
      subtitle="start.menu.delivery-frequency.subtitle"
    >
      <form onSubmit={handleSubmit(onSubmit, onInvalidScrollToElement)}>
        <Controller
          control={control}
          name="frequency"
          render={({ field: { onChange, value } }) => (
            <DeliveryFrequencySelect defaultValue={value} onChange={onChange} />
          )}
        />
        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            value={`${t("start.navbuttonbar.back")}`}
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/menu/recommended-daily-portion");
            }}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.choose-meal-plan")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default DeliveryFrequencyPage;
