import { IconDog } from "features/common/icon";
import ConfirmModal from "features/common/modals/ConfirmModal";
import { Account, Delivery } from "models/account";
import { Dog, newDog } from "models/mydog";
import { Order } from "models/order";
import { Product } from "models/product";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoaderFunction, useLoaderData } from "react-router-dom";
import { accountService } from "resources/account";
import { orderService } from "resources/order";
import { productService } from "resources/product";
import AddButton from "../components/AddButton";
import DogModal from "../components/DogModal";
import MySubscriptionPane from "../components/MySubscriptionPane";
import Page from "../components/Page";
import UpdateActiveOrderModal from "../components/UpdateActiveOrderModal";

export const loader: LoaderFunction = async () => {
  const [me, order, products] = await Promise.all([
    accountService.getMe(),
    orderService.getAciveOrder(),
    productService.getProducts(),
  ]);
  return { me, order, products };
};

type FamilyDogPaneProps = {
  idx: number;
  products: Product[];
  deliveryFrequency: number;
  dog: Dog;
  onUpdatedDog: (dog: Dog) => void;
  onUpdateSubscription: (index: number) => void;
  canDelete: boolean;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue">;
function FamilyDogPane({
  idx,
  products,
  deliveryFrequency,
  dog,
  onUpdatedDog,
  onUpdateSubscription,
  canDelete,
}: FamilyDogPaneProps) {
  const { t } = useTranslation();
  const [showDogModal, setShowDogModal] = useState(false);

  return (
    <div
      key={dog.id}
      className={`flex gap-4 items-center pb-5 border-b border-b-lightGray last:border-b-0 last:pb-0 ${
        dog.isSuspended && "opacity-50"
      }`}
    >
      <IconDog className="fill-orange w-20 h-14" />
      <div className="text-left font-normal">
        <div>{dog.name}</div>
        <div className="flex gap-x-8 text-left">
          <button
            onClick={() => {
              setShowDogModal(true);
            }}
            className="mt-3 text-blue1"
          >
            {t("home.subscription.family.edit.button")}
          </button>
          {canDelete && (
            <button
              onClick={() => {
                onUpdateSubscription(idx);
              }}
              className="mt-3 text-blue1"
            >
              {dog.isSuspended
                ? t("home.subscription.family.resume-delivery.button")
                : t("home.subscription.family.pause-delivery.button")}
            </button>
          )}
        </div>
      </div>
      <DogModal
        products={products}
        deliveryFrequency={deliveryFrequency}
        defaultValue={dog}
        isOpen={showDogModal}
        onClose={() => {
          setShowDogModal(false);
        }}
        onConfirm={(edittedDog) => {
          setShowDogModal(false);
          onUpdatedDog(edittedDog);
        }}
      />
    </div>
  );
}

type FamilyPaneProps = {
  dogs: Dog[];
  products: Product[];
  delivery: Delivery;
  onDogsUpdated: (dogs: Dog[]) => Promise<void>;
} & React.HTMLAttributes<HTMLDivElement>;
function FamilyPane({
  products,
  dogs,
  delivery,
  onDogsUpdated,
}: FamilyPaneProps) {
  const { t } = useTranslation();
  const [showDogModal, setShowDogModal] = useState(false);
  const [showUpdateDogSubscriptionModal, setShowUpdateDogSubscriptionModal] =
    useState(false);
  const [updateSubscriptionIndex, setUpdateSubscriptionIndex] = useState(0);

  return (
    <div>
      <div className="flex justify-between">
        <div className="text-2xl flex gap-2 items-center font-normal">
          <IconDog className="fill-orange w-8 h-6" />
          {t("home.subscription.family.title")}
        </div>
        <AddButton
          name="home.subscription.family.add.button"
          onClick={() => {
            setShowDogModal(true);
          }}
        />
      </div>
      <div className="bg-white shadow-thick rounded-xl p-5 mt-4 flex flex-col gap-y-6">
        {dogs.map((dog, mainIdx) => (
          <FamilyDogPane
            products={products}
            deliveryFrequency={delivery.frequency}
            key={mainIdx}
            idx={mainIdx}
            dog={dog}
            onUpdatedDog={async (edittedDog) => {
              const resultDogs = dogs.map((dog, idx) => {
                return idx === mainIdx ? edittedDog! : dog;
              });
              await onDogsUpdated(resultDogs);
            }}
            onUpdateSubscription={async (idx) => {
              setUpdateSubscriptionIndex(idx);
              setShowUpdateDogSubscriptionModal(true);
            }}
            canDelete={dogs.length > 1}
          />
        ))}
        <DogModal
          products={products}
          deliveryFrequency={delivery.frequency}
          defaultValue={newDog("", "")}
          isOpen={showDogModal}
          onClose={() => {
            setShowDogModal(false);
          }}
          onConfirm={async (edittedDog) => {
            const resultDogs = [...dogs, edittedDog];
            await onDogsUpdated(resultDogs);
            setShowDogModal(false);
          }}
        />
        <ConfirmModal
          header={t(
            `component.modal.${
              dogs[updateSubscriptionIndex].isSuspended ? "resume" : "stop"
            }-subscription.title`,
            {
              name: dogs[updateSubscriptionIndex]?.name ?? "",
            }
          )}
          description={t(
            `component.modal.${
              dogs[updateSubscriptionIndex].isSuspended ? "resume" : "stop"
            }-subscription.description`,
            {
              name: dogs[updateSubscriptionIndex]?.name ?? "",
            }
          )}
          image={<div></div>}
          isOpen={showUpdateDogSubscriptionModal}
          onClose={() => {
            setShowUpdateDogSubscriptionModal(false);
          }}
          onConfirm={async () => {
            const resultDogs = [...dogs];
            resultDogs[updateSubscriptionIndex] = {
              ...resultDogs[updateSubscriptionIndex],
              isSuspended: !resultDogs[updateSubscriptionIndex].isSuspended,
            };

            await onDogsUpdated(resultDogs);
            setShowUpdateDogSubscriptionModal(false);
          }}
        />
      </div>
    </div>
  );
}

function MySubscriptionPage() {
  const { t } = useTranslation();
  const { me, products } = useLoaderData() as {
    me: Account;
    order: Order;
    products: Product[];
  };
  const [dogs, setDogs] = useState(me.dogs);
  const [delivery, setDelivery] = useState(me.delivery);
  const [showUpdateActiveOrderModal, setShowUpdateActiveOrderModal] =
    useState(false);

  const updateDogs = async (updatedDogs: Dog[]) => {
    await accountService.updateDogs(updatedDogs);
    const { dogs } = await accountService.getMe();
    setDogs(dogs);
    const activeOrder = await orderService.getAciveOrder();
    if (activeOrder.status === "pending") {
      setShowUpdateActiveOrderModal(true);
    }
  };

  return (
    <Page
      title={`${t("home.subscription.jumbotron.title")}`}
      subtitle="home.subscription.jumbotron.subtitle"
    >
      <div className="grid sm:grid-cols-2 gap-8">
        <FamilyPane
          dogs={dogs}
          products={products}
          delivery={delivery}
          onDogsUpdated={updateDogs}
        />
        <MySubscriptionPane
          dogs={dogs}
          products={products}
          delivery={delivery}
          onDogsUpdated={updateDogs}
          onFrequencyUpdated={(frequency) => {
            setDelivery({
              ...delivery,
              frequency,
            });
          }}
        />
      </div>
      <UpdateActiveOrderModal
        isOpen={showUpdateActiveOrderModal}
        onClose={() => {
          setShowUpdateActiveOrderModal(false);
        }}
        onConfirm={async () => {
          const activeOrder = await orderService.getAciveOrder();
          await orderService.updateDogs(activeOrder.id, dogs);
          setShowUpdateActiveOrderModal(false);
        }}
      />
    </Page>
  );
}

export default MySubscriptionPage;
