import axios, { AxiosError } from "axios";
import { removeToken } from "features/common/storage";

interface APIErrorResponse {
  code: string;
  message: string;
}

export class ApiError extends Error {
  code: string;

  constructor(message: string, code: string) {
    super(message);
    this.name = "ApiError";
    this.code = code;
  }
}

export const authenHttpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

authenHttpClient.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.replace("/login");
    }

    config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
authenHttpClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError<APIErrorResponse>) => {
    let apiError: ApiError;
    if (error.response && error.response.status === 401) {
      // Redirect to the login page
      apiError = new ApiError(
        "API request failed",
        "401",
      );
      removeToken()
      window.location.replace("/login");
    } else if (error.response && error.response.status === 409) {
      apiError = new ApiError(
        "API request failed",
        error.response.data.code,
      );
    } else {
      // Create a generic instance of ApiError for other types of errors
      apiError = new ApiError("Network error", "");
    }

    return Promise.reject(apiError);
  }
);


export const anonymousHttpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

anonymousHttpClient.interceptors.request.use(
  function (config) {
    const email = localStorage.getItem("email");

    config.headers["X-EMAIL"] = email;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
anonymousHttpClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError<APIErrorResponse>) => {
    let apiError: ApiError;
    if (error.response && error.response.status === 409) {
      apiError = new ApiError(
        "API request failed",
        error.response.data.code,
      );
    } else {
      // Create a generic instance of ApiError for other types of errors
      apiError = new ApiError("Network error", "");
    }

    return Promise.reject(apiError);
  }
);
