import imgBg from "assets/images/login/bg.jpg";
import imgLogo from "assets/images/logo2.png";
import { setToken } from "features/common/storage";
import { useNavigate } from "react-router-dom";
import EmailPasswordLoginForm from "../components/EmailPasswordLoginForm";

const LoginPage = () => {
  const navigate = useNavigate();
  const loginSuccess = (token: string) => {
    setToken(token);
    navigate("/home/dashboard");
  };

  return (
    <main className="grid xl:grid-cols-12">
      <div className="xl:col-span-8 h-screen hidden xl:block">
        <img src={imgBg} alt="bg" className="w-full h-full object-cover" />
      </div>
      <div className="xl:col-span-4 flex flex-col justify-center items-center gap-y-7 h-screen">
        <img src={imgLogo} alt="logo" className="w-[211px] h-[211px]" />
        <div className="">
          <div className="">
            <EmailPasswordLoginForm onSuccess={loginSuccess} />
          </div>
          {/* <FacebookLoginButton onSuccess={loginSuccess} />
        <GoogleLoginButton onSuccess={loginSuccess} /> */}
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
