import ImageSelect from "features/common/components/ImageSelect";
import { useTranslation } from "react-i18next";

import EachDogName from "features/common/components/EachDogName";
import ErrorText from "features/common/components/ErrorText";
import { onInvalidScrollToElement } from "features/common/utils";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { get, pick } from "lodash";
import { DogSexFormValues, SexType, sexOptions } from "models/mydog";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";

function DogSexPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateDogSex } = useOnboardingDispatch();
  const { dogs } = useOnboarding();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<DogSexFormValues>({
    defaultValues: {
      sexs: dogs.map((dog) => pick(dog, ["id", "sex", "name"])),
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "sexs",
  });

  const updateToStore = (data: DogSexFormValues) => {
    const sexes = data.sexs.map((dog) => dog.sex!);
    updateDogSex(sexes);
  };

  const onSubmit: SubmitHandler<DogSexFormValues> = async (data) => {
    updateToStore(data);
    navigate("/start/my-dog/3/neutered");
  };

  return (
    <Page title="start.my-dog.sex.title">
      <form onSubmit={handleSubmit(onSubmit, onInvalidScrollToElement)}>
        {fields.map((field, index) => (
          <div key={index} className="md:px-36">
            <EachDogName template="my-dog.sex" name={field.name} />
            <Controller
              control={control}
              rules={{
                required: `${t("form.validation.required")}`,
              }}
              name={`sexs.${index}.sex`}
              render={({ field: { onChange, value } }) => (
                <ImageSelect<SexType>
                  className="grid grid-cols-2 gap-4 mt-4"
                  options={sexOptions}
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
            <ErrorText error={get(errors, `sexs[${index}].sex.message`)} />
          </div>
        ))}

        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/my-dog/1/name");
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default DogSexPage;
