import DeliveryFrequencySelect from "features/common/components/DeliveryFrequencySelect";
import InfoBox from "features/common/components/InfoBox";
import Modal from "features/common/modals/Modal";
import { DeliveryFrequencyFormValues } from "models/account";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { accountService } from "resources/account";

type DeliveryFrenquencyModalProps = {
  defaultValue: number;
  price: number;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (frequency: number) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue">;

function DeliveryFrequencyModal({
  defaultValue,
  price,
  isOpen,
  onClose,
  onConfirm,
}: DeliveryFrenquencyModalProps) {
  const { t } = useTranslation();

  const { control, reset, handleSubmit } = useForm<DeliveryFrequencyFormValues>(
    {
      defaultValues: {
        frequency: defaultValue,
      },
    }
  );

  const onSubmit: SubmitHandler<DeliveryFrequencyFormValues> = async (data) => {
    const frequency = parseInt(data.frequency.toString());
    await accountService.updateDeliveryFrequency({ frequency });
    onConfirm(data.frequency);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} className="rounded-2xl modal-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-7">
          <h3 className="text-2xl font-normal">
            {t("home.modal.delivery-frequency.title")}
          </h3>
          <InfoBox className="mt-4">
            {t("home.modal.delivery-frequency.description")}
          </InfoBox>
          <div className="mt-4">
            {t("home.modal.delivery-frequency.select")}
          </div>
          <div className="flex flex-col gap-y-2 mt-3">
            <Controller
              control={control}
              name="frequency"
              render={({ field: { onChange, value } }) => (
                <DeliveryFrequencySelect
                  defaultValue={value}
                  price={price}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <div className="bg-lightGray grid grid-cols-2 gap-x-4 py-5 px-7 rounded-b-2xl">
          <input
            className="form-submit back-button button rounded-lg shadow-none border border-gray"
            type="button"
            onClick={() => {
              reset();
              onClose();
            }}
            value={`${t("dialog.button.cancel")}`}
          />
          <input
            className="form-submit button primary-button rounded-lg shadow-none"
            type="submit"
            value={`${t("dialog.button.confirm")}`}
          />
        </div>
      </form>
    </Modal>
  );
}

export default DeliveryFrequencyModal;
