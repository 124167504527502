import React from "react";
import { useTranslation } from "react-i18next";

type DeliveryFrequencySelectProps = {
  defaultValue: number;
  price?: number;
  onChange: (value: number) => void;
};
function DeliveryFrequencySelect({
  defaultValue,
  price,
  onChange,
}: DeliveryFrequencySelectProps) {
  const { t } = useTranslation();
  const frequencies = [7, 14, 28];

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    onChange(parseInt(selectedValue));
  };

  return (
    <div className="flex flex-col gap-y-1">
      {frequencies.map((frequency) => (
        <label
          key={frequency}
          className={`md:flex justify-between py-5 px-7 rounded-lg border-2 cursor-pointer ${
            frequency === defaultValue
              ? "border-blue1 text-black"
              : "border-lightGray text-gray"
          }`}
        >
          <div>
            <input
              type="radio"
              value={frequency}
              checked={frequency === defaultValue}
              className="mr-2"
              onChange={handleOptionChange}
            />
            {t("home.modal.delivery-frequency.select.choice", {
              frequency: frequency / 7,
              numBox: frequency,
            })}
          </div>
          {price && (
            <div className="text-xl font-normal">{price} ฿ / day per dog</div>
          )}
        </label>
      ))}
    </div>
  );
}

export default DeliveryFrequencySelect;
