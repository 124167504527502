import imgCheckMark from "assets/images/start/checkout/summary/checkmark.png";
import BankTransferPaymentView from "features/common/components/BankTransferPaymentView";
import FormattedAmount from "features/common/components/FormattedAmount";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import PageTitle from "features/start/components/PageTitle";
import { round } from "lodash";
import { Menu } from "models/meal";
import { calculatePricePerBox } from "models/mydog";
import { Order } from "models/order";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom";
import { anonymousService } from "resources/anonymous";

export const loader: LoaderFunction = async () => {
  const order = await anonymousService.getOrder();
  return order;
};

type MenuItemProps = {
  menu: Menu;
};

function MenuItem({ menu }: MenuItemProps) {
  return (
    <div className="flex justify-between items-center py-4 border-b border-lightGray">
      <div className="flex gap-x-4 justify-start items-center">
        <img
          src={menu.imageUrl}
          alt={menu.name}
          className="rounded-full w-[70px] h-[70px]"
        />
        <div className="text-left">
          <div>{menu.name}</div>
          <div className="text-gray">{menu.description}</div>
        </div>
      </div>
      <div className="text-right">
        <div className="text-sm">
          <FormattedAmount
            value={calculatePricePerBox(menu.amountPerBox, menu.price)}
          />
          / Box
        </div>
        <div className="text-sm text-gray">{menu.boxNum} boxes</div>
      </div>
    </div>
  );
}

export function OrderSummaryPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const order = useLoaderData() as Order;
  const [code, setCode] = useState("");

  // const menus = flatten(
  //   order.dogs.map((dog) => {
  //     return dog.menus;
  //   })
  // );

  return (
    <Page
      title="start.checkout.summary.title"
      subtitle="start.checkout.summary.subtitle"
    >
      <div className="grid md:grid-cols-5">
        <div className="md:col-start-2 md:col-span-3">
          {/* <div className="grid grid-cols-2 gap-x-4 sm:gap-x-8 mt-8">
            <div
              className="text-blue1 py-5 text-center shadow-thick rounded-t-xl bg-white font-normal"
              style={{ clipPath: "inset(-30px -30px 0px -30px)" }}
            >
              {t("start.checkout.summary.trial-box.title")}
            </div>
            <div className="py-5 text-center text-gray bg-lightGray rounded-t-xl">
              {t("start.checkout.summary.after-the-trial.title")}
            </div>
          </div> */}
          <div className="shadow-thick px-10 py-6 bg-white z-50">
            <div className="font-light text-left">
              {t("start.checkout.summary.trial-box.description", {
                deliveryFrequency: order.frequency / 7,
                costPerDay: round(order.total / order.frequency),
              })}
            </div>
            <div className="my-4 flex items-center gap-x-2">
              <img src={imgCheckMark} alt="weeks of food" className="w-4 h-4" />
              {t("start.checkout.summary.trial-box.week-of-food", {
                deliveryFrequency: order.frequency / 7,
              })}
            </div>
            <div className="flex flex-col gap-y-2">
              {order.dogs.map((dog, index) => (
                <div key={index}>
                  <div className="text-left">{dog.name}</div>
                  {dog.menus.map((menu, index) => (
                    <MenuItem key={index} menu={menu} />
                  ))}
                </div>
              ))}
            </div>

            <div className="flex flex-col gap-y-3 mt-8">
              <div className="flex justify-between">
                <div className="font-light">
                  {t("start.checkout.summary.trial-box.delivery.label")}
                </div>
                <div className="bg-opacity-10 bg-green px-2 py-1 rounded-md text-green font-light">
                  {t("start.checkout.summary.trial-box.delivery.free.label")}
                </div>
              </div>

              {/* <div className="flex justify-between">
                <div className="font-light">
                  {t("start.checkout.summary.trial-box.discount.label")}
                </div>
                <div className="text-green">-926 ฿</div>
              </div> */}

              <div className="flex justify-between mt-1">
                <div className="font-light">
                  {t("start.checkout.summary.trial-box.total.label")}
                </div>
                <div className="text-xl">
                  <FormattedAmount value={order.total} />
                </div>
              </div>
            </div>
          </div>
          <div className="text-xl font-normal mt-8">
            {t("start.checkout.payment.title")}
          </div>
          <div className="mt-8">
            <PageTitle title="start.checkout.payment.subtitle" />
          </div>
          <BankTransferPaymentView
            className="mt-4"
            getUploadUrl={async () => {
              const uploadUrl = await anonymousService.getSlipUploadUrl();
              return uploadUrl;
            }}
            onUploaded={async () => {
              const { code } = await anonymousService.informBankTransfer();
              setCode(code);
            }}
          />
        </div>
      </div>
      <BottomNavigationBar className="mt-12">
        <input
          className="form-submit back-button button"
          type="button"
          onClick={() => {
            navigate("/start/delivery");
          }}
          value={`${t("start.navbuttonbar.back")}`}
        />
        {code && (
          <input
            className="form-submit button primary-button"
            type="submit"
            onClick={() => {
              navigate({
                pathname: "/start/create-account",
                search: `?code=${code}`,
              });
            }}
            value={`${t("start.navbuttonbar.create-account")}`}
          />
        )}
      </BottomNavigationBar>
    </Page>
  );
}
