import { yupResolver } from "@hookform/resolvers/yup";
import "assets/css/calendar.css";
import dayjs from "dayjs";
import ErrorText from "features/common/components/ErrorText";
import Input from "features/common/components/Input";
import StringSelect from "features/common/components/StringSelect";
import WarningBox from "features/common/components/WarningBox";
import { ValidationState } from "features/common/models";
import postalCodes from "features/common/postalCode.json";
import {
  onInvalidScrollToElement,
  scrollToElementAndMakeItTop,
} from "features/common/utils";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import UnderlinedTextButton from "features/start/components/UnderlinedTextButton";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { find, get, isEmpty, omit } from "lodash";
import { DeliveryAddressFormValues } from "models/account";
import { useState } from "react";
import Calendar from "react-calendar";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { anonymousService } from "resources/anonymous";
import * as yup from "yup";

export function DeliveryFormPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { dogs, user, addresses, delivery } = useOnboarding();
  const { updateUserDetails, updateAddress, updateDelivery } =
    useOnboardingDispatch();

  const formSchema = yup.object().shape({
    postalCode: yup
      .string()
      .required(`${t("form.validation.required")}`)
      .length(5, `${t("form.validation.invalid-postal-code")}`),
    deliveryDate: yup.date().required(`${t("form.validation.required")}`),
    address: yup.string().required(`${t("form.validation.required")}`),
    subdistrict: yup.string().required(`${t("form.validation.required")}`),
    district: yup.string().required(`${t("form.validation.required")}`),
    province: yup.string().required(`${t("form.validation.required")}`),
    telno: yup.string().required(`${t("form.validation.required")}`),
  });

  const defaultValue = addresses.length > 0 ? addresses[0] : {};
  const defaultPostalCode = get(defaultValue, "postalCode");
  const [noPostalCodeError, setNoPostalCodeError] = useState(false);

  let defaultSubdistrictOptions: string[] = [];
  if (defaultPostalCode) {
    defaultSubdistrictOptions = [
      ...(find(postalCodes, (pc) => pc.postalCode === defaultPostalCode)
        ?.subDistricts ?? []),
    ];
  }

  const [postalCodeValidationState, setPostalCodeValidationState] =
    useState<ValidationState>(
      !isEmpty(defaultSubdistrictOptions) ? "valid" : "idle"
    );

  const [subdistrictOptions, setSubdistrictOptions] = useState<string[]>(
    defaultSubdistrictOptions
  );

  const minDate = dayjs()
    .add(parseInt(process.env.REACT_APP_ORDER_PREPARATION_PERIOD!, 10), "day")
    .toDate();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<DeliveryAddressFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      ...defaultValue,
      telno: get(user, "telno"),
      deliveryDate: delivery.deliveryDate || minDate,
    },
  });

  const updateToStore = (data: DeliveryAddressFormValues) => {
    updateUserDetails({ ...user, telno: data.telno });
    updateAddress(data);
    updateDelivery({
      ...delivery,
      deliveryDate: data.deliveryDate,
    });
  };

  const onSubmit: SubmitHandler<DeliveryAddressFormValues> = async (data) => {
    data.isActive = true;
    await anonymousService.saveAccountWithDelivery({
      user: {
        ...user,
        telno: data.telno,
      },
      addresses: [
        {
          ...omit(data, "telno", "deliveryDate"),
        },
      ],
      dogs,
      delivery: {
        ...delivery,
        deliveryDate: data.deliveryDate,
      },
    });
    await anonymousService.placeOrder();
    updateToStore(data);
    navigate("/start/checkout/summary");
  };

  return (
    <Page title="start.delivery.form.title">
      <div className="text-xl font-normal mt-2">
        {t("start.delivery.form.country.title")}
      </div>
      <form onSubmit={handleSubmit(onSubmit, onInvalidScrollToElement)}>
        <div className="mt-4 md:px-24">
          <div className="grid sm:grid-cols-4 gap-x-6 gap-y-2">
            <div className="sm:col-span-2 sm:col-start-2">
              <Input
                label="start.delivery.form.country.postalCode.label"
                placeholder="start.delivery.form.country.postalCode.label"
                {...register("postalCode")}
                errors={errors}
                onBlur={(event) => {
                  setNoPostalCodeError(false);
                  setPostalCodeValidationState("idle");
                  const value = event.target.value;
                  const postalCode = find(
                    postalCodes,
                    (postalCode) => postalCode.postalCode === value
                  );
                  if (!postalCode) {
                    setNoPostalCodeError(true);
                    setPostalCodeValidationState("invalid");
                    return;
                  }
                  setPostalCodeValidationState("valid");
                  setSubdistrictOptions(postalCode.subDistricts);
                  // FIXME: bug when change postalCode, the default value here remains the same
                  // setValue("subdistrict", "", {});
                  setValue("subdistrict", "");
                  setValue("district", postalCode.district, {
                    // shouldValidate: true,
                  });
                  setValue("province", postalCode.province, {
                    // shouldValidate: true,
                  });
                  scrollToElementAndMakeItTop("title-address");
                }}
              />
              {noPostalCodeError && (
                <ErrorText
                  error={t("form.validation.invalid-postal-code")}
                  className="mt-2"
                />
              )}
            </div>
          </div>
          {postalCodeValidationState === "invalid" && (
            <div className="mt-6 grid grid-cols-5">
              <WarningBox className="col-start-2 col-span-3">
                {t("start.delivery.form.country.condition")}
              </WarningBox>
            </div>
          )}
          {postalCodeValidationState === "valid" && (
            <div className="animate__animated animate__fadeIn animate__faster">
              <div id="title-address" className="mt-8 mb-3 text-xl font-normal">
                {t("start.delivery.form.address.title")}
              </div>
              <div className="mt-2">
                <Input
                  label="start.delivery.form.address.address.label"
                  placeholder="start.delivery.form.address.address.label"
                  {...register("address")}
                  errors={errors}
                />
              </div>
              <div className="grid sm:grid-cols-2 gap-x-6 mt-3 gap-y-2">
                <div>
                  <Controller
                    control={control}
                    name="subdistrict"
                    rules={{
                      required: `${t("form.validation.required")}`,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StringSelect
                        className="sm:col-span-1"
                        defaultValue={value}
                        onChange={onChange}
                        label={t(
                          "start.delivery.form.address.subdistrict.label"
                        )}
                        options={subdistrictOptions}
                      />
                    )}
                  />
                  <ErrorText error={get(errors, "subdistrict.message")} />
                </div>
                <Input
                  className="sm:col-span-1"
                  label="start.delivery.form.address.district.label"
                  placeholder="start.delivery.form.address.district.label"
                  {...register("district")}
                  errors={errors}
                />
              </div>
              <div className="mt-4">
                <Input
                  label="start.delivery.form.address.province.label"
                  placeholder="start.delivery.form.address.province.label"
                  {...register("province")}
                  errors={errors}
                />
              </div>
              <div className="mt-8 text-xl font-normal">
                {t("start.delivery.form.telno.title")}
              </div>
              <div className="grid sm:grid-cols-7 mt-4">
                <div className="sm:col-start-3 sm:col-span-3">
                  <Input
                    label="start.delivery.form.telno.telno.label"
                    placeholder="start.delivery.form.telno.telno.label"
                    {...register("telno")}
                    errors={errors}
                  />
                  <div className="mt-4 text-gray font-light">
                    {t("start.delivery.form.telno.telno.description")}
                  </div>
                  <UnderlinedTextButton
                    caption="start.delivery.form.telno.telno.not-home.title"
                    onClick={() => {
                      alert("test");
                    }}
                  />
                </div>
              </div>
              <div className="mt-8 text-xl font-normal">
                {t("start.delivery.form.deliveryDate.title")}
              </div>
              <div className="flex justify-center py-5">
                <Controller
                  name="deliveryDate"
                  control={control}
                  defaultValue={new Date()}
                  render={({ field: { onChange, value } }) => (
                    <Calendar
                      onChange={onChange}
                      defaultValue={value}
                      className="font-mitr"
                      minDate={minDate}
                      locale={i18n.language}
                    />
                  )}
                />
              </div>
              <ErrorText error={get(errors, "deliveryDate.message")} />
            </div>
          )}
          <BottomNavigationBar>
            <input
              className="form-submit back-button button"
              type="button"
              onClick={() => {
                updateToStore(getValues());
                navigate("/start/menu/selection");
              }}
              value={`${t("start.navbuttonbar.back")}`}
            />
            <input
              className="form-submit button primary-button"
              type="submit"
              value={`${t("start.navbuttonbar.payment")}`}
            />
          </BottomNavigationBar>
        </div>
      </form>
    </Page>
  );
}
