type BottomNavigationBarProps = {} & React.HTMLAttributes<HTMLDivElement>;

function BottomNavigationBar({
  children,
  className,
}: BottomNavigationBarProps) {
  return (
    <div
      className={`mt-3 fixed left-0 bottom-0 w-full xl:w-3/4 sm:grid sm:grid-cols-10 py-4 px-3 sm:px-0 bg-white z-10 ${className}`}
    >
      <div className="sm:col-span-8 sm:col-start-2">
        <div className="flex justify-between">{children}</div>
      </div>
    </div>
  );
}

export default BottomNavigationBar;
