import ButtonSelect from "features/common/components/ButtonSelect";
import EachDogName from "features/common/components/EachDogName";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { pick } from "lodash";
import { DogMainFoodFormValues, mainFoodOptions } from "models/mydog";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function DogMainFoodPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dogs } = useOnboarding();
  const { updateDogMainFood } = useOnboardingDispatch();

  const { handleSubmit, control, getValues } = useForm<DogMainFoodFormValues>({
    defaultValues: {
      mainFoods: dogs.map((dog) => pick(dog, ["id", "name", "mainFoodType"])),
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "mainFoods",
  });

  const updateToStore = (data: DogMainFoodFormValues) => {
    const mainFoods = data.mainFoods.map((dog) => dog.mainFoodType!);
    updateDogMainFood(mainFoods);
  };

  const onSubmit: SubmitHandler<DogMainFoodFormValues> = async (data) => {
    updateToStore(data);
    navigate("/start/my-dog/7/eating-habit");
  };

  return (
    <Page title="start.my-dog.main-food.title">
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <div key={index} className="mt-4 md:px-48 flex flex-col gap-y-6 mb-8">
            <EachDogName template="my-dog.main-food" name={field.name} />
            <Controller
              control={control}
              name={`mainFoods.${index}.mainFoodType`}
              render={({ field: { onChange, value } }) => (
                <ButtonSelect
                  options={mainFoodOptions}
                  defaultValue={field.mainFoodType}
                  className="grid grid-cols-2 gap-4"
                  onChange={onChange}
                />
              )}
            />
          </div>
        ))}
        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/my-dog/5/breed");
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default DogMainFoodPage;
