import { useTranslation } from "react-i18next";
import Page from "../components/Page";

function ReferalsPage() {
  const { t } = useTranslation();
  return (
    <Page
      title={`${t("home.referals.jumbotron.title")}`}
      subtitle="home.referals.jumbotron.subtitle"
    >
      <div className="flex justify-between">Referals</div>;
    </Page>
  );
}

export default ReferalsPage;
