import { useTranslation } from "react-i18next";

import ButtonSelect from "features/common/components/ButtonSelect";
import SpecialConditionRecommendation from "features/common/components/SpecialConditionRecommendation";
import EachDogName from "features/common/components/EachDogName";
import ErrorText from "features/common/components/ErrorText";
import SpecialConditionSelect from "features/common/components/SpecialConditionSelect";
import {
  onInvalidScrollToElement,
  scrollToElementAndMakeItTop,
} from "features/common/utils";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { get, pick } from "lodash";
import {
  DogAndSpecialCondition,
  DogSpecialConditionFormValues,
  hasSpecialConditionOptions,
} from "models/mydog";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";

function DogSpecialConditionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dogs } = useOnboarding();
  const { updateDogSpecialCondition } = useOnboardingDispatch();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    getValues,
  } = useForm<DogSpecialConditionFormValues>({
    defaultValues: {
      specialConditions: dogs.map((dog) =>
        pick(dog, ["name", "hasSpecialCondition", "specialConditionId"])
      ),
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "specialConditions",
  });
  const specialConditions = watch("specialConditions");

  const updateToStore = (data: DogSpecialConditionFormValues) => {
    const specialConditions: DogAndSpecialCondition[] =
      data.specialConditions.map((dog) => ({
        name: dog.name,
        hasSpecialCondition: dog.hasSpecialCondition,
        specialConditionId: dog.hasSpecialCondition
          ? dog.specialConditionId
          : null,
      }));
    updateDogSpecialCondition(specialConditions);
  };

  const onSubmit: SubmitHandler<DogSpecialConditionFormValues> = async (
    data
  ) => {
    updateToStore(data);
    navigate("/start/menu/user-details");
  };

  return (
    <Page title="start.my-dog.special-condition.title">
      <form onSubmit={handleSubmit(onSubmit, onInvalidScrollToElement)}>
        {fields.map((field, index) => (
          <div key={index} className="mb-8 flex flex-col gap-y-4 md:px-36">
            <EachDogName
              template="my-dog.special-condition"
              name={field.name}
            />
            <Controller
              control={control}
              name={`specialConditions.${index}.hasSpecialCondition`}
              render={({ field: { onChange, value } }) => (
                <ButtonSelect
                  options={hasSpecialConditionOptions}
                  defaultValue={value}
                  className="grid grid-cols-2 gap-4"
                  onChange={(yes) => {
                    onChange(yes);
                    if (yes) {
                      scrollToElementAndMakeItTop(
                        `special-condition-select-${index}`
                      );
                    }
                  }}
                />
              )}
            />

            {specialConditions[index].hasSpecialCondition && (
              <>
                <h4
                  id={`special-condition-select-${index}`}
                  className="mt-4 font-normal text-xl"
                >
                  {t("start.my-dog.food-restriction.special-conditions.title")}
                </h4>
                <Controller
                  control={control}
                  name={`specialConditions.${index}.specialConditionId`}
                  rules={{
                    required: `${t("form.validation.required")}`,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <SpecialConditionSelect
                      defaultValue={value}
                      onChange={onChange}
                    />
                  )}
                />
                {specialConditions[index].specialConditionId && (
                  <SpecialConditionRecommendation
                    specialConditionId={
                      specialConditions[index].specialConditionId!
                    }
                  />
                )}
                <ErrorText
                  error={get(
                    errors,
                    `specialConditions[${index}].specialConditionId.message`
                  )}
                />
              </>
            )}
          </div>
        ))}
        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/my-dog/11/food-restriction");
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default DogSpecialConditionPage;
