import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

type StringSelectProps = {
  defaultValue: string | null;
  onChange: (value: string | undefined) => void;
  options: string[];
  label: string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue" | "onChange">;

export type StringOption = {
  label: string;
  value: string;
};

function StringSelect({
  defaultValue,
  onChange,
  options,
  label,
  className,
}: StringSelectProps) {
  const { t } = useTranslation();
  const stringOptions = options.map((option): StringOption => {
    return {
      label: option,
      value: option,
    };
  });

  const defaultStringOption = stringOptions.find(
    (stringOption) => stringOption.value === defaultValue
  );

  return (
    <>
      <div className={`flex flex-col ${className}`}>
        {label && (
          <label htmlFor={label} className="text-gray font-light text-left">
            {t(label)}
          </label>
        )}
        <Select
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              height: "48px",
              borderRadius: "8px",
              border: "1px solid #A0B3C1",
              textAlign: "left",
              paddingLeft: "12px",
              marginTop: "4px",
            }),
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999, // Specify the desired z-index value
              fontFamily: "Mitr, sans-serif",
              fontWeight: 300,
            }),
          }}
          menuPortalTarget={document.body}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#075DEB",
              primary25: "#F5F7F9",
            },
          })}
          defaultValue={defaultStringOption}
          getOptionLabel={(optStr: StringOption) => optStr.label}
          getOptionValue={(optStr: StringOption) => optStr.value}
          isClearable={false}
          options={stringOptions}
          onChange={(value) => {
            onChange(value!.value);
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
          placeholder={label}
        />
      </div>
    </>
  );
}

export default StringSelect;
