import React, { CSSProperties, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

export type ImageSelectOption<Type> = {
  label: string;
  icon: ReactNode;
  value: Type;
} & React.HTMLAttributes<HTMLDivElement>;

export type ImageSelectOptionProps = {
  name: string;
  icon: ReactNode;
  selected: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

function ImageSelectItem({ name, icon, selected }: ImageSelectOptionProps) {
  let itemClass = selected ? "fill-white" : "fill-gray bg-lightGray";
  let itemStyle: CSSProperties = selected
    ? {
        background: "linear-gradient(150.7deg, #FFB65F 15.71%, #E08C15 67.3%)",
        boxShadow: "box-shadow: 0px 10px 20px rgba(58, 92, 137, 0.08)",
      }
    : {};
  return (
    <div>
      <div
        style={itemStyle}
        className={`rounded-2xl h-[210px] flex justify-center items-center cursor-pointer ${itemClass}`}
      >
        {icon}
      </div>
      <div className="mt-2 text-gray">{name}</div>
    </div>
  );
}

export type ImageSelectFormValues = {
  name: string;
};

type ImageSelectProps<ValueType> = {
  defaultValue: ValueType | null;
  options: ImageSelectOption<ValueType>[];
  onChange: (value: ValueType) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue" | "onChange">;

function ImageSelect<Type>({
  options,
  defaultValue,
  onChange,
  className,
}: ImageSelectProps<Type>) {
  const { t } = useTranslation();
  const initialState = defaultValue ? defaultValue : null;
  const [selectedId, setSelectedId] = useState(initialState);

  return (
    <div className={className}>
      {options.map((option, index) => (
        <div
          key={index}
          onClick={() => {
            setSelectedId(option.value!);
            onChange(option.value);
          }}
        >
          <ImageSelectItem
            key={option.id}
            name={t(option.label)}
            icon={option.icon}
            selected={selectedId === option.value}
          />
        </div>
      ))}
    </div>
  );
}

export default ImageSelect;
