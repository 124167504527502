import Modal from "features/common/modals/Modal";
import { Account, Address, DeliveryAddressFormValues } from "models/account";
import { Controller, SubmitHandler, get, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { accountService } from "resources/account";
import AddressInfo from "../components/AddressInfo";
import Input from "../components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import {
  onInvalidScrollToElement,
  scrollToElementAndMakeItTop,
} from "../utils";
import { find, update } from "lodash";
import ErrorText from "../components/ErrorText";
import WarningBox from "../components/WarningBox";
import postalCodes from "features/common/postalCode.json";
import StringSelect from "../components/StringSelect";
import { ValidationState } from "../models";
import { orderService } from "resources/order";

type DeliveryAddressFormProps = {
  defaultValue: Address | undefined;
  onFinish: (address: Address) => void;
  onCancel: () => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue">;

export function DeliveryAddressForm({
  defaultValue,
  onFinish,
  onCancel,
}: DeliveryAddressFormProps) {
  const { t } = useTranslation();
  const [noPostalCodeError, setNoPostalCodeError] = useState(false);

  // const defaultValue = addresses.length > 0 ? addresses[0] : {};

  const formSchema = yup.object().shape({
    postalCode: yup
      .string()
      .required(`${t("form.validation.required")}`)
      .length(5, `${t("form.validation.invalid-postal-code")}`),
    address: yup.string().required(`${t("form.validation.required")}`),
    subdistrict: yup.string().required(`${t("form.validation.required")}`),
    district: yup.string().required(`${t("form.validation.required")}`),
    province: yup.string().required(`${t("form.validation.required")}`),
  });

  const defaultPostalCode = get(defaultValue, "postalCode");

  let defaultSubdistrictOptions: string[] = [];
  if (defaultPostalCode) {
    defaultSubdistrictOptions = [
      ...(find(postalCodes, (pc) => pc.postalCode === defaultPostalCode)
        ?.subDistricts ?? []),
    ];
  }
  const [subdistrictOptions, setSubdistrictOptions] = useState<string[]>(
    defaultSubdistrictOptions
  );

  const [postalCodeValidationState, setPostalCodeValidationState] =
    useState<ValidationState>(defaultValue ? "valid" : "idle");

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<DeliveryAddressFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      ...defaultValue,
    },
  });

  const onSubmit: SubmitHandler<DeliveryAddressFormValues> = async (data) => {
    onFinish(data);
  };

  const postalCode = watch("postalCode");

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onInvalidScrollToElement)}
      className="animate__animated animate__fadeIn animate__faster"
    >
      <div className="mt-4 sm:px-24">
        <div className="grid sm:grid-cols-4 gap-x-6 gap-y-2">
          <div className="sm:col-span-2 sm:col-start-2">
            <Input
              label="start.delivery.form.country.postalCode.label"
              placeholder="start.delivery.form.country.postalCode.label"
              {...register("postalCode")}
              errors={errors}
              onBlur={(event) => {
                setNoPostalCodeError(false);
                setPostalCodeValidationState("idle");
                const value = event.target.value;
                const postalCode = find(
                  postalCodes,
                  (postalCode) => postalCode.postalCode === value
                );
                if (!postalCode) {
                  setNoPostalCodeError(true);
                  setPostalCodeValidationState("invalid");
                  return;
                }
                setPostalCodeValidationState("valid");
                setSubdistrictOptions(postalCode.subDistricts);
                // FIXME: bug when change postalCode, the default value here remains the same
                // setValue("subdistrict", "", {});
                setValue("subdistrict", "");
                setValue("district", postalCode.district, {
                  // shouldValidate: true,
                });
                setValue("province", postalCode.province, {
                  // shouldValidate: true,
                });
                scrollToElementAndMakeItTop("title-address");
              }}
            />
            {noPostalCodeError && (
              <ErrorText
                error={t("form.validation.invalid-postal-code")}
                className="mt-2"
              />
            )}
          </div>
        </div>
        {postalCodeValidationState === "invalid" && (
          <div className="mt-6 grid grid-cols-5">
            <WarningBox className="col-start-2 col-span-3">
              {t("start.delivery.form.country.condition")}
            </WarningBox>
          </div>
        )}
        {postalCodeValidationState === "valid" && (
          <div className="animate__animated animate__fadeIn animate__faster">
            <div className="mt-2">
              <Input
                label="start.delivery.form.address.address.label"
                placeholder="start.delivery.form.address.address.label"
                {...register("address")}
                errors={errors}
              />
            </div>
            <div className="grid sm:grid-cols-2 gap-x-6 mt-3 gap-y-2">
              <Controller
                key={postalCode}
                control={control}
                name={"subdistrict"}
                rules={{
                  required: `${t("form.validation.required")}`,
                }}
                render={({ field: { onChange, value } }) => (
                  <StringSelect
                    className="sm:col-span-1"
                    defaultValue={value}
                    onChange={onChange}
                    label={t("start.delivery.form.address.subdistrict.label")}
                    options={subdistrictOptions}
                  />
                )}
              />
              <ErrorText error={get(errors, "subdistrict.message")} />
              <Input
                className="sm:col-span-1"
                label="start.delivery.form.address.district.label"
                placeholder="start.delivery.form.address.district.label"
                {...register("district")}
                errors={errors}
              />
            </div>
            <div className="mt-4">
              <Input
                label="start.delivery.form.address.province.label"
                placeholder="start.delivery.form.address.province.label"
                {...register("province")}
                errors={errors}
              />
            </div>
          </div>
        )}
        <div className="flex justify-between mt-4 border-t border-t-lightGray pt-4">
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              onCancel();
            }}
            value={`${t("dialog.button.cancel")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("dialog.button.confirm")}`}
          />
        </div>
      </div>
    </form>
  );
}

type DeliveryAddressModalProps = {
  account: Account;
  orderId: string;
  defaultValue: Date;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (addresses: Address[]) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue">;

function DeliveryAddressModal({
  defaultValue,
  orderId,
  account,
  isOpen,
  onClose,
  onConfirm,
}: DeliveryAddressModalProps) {
  const { t } = useTranslation();

  const [addresses, setAddresses] = useState<Address[]>(account.addresses);
  const [editingAddress, setEditingAddress] = useState<Address | undefined>();
  const [editingIndex, setEditingIndex] = useState<number | undefined>();
  const [showForm, setShowForm] = useState(false);

  const onSubmit = async () => {
    await accountService.updateAddresses(addresses);
    await orderService.changeDeliveryAddress(
      orderId,
      addresses.find((addr) => addr.isActive)!
    );
    onConfirm(addresses);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} className="rounded-2xl modal-lg">
      <div className="flex flex-col h-full max-h-full md:max-h-[95vh]">
        <div className="p-7 flex-shrink flex-grow overflow-y-auto">
          <h3 className="text-2xl font-normal">
            {t("component.modal.delivery-address.title")}
          </h3>
          {!showForm && (
            <div className="flex flex-col gap-y-2 mt-3 animate__animated animate__fadeIn animate__faster">
              {addresses.map((address, idx) => (
                <div key={idx} className="flex justify-between">
                  <label className="flex gap-x-3 items-start">
                    <input
                      type="radio"
                      id={`addr`}
                      name="address"
                      checked={address.isActive}
                      onChange={() => {
                        let newAddresses = addresses.map((addr) => ({
                          ...addr,
                          isActive: false,
                        }));
                        newAddresses = update(
                          newAddresses,
                          `[${idx}].isActive`,
                          () => true
                        );
                        setAddresses(newAddresses);
                      }}
                    />
                    <span>
                      <AddressInfo address={address} />
                    </span>
                  </label>
                  <div
                    className="cursor-pointer font-normal text-blue1"
                    onClick={() => {
                      setEditingIndex(idx);
                      setEditingAddress(address);
                      setShowForm(true);
                    }}
                  >
                    {t("button.update")}
                  </div>
                </div>
              ))}
            </div>
          )}
          {showForm && (
            <DeliveryAddressForm
              onFinish={(address) => {
                setShowForm(false);
                setEditingAddress(undefined);
                if (editingIndex !== undefined) {
                  setAddresses(
                    addresses.map((addr, idx) =>
                      idx === editingIndex ? address : addr
                    )
                  );
                } else {
                  setAddresses([...addresses, address]);
                }
              }}
              onCancel={() => {
                setShowForm(false);
              }}
              defaultValue={editingAddress}
            />
          )}
          {!showForm && (
            <button
              className="form-submit button primary-button rounded-lg shadow-none mt-6"
              type="submit"
              value={`${t("dialog.button.confirm")}`}
              onClick={() => {
                setEditingIndex(undefined);
                setEditingAddress(undefined);
                setShowForm(true);
              }}
            >
              {t("button.addNew")}
            </button>
          )}
        </div>
        <div className="bg-lightGray grid grid-cols-2 gap-x-4 py-5 px-7 rounded-b-2xl">
          <input
            className="form-submit back-button button rounded-lg shadow-none border border-gray"
            type="button"
            onClick={onClose}
            value={`${t("dialog.button.cancel")}`}
          />
          <input
            className="form-submit button primary-button rounded-lg shadow-none"
            type="button"
            onClick={onSubmit}
            value={`${t("dialog.button.confirm")}`}
          />
        </div>
      </div>
    </Modal>
  );
}

export default DeliveryAddressModal;
