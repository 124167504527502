import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// export type ButtonSelectOption<T extends OptionValue> = {
export type ButtonSelectOption<Type> = {
  label: string;
  value: Type;
};

export type ButtonSelectItemProps = {
  // id: string;
  name: string;
  selected: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

function ButtonSelectItem({ name, selected }: ButtonSelectItemProps) {
  let itemClass = selected
    ? "bg-blue1 shadow-lg text-white"
    : "bg-white border border-gray";
  return (
    <div>
      <div
        className={`h-16 rounded-lg flex justify-center items-center cursor-pointer ${itemClass}`}
      >
        {name}
      </div>
    </div>
  );
}

type ButtonSelectProps<ValueType> = {
  defaultValue: ValueType;
  options: ButtonSelectOption<ValueType>[];
  onChange: (value: ValueType) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue" | "onChange">;

function ButtonSelect<Type>({
  defaultValue,
  options,
  onChange,
  className,
}: ButtonSelectProps<Type>) {
  const initialState = defaultValue ? defaultValue : options[0].value;
  const [selectedId, setSelectedId] = useState(initialState);
  const { t } = useTranslation();

  return (
    <div className={`${className}`}>
      {options.map((option, idx) => (
        <div
          key={idx}
          onClick={() => {
            setSelectedId(option.value);
            onChange(option.value);
          }}
        >
          <ButtonSelectItem
            name={t(option.label)}
            selected={selectedId === option.value}
          />
        </div>
      ))}
    </div>
  );
}

export default ButtonSelect;
