import { yupResolver } from "@hookform/resolvers/yup";
import APIErrorBox from "features/common/components/APIErrorBox";
import Input from "features/common/components/Input";
import Modal from "features/common/modals/Modal";
import {
  IconDogtorBag,
  IconEnvelope,
  IconMan,
  IconMobilePhone,
  IconPadlock,
} from "features/common/icon";
import { Account, User } from "models/account";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoaderFunction, useLoaderData } from "react-router-dom";
import { accountService } from "resources/account";
import { ApiError } from "resources/http-client";
import * as yup from "yup";
import EditButton from "../components/EditButton";
import Page from "../components/Page";

export const loader: LoaderFunction = async () => {
  const me = await accountService.getMe();
  return { me };
};

export type EditAccountFormValues = {
  vetName: string;
} & User;

type EditAccountModalProps = {
  isOpen: boolean;
  user: User;
  onClose: () => void;
  onConfirm: (user: User) => void;
} & React.HTMLAttributes<HTMLDivElement>;

function EditAccountModal({
  isOpen,
  user,
  onClose,
  onConfirm,
}: EditAccountModalProps) {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<ApiError | undefined>();

  const formSchema = yup.object().shape({
    firstName: yup.string().required(`${t("form.validation.required")}`),
    lastName: yup.string().required(`${t("form.validation.required")}`),
    email: yup
      .string()
      .required(`${t("form.validation.required")}`)
      .email(`${t("form.validation.invalid-email")}`),
    telno: yup.string().required(`${t("form.validation.required")}`),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EditAccountFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      ...user,
      vetName: "Vet Name",
    },
  });

  const onSubmit: SubmitHandler<EditAccountFormValues> = async (data) => {
    setApiError(undefined);
    try {
      await accountService.updateUser(data);
    } catch (err) {
      setApiError(err as ApiError);
    }

    await accountService.updateUser(data);
    onConfirm(data);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} className="rounded-2xl modal-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-7">
          <div className="bg-blue1 bg-opacity-10 rounded-full w-[60px] h-[60px] flex justify-center items-center">
            <IconMan className="fill-blue1" />
          </div>

          <h3 className="text-2xl mt-8 font-normal">
            {t("home.mydata.modal.account.title")}
          </h3>
          {/* <div className="mt-2">
            {t("home.mydata.modal.account.title.label")}
          </div>
          <div className="flex gap-x-4 mt-2">
            <label>
              <input
                type="radio"
                value="mr"
                {...register("title", { required: true })}
                className="mr-2 w-6 h-6"
              />
              {t("home.mydata.modal.account.title.mr.label")}
            </label>
            <label>
              <input
                type="radio"
                value="mrs"
                {...register("title", { required: true })}
                className="mr-2 w-6 h-6"
              />
              {t("home.mydata.modal.account.title.mrs.label")}
            </label>
          </div> */}
          <div className="grid sm:grid-cols-2 gap-x-6 mt-4">
            <Input
              className="sm:col-span-1"
              label="home.mydata.modal.account.first-name.label"
              placeholder="home.mydata.modal.account.first-name.label"
              {...register("firstName", {
                required: `${t("form.validation.required")}`,
              })}
              errors={errors}
            />
            <Input
              className="sm:col-span-1"
              label="home.mydata.modal.account.last-name.label"
              placeholder="home.mydata.modal.account.last-name.label"
              {...register("lastName", {
                required: `${t("form.validation.required")}`,
              })}
              errors={errors}
            />
          </div>
          <div className="mt-4">
            <Input
              label="home.mydata.modal.account.email.label"
              placeholder="home.mydata.modal.account.email.label"
              {...register("email", {
                required: `${t("form.validation.required")}`,
              })}
              errors={errors}
            />
            <APIErrorBox err={apiError} />
          </div>
          <div className="mt-4">
            <Input
              label="home.mydata.modal.account.telno.label"
              placeholder="home.mydata.modal.account.telno.label"
              {...register("telno", {
                required: `${t("form.validation.required")}`,
              })}
              errors={errors}
            />
          </div>
          {/* <div className="mt-4">
            <Input
              label="home.mydata.modal.account.vet-name.label"
              placeholder="home.mydata.modal.account.vet-name.label"
              {...register("telno", {
                required: `${t("form.validation.required")}`,
              })}
              errors={errors}
            />
          </div> */}
        </div>
        <div className="bg-lightGray grid grid-cols-2 gap-x-4 py-5 px-7 rounded-b-2xl">
          <input
            className="form-submit back-button button rounded-lg shadow-none border border-gray"
            type="button"
            onClick={onClose}
            value={`${t("dialog.button.cancel")}`}
          />
          <input
            className="form-submit button primary-button rounded-lg shadow-none"
            type="submit"
            value={`${t("dialog.button.confirm")}`}
          />
        </div>
      </form>
    </Modal>
  );
}

export type EditPasswordFormValues = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

type EditPasswordModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

function EditPasswordModal({
  isOpen,
  onClose,
  onConfirm,
}: EditPasswordModalProps) {
  const { t } = useTranslation();

  const formSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .required(`${t("form.validation.required")}`)
      .min(8, `${t("form.validation.tooshort")}`),
    newPassword: yup
      .string()
      .required(`${t("form.validation.required")}`)
      .min(8, `${t("form.validation.tooshort")}`),
    confirmNewPassword: yup
      .string()
      .required(`${t("form.validation.required")}`)
      .oneOf(
        [yup.ref("newPassword")],
        `${t("form.validation.password-not-match")}`
      ),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EditPasswordFormValues>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<EditPasswordFormValues> = async (data) => {
    await accountService.updatePassword(data);
    onConfirm();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} className="rounded-2xl modal-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-7">
          <div className="bg-blue1 bg-opacity-10 rounded-full w-[60px] h-[60px] flex justify-center items-center">
            <IconMan className="fill-blue1" />
          </div>

          <h3 className="text-2xl mt-8 font-normal pb-10">
            {t("home.mydata.modal.password.title")}
          </h3>
          <div className="flex flex-col gap-y-3">
            <div className="grid sm:grid-cols-5 gap-2 sm:gap-6">
              <Input
                className="sm:col-span-3 sm:col-start-2"
                type="password"
                label="home.mydata.modal.password.old-password.label"
                placeholder="home.mydata.modal.password.old-password.label"
                {...register("oldPassword")}
                errors={errors}
              />
            </div>
            <div className="grid sm:grid-cols-5 gap-2 sm:gap-6">
              <Input
                className="sm:col-span-3 sm:col-start-2"
                type="password"
                label="home.mydata.modal.password.new-password.label"
                placeholder="home.mydata.modal.password.new-password.label"
                {...register("newPassword")}
                errors={errors}
              />
            </div>
            <div className="grid sm:grid-cols-5 gap-2 sm:gap-6">
              <Input
                className="sm:col-span-3 sm:col-start-2"
                type="password"
                label="home.mydata.modal.password.new-confirm-password.label"
                placeholder="home.mydata.modal.password.new-confirm-password.label"
                {...register("confirmNewPassword")}
                errors={errors}
              />
            </div>
          </div>
        </div>
        <div className="bg-lightGray grid grid-cols-2 gap-x-4 py-5 px-7 rounded-b-2xl">
          <input
            className="form-submit back-button button rounded-lg shadow-none border border-gray"
            type="button"
            onClick={onClose}
            value={`${t("dialog.button.cancel")}`}
          />
          <input
            className="form-submit button primary-button rounded-lg shadow-none"
            type="submit"
            value={`${t("dialog.button.confirm")}`}
          />
        </div>
      </form>
    </Modal>
  );
}

type NotificationCheckboxProps = {
  name: string;
  checked: boolean;
  onCheck: (enabled: boolean) => void;
} & React.HTMLAttributes<HTMLDivElement>;
function NotificationCheckbox({
  name,
  checked,
  onCheck,
}: NotificationCheckboxProps) {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(checked);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    onCheck(e.target.checked);
  };

  return (
    <label className="cursor-pointer">
      <input
        type="checkbox"
        className="w-4 h-4"
        checked={isChecked}
        onChange={onChange}
      />
      <span className="ml-4 font-light">{t(name)}</span>
    </label>
  );
}

type AccountPageProps = {
  defaultUser: User;
} & React.HTMLAttributes<HTMLDivElement>;
function AccountPane({ defaultUser }: AccountPageProps) {
  const { t } = useTranslation();
  const [showEditAccountModal, setShowEditAccountModal] = useState(false);
  const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);
  const [user, setUser] = useState(defaultUser);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="text-2xl font-normal">
          {t("home.mydata.account.title")}
        </div>
        <EditButton
          name="home.mydata.account.edit.button"
          onClick={() => {
            setShowEditAccountModal(true);
          }}
        />
      </div>
      <div className="bg-white shadow-thick rounded-xl p-5 mt-4">
        <div className="flex flex-col gap-y-4">
          <div className="flex gap-x-4 items-center text-xl font-normal">
            <IconMan className="fill-black" />
            {user.firstName} {user.lastName}
          </div>
          <div className="flex gap-x-4 items-center">
            <IconEnvelope className="fill-black" />
            {user.email}
          </div>
          <div className="flex gap-x-4 items-center">
            <IconMobilePhone className="fill-black" />
            {user.telno}
          </div>
          <div className="flex gap-x-4 items-center">
            <IconDogtorBag className="fill-black" />
            Dr. Kero
          </div>
          <div className="flex gap-x-4 items-center">
            <IconPadlock className="fill-black" />
            <div
              className="underline cursor-pointer"
              onClick={() => {
                setShowEditPasswordModal(true);
              }}
            >
              ({t("home.mydata.account.update-password.button")})
            </div>
          </div>
        </div>

        <hr className="my-5 border border-lightGray" />
        <div className="font-normal text-xl text-left">
          {t("home.mydata.notification.title")}
        </div>
        <div className="flex flex-col gap-y-2 items-start mt-3 text-left">
          <NotificationCheckbox
            name="home.mydata.notification.order"
            checked={true}
            onCheck={() => {}}
          />
          <NotificationCheckbox
            name="home.mydata.notification.information"
            checked={true}
            onCheck={() => {}}
          />
          <NotificationCheckbox
            name="home.mydata.notification.deals"
            checked={true}
            onCheck={() => {}}
          />
          <NotificationCheckbox
            name="home.mydata.notification.newsletter"
            checked={true}
            onCheck={() => {}}
          />
        </div>
      </div>
      <EditAccountModal
        user={user}
        isOpen={showEditAccountModal}
        onClose={() => {
          setShowEditAccountModal(false);
        }}
        onConfirm={(user) => {
          setUser(user);
          setShowEditAccountModal(false);
        }}
      />
      <EditPasswordModal
        isOpen={showEditPasswordModal}
        onClose={() => {
          setShowEditPasswordModal(false);
        }}
        onConfirm={() => {
          setUser(user);
          setShowEditPasswordModal(false);
        }}
      />
    </div>
  );
}

function MyDataPage() {
  const { me } = useLoaderData() as {
    me: Account;
  };

  return (
    <Page
      title="home.mydata.jumbotron.title"
      subtitle="home.mydata.jumbotron.subtitle"
    >
      <div className="grid sm:grid-cols-2 gap-8">
        <AccountPane defaultUser={me.user} />
      </div>
    </Page>
  );
}

export default MyDataPage;
