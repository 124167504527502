import App from "App";
import { Navigate, RouteObject } from "react-router-dom";
import DogNamePage from "features/start/pages/my-dog/DogNamePage";
import StartMainPage from "features/start/pages/StartMainPage";
import ErrorPage from "./ErrorPage";
import DogSexPage from "features/start/pages/my-dog/DogSexPage";
import DogBodyTypePage from "features/start/pages/my-dog/DogBodyTypePage";
import DogWeightPage from "features/start/pages/my-dog/DogWeightPage";
import DogSpecialConditionPage from "features/start/pages/my-dog/DogSpecialConditionPage";
import DogNeuteredPage from "features/start/pages/my-dog/DogNeuteredPage";
import DogAgePage from "features/start/pages/my-dog/DogAgePage";
import DogBreedPage from "features/start/pages/my-dog/DogBreedPage";
import DogMainFoodPage from "features/start/pages/my-dog/DogMainFoodPage";
import DogEatingHabitPage from "features/start/pages/my-dog/DogEatingHabitPage";
import DogActivityHabitTypePage from "features/start/pages/my-dog/DogActivityHabitTypePage";
import DogFoodRestrictionPage from "features/start/pages/my-dog/DogFoodRestrictionPage";
import UserDetailsPage from "features/start/pages/menu/UserDetailsPage";
import MenuGenerationPage from "features/start/pages/menu/MenuGenerationPage";
import MenuRecommendedDailyPortionPage, {
  loader as menuRecommendedDailyPortionPageLoader,
} from "features/start/pages/menu/MenuRecommendedDailyPortionPage";
import DeliveryFrequencyPage from "features/start/pages/menu/DeliveryFrequencyPage";
import {
  MenuSelectionPage,
  loader as menuRecipePageLoader,
} from "features/start/pages/menu/MenuSelectionPage";
import { DeliveryFormPage } from "features/start/pages/delivery/DeliveryFormPage";
import {
  OrderSummaryPage,
  loader as orderSummaryLoader,
} from "features/start/pages/checkout/OrderSummaryPage";
import HomePage from "features/home/pages/HomePage";
import {
  DashboardPage,
  loader as homeDashboardLoader,
} from "features/home/pages/DashboardPage";
import DeliveriesPage, {
  loader as deliveriesPageLoader,
} from "features/home/pages/DeliveriesPage";
import MySubscriptionPage, {
  loader as mySubscriptionPageLoader,
} from "features/home/pages/MySubscriptionPage";
import ReferalsPage from "features/home/pages/ReferalsPage";
import HistoryPage, {
  loader as historyPageLoader,
} from "features/home/pages/HistoryPage";
import MyDataPage, {
  loader as myDataPageLoader,
} from "features/home/pages/MyDataPage";
import LoginPage from "features/authentication/pages/LoginPage";
import CreateAccountPage, {
  loader as createAccountPageLoader,
} from "features/start/pages/checkout/CreateAccountPage";
import SplashPage from "features/SplashPage";
import DeliveryDetailPage, {
  loader as deliveryDetailPageLoader,
} from "features/home/pages/DeliveryDetailPage";
import OrderManagementPage, {
  loader as orderManagementPageLoader,
} from "features/admin/pages/OrderManagementPage";

const router: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/main" replace />,
      },
      {
        path: "/main",
        element: <SplashPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/start",
        element: <StartMainPage />,
        children: [
          {
            path: "/start/my-dog/1/name",
            element: <DogNamePage />,
          },
          {
            path: "/start/my-dog/2/sex",
            element: <DogSexPage />,
          },
          {
            path: "/start/my-dog/3/neutered",
            element: <DogNeuteredPage />,
          },
          {
            path: "/start/my-dog/4/age",
            element: <DogAgePage />,
          },
          {
            path: "/start/my-dog/5/breed",
            element: <DogBreedPage />,
          },
          {
            path: "/start/my-dog/6/main-food",
            element: <DogMainFoodPage />,
          },
          {
            path: "/start/my-dog/7/eating-habit",
            element: <DogEatingHabitPage />,
          },
          {
            path: "/start/my-dog/8/weight",
            element: <DogWeightPage />,
          },
          {
            path: "/start/my-dog/9/body-type",
            element: <DogBodyTypePage />,
          },
          {
            path: "/start/my-dog/10/activity-habit",
            element: <DogActivityHabitTypePage />,
          },
          {
            path: "/start/my-dog/11/food-restriction",
            element: <DogFoodRestrictionPage />,
          },
          {
            path: "/start/my-dog/12/special-condition",
            element: <DogSpecialConditionPage />,
          },
          {
            path: "/start/menu/user-details",
            element: <UserDetailsPage />,
          },
          {
            path: "/start/menu/generation",
            element: <MenuGenerationPage />,
          },
          {
            path: "/start/menu/recommended-daily-portion",
            element: <MenuRecommendedDailyPortionPage />,
            loader: menuRecommendedDailyPortionPageLoader,
          },
          {
            path: "/start/menu/delivery-frequency",
            element: <DeliveryFrequencyPage />,
          },
          {
            path: "/start/menu/selection",
            element: <MenuSelectionPage />,
            loader: menuRecipePageLoader,
          },
          {
            path: "/start/delivery",
            element: <DeliveryFormPage />,
          },
          {
            path: "/start/checkout/summary",
            element: <OrderSummaryPage />,
            loader: orderSummaryLoader,
          },
          {
            path: "/start/create-account",
            element: <CreateAccountPage />,
            loader: createAccountPageLoader,
          },
        ],
      },
      {
        path: "/home",
        element: <HomePage />,
        children: [
          {
            path: "/home/dashboard",
            element: <DashboardPage />,
            loader: homeDashboardLoader,
          },
          {
            path: "/home/deliveries",
            element: <DeliveriesPage />,
            loader: deliveriesPageLoader,
          },
          {
            path: "/home/deliveries/:id",
            element: <DeliveryDetailPage />,
            loader: deliveryDetailPageLoader,
          },
          {
            path: "/home/subscriptions",
            element: <MySubscriptionPage />,
            loader: mySubscriptionPageLoader,
          },
          {
            path: "/home/referals",
            element: <ReferalsPage />,
          },
          {
            path: "/home/history",
            element: <HistoryPage />,
            loader: historyPageLoader,
          },
          {
            path: "/home/my-data",
            element: <MyDataPage />,
            loader: myDataPageLoader,
          },
        ],
      },
      {
        path: "/admin",
        element: <OrderManagementPage />,
        loader: orderManagementPageLoader,
      },
    ],
  },
];

export default router;
