import imgLogoMobile from "assets/images/logo-mobile.png";
import imgLogo from "assets/images/logo.png";
import { ReactComponent as IconCheckout } from "assets/images/start/checkout.svg";
import { ReactComponent as IconDelivery } from "assets/images/start/delivery.svg";
import imgJumbotron from "assets/images/start/jumbotron.png";
import { ReactComponent as IconMenu } from "assets/images/start/menu.svg";
import { ReactComponent as IconMyDog } from "assets/images/start/my-dog.svg";
import LanguageSwitcher from "features/common/components/LanguageSwitcher";
import { CSSProperties, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { OnboardingProvider } from "../state/context";
import { getOnboarding } from "features/common/storage";

function Header() {
  return (
    <>
      <header
        className="h-[184px] justify-center hidden sm:flex"
        style={{
          background:
            "linear-gradient(180deg, #8DC4FA -53.53%, rgba(166, 205, 250, 0.4) 100%)",
        }}
      >
        <img
          src={imgJumbotron}
          alt="jumbotron"
          className="w-[344px] h-[200px]"
        />
      </header>
      {/* mobile header */}
      <header className="flex justify-between sm:hidden bg-blue1 h-[70px] items-center px-3">
        <img src={imgLogoMobile} alt="logo" className="w-[146px] h-[44px]" />
        <div>
          <LanguageSwitcher paneClass="top-8 right-0" />
        </div>
      </header>
    </>
  );
}

function RightPane() {
  const { t } = useTranslation();

  return (
    <div
      className="flex justify-center min-h-screen items-center bg-cover bg-center"
      style={{
        backgroundImage: "url(/images/start/dog.png)",
      }}
    >
      {/* FIXME: text in right pane is too high */}
      <div className="flex flex-col justify-between h-screen pt-20">
        <div className="grid grid-cols-5">
          <div className="col-start-2 col-span-3 flex justify-center">
            <img src={imgLogo} alt="logo" className="w-36 h-44" />
          </div>
          <LanguageSwitcher paneClass="top-8 right-10" />
        </div>
        <div className="mb-10">
          <h3 className="text-white text-4xl">{t("start.right-pane.title")}</h3>
          <div className="text-white mt-3">
            {t("start.right-pane.subtitle")}
          </div>
        </div>
      </div>
    </div>
  );
}

type StepId = "mydog" | "menu" | "delivery" | "checkout";
type StepState = "done" | "inprogress" | "empty";

type StepProps = {
  id: StepId;
  name: string;
  caption: string;
  icon?: ReactNode;
  state?: StepState;
  startPath: string;
};

function Step({
  id,
  name,
  caption,
  icon,
  state = "empty",
  startPath,
}: StepProps) {
  const navigate = useNavigate();
  let iconClass = "";
  let titleClass = "";
  let captionClass = "";
  let iconStyle: CSSProperties = {};
  if (state === "inprogress") {
    iconClass += "fill-white";
    iconStyle = {
      background: "linear-gradient(180deg, #07CFEB -63.26%, #075DEB 100%)",
      boxShadow: "0px 10px 20px rgba(7, 93, 235, 0.3)",
    };
    titleClass = "text-blue1";
    captionClass = "text-cream";
  } else if (state === "done") {
    iconClass += "p-3 fill-white bg-darkBlue";
    captionClass = "text-darkBlue";
  } else {
    titleClass = "text-gray";
    iconClass += "bg-lightGray fill-blue1 text-gray";
    captionClass = "text-gray";
  }
  return (
    <div
      className="flex flex-col justify-center items-center"
      onClick={() => {
        if (state === "done" || state === "inprogress") {
          navigate(`/start/${startPath}`);
        }
      }}
    >
      <div className="flex flex-row gap-2 items-center text-sm md:text-base 2xl:text-xl 2xl:gap-5">
        <div
          className={`p-5 flex justify-center items-center rounded-full cursor-pointer ${iconClass}`}
          style={iconStyle}
        >
          {icon}
        </div>
        <div
          className={`items-start text-left ${
            state !== "inprogress" && "hidden sm:flex sm:flex-col"
          }`}
        >
          <div className={captionClass}>{caption}</div>
          <div className={titleClass}>{name}</div>
        </div>
      </div>
    </div>
  );
}

function Steps() {
  const location = useLocation();
  const routeMatch = (path: string) => {
    return location.pathname.includes(path);
  };
  let myDogState: StepState = "empty";
  let menuState: StepState = "empty";
  let deliveryState: StepState = "empty";
  let checkoutState: StepState = "empty";
  let caption = "Step 12/12";
  if (routeMatch("/my-dog")) {
    myDogState = "inprogress";
    menuState = "empty";
    deliveryState = "empty";
    checkoutState = "empty";
    caption = `Step ${/\/(\d+)\//.exec(location.pathname)?.[1] || ""}/12`;
  } else if (routeMatch("/menu")) {
    myDogState = "done";
    menuState = "inprogress";
    deliveryState = "empty";
    checkoutState = "empty";
  } else if (routeMatch("/delivery")) {
    myDogState = "done";
    menuState = "done";
    deliveryState = "inprogress";
    checkoutState = "empty";
  } else if (routeMatch("/checkout")) {
    myDogState = "done";
    menuState = "done";
    deliveryState = "done";
    checkoutState = "inprogress";
  }

  return (
    <div className="sm:-mt-16 shadow-thick m-3 sm:m-0 rounded-[20px]">
      <div
        style={{ boxShadow: "0px 20px 60px rgba(184, 184, 184, 0.2)" }}
        className="flex flex-row justify-between items-center bg-white rounded-[20px] gap-x-3 px-3 py-5 md:px-10"
      >
        <Step
          id="mydog"
          name="My dog"
          icon={<IconMyDog className="w-4 h-4 md:w-9 md:h-9" />}
          state={myDogState}
          caption={caption}
          startPath="my-dog/1/name"
        />
        <Step
          id="menu"
          name="Menu"
          icon={<IconMenu className="w-4 h-4 md:w-9 md:h-9" />}
          state={menuState}
          caption="Step"
          startPath="menu/user-details"
        />
        <Step
          id="delivery"
          name="Delivery"
          icon={<IconDelivery className="w-4 h-4 md:w-9 md:h-9" />}
          state={deliveryState}
          caption="Step"
          startPath="delivery"
        />
        <Step
          id="checkout"
          name="Checkout"
          icon={<IconCheckout className="w-4 h-4 md:w-9 md:h-9" />}
          state={checkoutState}
          caption="Step"
          startPath="checkout/summary"
        />
      </div>
    </div>
  );
}

function StartPage() {
  const location = useLocation();

  // Check if user enters page other than the start one without initiating onboarding
  // If so, redirect to the start page
  if (location.pathname !== "/start/my-dog/1/name") {
    const onboarding = getOnboarding();
    if (!onboarding) {
      window.location.href = "/start/my-dog/1/name";
    }
  }

  useEffect(() => {
    localStorage.setItem(`start:recentPath`, location.pathname);
  }, [location]);

  return (
    <div className="grid xl:grid-cols-4">
      <div className="xl:col-span-3">
        <Header />
        <div className="grid sm:grid-cols-10 2xl:container 2xl:mx-auto">
          <div className="sm:col-span-8 sm:col-start-2">
            <Steps />
            <div className="mt-9 px-3 sm:px-0 pb-24">
              <OnboardingProvider>
                <Outlet />
              </OnboardingProvider>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed right-0 top-0 w-1/4 hidden xl:block xl:col-span-1">
        <RightPane />
      </div>
    </div>
  );
}

export default StartPage;
