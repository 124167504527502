import MenuGenerationSpinner from "features/start/components/MenuGenerationSpinner";
import Page from "features/start/components/Page";
import ProgressBar from "features/start/components/ProgressBar";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { calculateDailyEnergyRequirement } from "models/mydog";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export type Country = {
  id: string;
  name: string;
};

function MenuGenerationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const { updateDailyEnergy } = useOnboardingDispatch();
  const { dogs } = useOnboarding();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((count) => count + 1);
    }, 20);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      const energies = calculateDailyEnergyRequirement(dogs);
      updateDailyEnergy(energies);
      navigate("/start/menu/recommended-daily-portion");
    }, 2000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Page
      title="start.menu.generation.title"
      className="flex flex-col justify-center items-center gap-y-4"
    >
      <MenuGenerationSpinner className="mt-8" />
      <ProgressBar progress={progress} className="w-64" />
      <div className="font-light">{t("start.menu.generation.caption")}</div>
    </Page>
  );
}

export default MenuGenerationPage;
