import dayjs from "dayjs";
import Modal from "features/common/modals/Modal";
import Calendar from "react-calendar";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { orderService } from "resources/order";

export type DeliveryTimeFormValues = {
  date: Date;
};

type DeliveryTimeModalProps = {
  defaultValue: Date;
  isOpen: boolean;
  orderId: string;
  onClose: () => void;
  onConfirm: (date: Date) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue">;

function DeliveryTimeModal({
  defaultValue,
  orderId,
  isOpen,
  onClose,
  onConfirm,
}: DeliveryTimeModalProps) {
  const { t, i18n } = useTranslation();

  const { control, handleSubmit } = useForm<DeliveryTimeFormValues>({
    defaultValues: {
      date: defaultValue,
    },
  });

  const onSubmit: SubmitHandler<DeliveryTimeFormValues> = async (data) => {
    await orderService.changeDeliveryTime(orderId, data.date);
    onConfirm(data.date);
  };

  const minDate = dayjs()
    .add(parseInt(process.env.REACT_APP_ORDER_PREPARATION_PERIOD!, 10), "day")
    .toDate();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      className="rounded-2xl w-11/12 sm:w-1/2"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-7">
          <h3 className="text-2xl font-normal">
            {t("component.modal.change-delivery-time.title")}
          </h3>
          {/* <div className="mt-4">
            {t("home.modal.delivery-frequency.select")}
          </div> */}
          <div className="flex flex-col gap-y-2 mt-3">
            <Controller
              name="date"
              control={control}
              defaultValue={new Date()}
              render={({ field: { onChange, value } }) => (
                <Calendar
                  onChange={onChange}
                  defaultValue={value}
                  className="font-mitr"
                  minDate={minDate}
                  locale={i18n.language}
                />
              )}
            />
          </div>
        </div>
        <div className="bg-lightGray grid grid-cols-2 gap-x-4 py-5 px-7 rounded-b-2xl">
          <input
            className="form-submit back-button button rounded-lg shadow-none border border-gray"
            type="button"
            onClick={onClose}
            value={`${t("dialog.button.cancel")}`}
          />
          <input
            className="form-submit button primary-button rounded-lg shadow-none"
            type="submit"
            value={`${t("dialog.button.confirm")}`}
          />
        </div>
      </form>
    </Modal>
  );
}

export default DeliveryTimeModal;
