import imgMoreDogBenefit from "assets/images/start/name/moredog-benefit.png";
import ConfirmModal from "features/common/modals/ConfirmModal";
import Input from "features/common/components/Input";
import WarningBox from "features/common/components/WarningBox";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import UnderlinedTextButton from "features/start/components/UnderlinedTextButton";
import { Dog, DogIdAndName, DogNameFormValues, newDog } from "models/mydog";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { differenceWith, intersectionWith, pick } from "lodash";
import { useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ContinuePreviousSimulationModal from "features/start/components/ContinuePreviousSimulationModal";

function AdditionalDogBenefitBanner() {
  const { t } = useTranslation();
  return (
    <WarningBox className="md:mx-28">
      <div className="flex justify-start items-center gap-x-5">
        <img
          src={imgMoreDogBenefit}
          alt="moredog-benefit"
          className="w-11 h-11"
        />
        <div className="text-orange text-left">
          <div className="font-bold">
            {t("start.my-dog.dog-name.moredog.benefit.title")}
          </div>
          <div>{t("start.my-dog.dog-name.moredog.benefit.detail")}</div>
        </div>
      </div>
    </WarningBox>
  );
}

function DogNamePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dogs } = useOnboarding();
  const { addDog, removeDog, updateDogName } = useOnboardingDispatch();
  const [showDeleteDogDialog, setShowDeleteDogDialog] = useState(false);
  const [
    showContinuePreviousSimulationDialog,
    setShowContinuePreviousSimulationDialog,
  ] = useState(false);
  const [dogIndexToDelete, setDogIndexToDelete] = useState<number | null>();

  const onSubmit: SubmitHandler<DogNameFormValues> = async (data) => {
    const isEqual = (a: DogIdAndName, b: Dog) => a.id === b.id;
    const toUpdateDogs = intersectionWith(data.names, dogs, isEqual);
    const toAddDogs = differenceWith(data.names, dogs, isEqual);
    const toRemoveDogs = differenceWith(
      dogs,
      data.names,
      (a, b) => a.id === b.id
    );

    toRemoveDogs.forEach((dog) => {
      removeDog(dog);
    });

    toAddDogs.forEach((dog) => {
      addDog(newDog(dog.id, dog.name));
    });

    toUpdateDogs.forEach((dog) => {
      updateDogName(dog);
    });

    navigate("/start/my-dog/2/sex");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<DogNameFormValues>({
    defaultValues: {
      names:
        dogs.length === 0
          ? [{ id: uuidv4(), name: "" }]
          : dogs.map((dog) => pick(dog, ["id", "name"])),
    },
  });

  const names = watch("names");

  const { fields, append, remove } = useFieldArray<DogNameFormValues>({
    control,
    name: "names",
  });

  return (
    <Page title="start.my-dog.dog-name.title">
      <div className="mt-8 text-xl font-normal">
        {t("start.my-dog.dog-name.my-dog-name-is")}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <div key={index}>
            <div className="mt-4 relative sm:px-32 md:px-52">
              <Input
                key={index}
                className=""
                label=""
                placeholder="start.my-dog.name.placeholder"
                {...register(`names.${index}.name`, {
                  required: `${t("form.validation.required")}`,
                })}
                errors={errors}
                removeable={fields.length > 1}
                onRemove={() => {
                  if (!dogs.some((dog) => dog.id === names[index].id)) {
                    remove(index);
                    return;
                  }

                  setDogIndexToDelete(index);
                  setShowDeleteDogDialog(true);
                }}
              />
            </div>
          </div>
        ))}

        <UnderlinedTextButton
          className="mt-4 mb-4"
          caption="start.my-dog.dog-name.i-have-another-dog"
          onClick={() => {
            append({
              id: uuidv4(),
              name: "",
            });
          }}
        />
        <AdditionalDogBenefitBanner />
        <UnderlinedTextButton
          caption="start.my-dog.dog-name.use-previous-simulation"
          onClick={() => {
            setShowContinuePreviousSimulationDialog(true);
          }}
        />

        <ConfirmModal
          header="start.my-dog.dog-name.dialog.remove.title"
          description="start.my-dog.dog-name.dialog.remove.description"
          image={<div></div>}
          isOpen={showDeleteDogDialog}
          onClose={() => {
            setShowDeleteDogDialog(false);
          }}
          onConfirm={() => {
            remove(dogIndexToDelete!);
            setShowDeleteDogDialog(false);
          }}
        />

        <BottomNavigationBar>
          <div className="flex justify-end w-full">
            <input
              className="form-submit button primary-button"
              type="submit"
              value={`${t("start.navbuttonbar.continue")}`}
            />
          </div>
        </BottomNavigationBar>
      </form>
      <ContinuePreviousSimulationModal
        isOpen={showContinuePreviousSimulationDialog}
        onClose={() => {
          setShowContinuePreviousSimulationDialog(false);
        }}
        onConfirm={() => {
          remove(dogIndexToDelete!);
          setShowContinuePreviousSimulationDialog(false);
        }}
      />
    </Page>
  );
}

export default DogNamePage;
