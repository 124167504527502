import imgExtra from "assets/images/home/extras.jpg";
import imgSupplements from "assets/images/home/supplements.jpg";
import dayjs from "dayjs";
import AddressInfo from "features/common/components/AddressInfo";
import BankTransferPaymentView from "features/common/components/BankTransferPaymentView";
import FormattedAmount from "features/common/components/FormattedAmount";
import { IconCalendar } from "features/common/icon";
import DeliveryAddressModal from "features/common/modals/DeliveryAddressModal";
import DeliveryTimeModal from "features/common/modals/DeliveryTimeModal";
import {
  querySelectorWithTimeout,
  scrollToElementAndMakeItTop,
} from "features/common/utils";
import { map, round } from "lodash";
import { Account, Delivery } from "models/account";
import { Dog, dogsToMenus } from "models/mydog";
import { Order, getTagFromOrderStatus } from "models/order";
import { Product } from "models/product";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoaderFunction, useLoaderData } from "react-router-dom";
import { accountService } from "resources/account";
import { orderService } from "resources/order";
import { productService } from "resources/product";
import AddButton from "../components/AddButton";
import MenusSelectionModal from "../components/MenusSelectionModal";
import Page from "../components/Page";
import PillButton from "../components/PillButton";

export const loader: LoaderFunction = async ({ params }) => {
  const [me, order, products] = await Promise.all([
    accountService.getMe(),
    orderService.getOrderById(params.id!),
    productService.getProducts(),
  ]);
  return { me, order, products };
};

type DeliveryPaneProps = {
  order: Order;
  dogs: Dog[];
  delivery: Delivery;
  products: Product[];
  onMenusUpdated: () => void;
} & React.HTMLAttributes<HTMLDivElement>;
function DeliveryPane({
  order,
  dogs,
  delivery,
  products,
  onMenusUpdated,
}: DeliveryPaneProps) {
  const { t } = useTranslation();
  const [showMenusSelectionModal, setShowMenusSelectionModal] = useState(false);

  const menusWithDogs = dogsToMenus(dogs);

  return (
    <>
      <div className="flex justify-between">
        <div className="text-2xl">
          {t("home.delivery-detail.next-delivery.title")}
        </div>
        {order.status === "pending" && (
          <AddButton
            name="home.delivery-detail.next-delivery.button"
            onClick={() => {
              setShowMenusSelectionModal(true);
            }}
          />
        )}
      </div>
      <div className="bg-white shadow-thick rounded-xl p-4 sm:py-8 sm:px-10 mt-4 items-center">
        <div className="grid sm:grid-cols-3 gap-y-4 sm:gap-8">
          {menusWithDogs.map((menu) => (
            <div
              key={menu.menu.id}
              className="flex flex-row gap-x-4 items-center shadow-lg p-5"
            >
              <img
                src={menu.menu.imageUrl}
                className="w-16 h-16"
                alt={menu.menu.name}
              />
              <div className="text-left">
                <div className="font-normal">{menu.menu.name}</div>
                <div className="flex flex-col gap-y-2 mt-1">
                  {menu.dogs.map((dog, index) => (
                    <div className="flex gap-x-3 text-xs" key={index}>
                      {t("home.deliveries.delivery-pane.grams-for.label", {
                        boxNum: menu.menu.boxNum,
                        amount: dog.menus.find(
                          (m) => m.name === menu.menu.name
                        )!.amountPerBox,
                        dogName: dog.name,
                      })}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <MenusSelectionModal
        delivery={delivery}
        dogs={dogs}
        products={products}
        isOpen={showMenusSelectionModal}
        onClose={() => {
          setShowMenusSelectionModal(false);
        }}
        onConfirm={async (dogs) => {
          await orderService.updateDogs(order.id, dogs);
          setShowMenusSelectionModal(false);
          onMenusUpdated();
        }}
      />
    </>
  );
}

type ExtrasPaneProps = {
  dogs: Dog[];
  onClick: () => void;
} & React.HTMLAttributes<HTMLDivElement>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ExtrasPane({ dogs, onClick }: ExtrasPaneProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-between mt-4">
        <div className="text-2xl">{t("home.delivery-detail.extras.title")}</div>
        <AddButton
          name="home.delivery-detail.extras.edit.button"
          onClick={() => {}}
        />
      </div>
      <div className="bg-white shadow-thick rounded-xl p-5 mt-4 flex justify-center items-center py-10">
        <div className="flex flex-col items-center gap-4">
          <img src={imgExtra} alt="extras" className="w-[341px] h-[200px]" />
          <div className="font-normal text-gray">
            {t("home.delivery-detail.extras.description", {
              names: map(dogs, "name").join(", "),
            })}
          </div>
          <PillButton
            name="home.delivery-detail.extras.add.button"
            onClick={() => {}}
          />
        </div>
      </div>
    </>
  );
}

type SupplementsPaneProps = {
  dogs: Dog[];
  onClick: () => void;
} & React.HTMLAttributes<HTMLDivElement>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SupplementsPane({ dogs, onClick }: SupplementsPaneProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-between mt-4">
        <div className="text-2xl">
          {t("home.delivery-detail.supplements.title")}
        </div>
        <AddButton
          name="home.delivery-detail.supplements.edit.button"
          onClick={() => {}}
        />
      </div>
      <div className="bg-white shadow-thick rounded-xl p-5 mt-4 flex justify-center items-center py-10">
        <div className="flex flex-col items-center gap-4">
          <img
            src={imgSupplements}
            alt="extras"
            className="w-[320px] h-[125px]"
          />
          <div className="font-normal text-gray">
            {t("home.delivery-detail.supplements.description", {
              names: map(dogs, "name").join(", "),
            })}
          </div>
          <PillButton
            name="home.delivery-detail.supplements.add.button"
            onClick={() => {}}
          />
        </div>
      </div>
    </>
  );
}

type DeliveryConfirmationChangePaneProps = {
  title: string;
  onChange: () => void;
} & React.HTMLAttributes<HTMLDivElement>;
function DeliveryConfirmationChangePane({
  title,
  onChange,
  children,
}: DeliveryConfirmationChangePaneProps) {
  const { t } = useTranslation();

  return (
    <div className="mb-5 border-b border-b-lightGray pb-3">
      <div className="flex justify-between mb-3">
        <div className="text-xl font-normal">{t(title)}</div>
        <div
          className="cursor-pointer font-normal text-blue1"
          onClick={() => {
            onChange();
          }}
        >
          Change
        </div>
      </div>
      {children}
    </div>
  );
}

type DeliveryConfirmationPaneProps = {
  me: Account;
  order: Order;
  onPaymentSuccessful: () => void;
} & React.HTMLAttributes<HTMLDivElement>;
function DeliveryConfirmationPane({
  me,
  order,
  onPaymentSuccessful,
}: DeliveryConfirmationPaneProps) {
  const { t } = useTranslation();
  const [showBankTransferPayment, setShowBankTransferPayment] = useState(false);
  const [showDeliveryTimeModal, setShowDeliveryTimeModal] = useState(false);
  const [showDeliveryAddressModal, setShowDeliveryAddressModal] =
    useState(false);
  const [deliveryTime, setDeliveryTime] = useState(order.deliveredAt);
  const [address, setAddress] = useState(order.address);
  const tag = getTagFromOrderStatus(order);

  return (
    <>
      <div className="flex justify-between mt-4">
        <div className="text-2xl">
          {t("home.delivery-detail.delivery-confirmation.title")}
        </div>
        {/* <AddButton
          name="home.delivery-detail.delivery-confirmation.button"
          onClick={() => {}}
        /> */}
      </div>
      <div className="bg-white shadow-thick rounded-xl p-5 mt-4 grid sm:grid-cols-2 sm:divide-x sm:divide-lightGray">
        <div className="sm:pr-6">
          <div className="pb-3">
            <DeliveryConfirmationChangePane
              title="home.delivery-detail.delivery-confirmation.date-timeframe.title"
              onChange={() => {
                setShowDeliveryTimeModal(true);
              }}
            >
              <div className="flex gap-x-2 items-center">
                <IconCalendar className="w-4 h-4" />
                {dayjs(deliveryTime).format("dddd DD MMMM")}
              </div>
            </DeliveryConfirmationChangePane>

            <DeliveryConfirmationChangePane
              title="home.delivery-detail.delivery-confirmation.address.title"
              onChange={() => {
                setShowDeliveryAddressModal(true);
              }}
            >
              <AddressInfo address={address} className="text-left" />
            </DeliveryConfirmationChangePane>
          </div>
        </div>

        <div className="sm:pl-6">
          <div className="flex flex:col sm:flex-row justify-start">
            <span className="font-normal">
              {t("home.deliveries.delivery-pane.your-box.delivery.label")}
            </span>
            <span className="ml-1">: 0 ฿</span>
          </div>
          {order.shippedAt && (
            <div className="flex flex:col sm:flex-row justify-start">
              <span className="font-normal">Tracking Number</span>
              <span className="ml-1">: {order.trackingNumber}</span>
            </div>
          )}
          <div className="flex flex-col text-left sm:flex-row justify-start mt-8 gap-x-2">
            <span className="font-normal">
              {t("home.delivery-detail.delivery-confirmation.total.title")} :{" "}
            </span>
            <span>
              <FormattedAmount value={order.total} />
            </span>
            <span>
              {t(
                "home.delivery-detail.delivery-confirmation.total.description",
                {
                  price: round(order.total / order.frequency),
                  dayNum: order.frequency,
                }
              )}
            </span>
          </div>
          <div className="grid md:grid-cols-3 mt-8">
            <div className="md:col-start-2 col-span-1">
              {order.status === "pending" ? (
                <button
                  className="form-submit button primary-button"
                  onClick={() => {
                    setShowBankTransferPayment(true);
                    querySelectorWithTimeout(
                      "#bankTransferPaymentView",
                      1000
                    ).then((element) => {
                      if (element) {
                        scrollToElementAndMakeItTop("bankTransferPaymentView");
                      }
                    });
                  }}
                >
                  {t("button.paynow")}
                </button>
              ) : (
                <>{tag}</>
              )}
            </div>
          </div>
          {showBankTransferPayment && (
            <BankTransferPaymentView
              className="mt-4 animate__animated animate__fadeIn"
              getUploadUrl={async () => {
                const uploadUrl = await orderService.getSlipUploadUrl(order.id);
                return uploadUrl;
              }}
              onUploaded={async () => {
                await orderService.informBankTransfer(order.id);
                onPaymentSuccessful();
                window.location.reload();
              }}
            />
          )}
          <DeliveryTimeModal
            orderId={order.id}
            defaultValue={deliveryTime}
            isOpen={showDeliveryTimeModal}
            onClose={() => {
              setShowDeliveryTimeModal(false);
            }}
            onConfirm={(newDate) => {
              setShowDeliveryTimeModal(false);
              setDeliveryTime(newDate);
            }}
          />
          <DeliveryAddressModal
            orderId={order.id}
            account={me}
            defaultValue={deliveryTime}
            isOpen={showDeliveryAddressModal}
            onClose={() => {
              setShowDeliveryAddressModal(false);
            }}
            onConfirm={(addresses) => {
              setShowDeliveryAddressModal(false);
              setAddress(addresses.find((a) => a.isActive)!);
            }}
          />
        </div>
      </div>
    </>
  );
}

function DeliveryDetailPage() {
  const { t } = useTranslation();
  const {
    me,
    order: defaultOrder,
    products,
  } = useLoaderData() as {
    me: Account;
    order: Order;
    products: Product[];
  };
  const [order, setOrder] = useState(defaultOrder);

  const reloadOrder = async () => {
    const freshOrder = await orderService.getOrderById(order.id);
    setOrder(freshOrder);
  };

  return (
    <Page
      title="home.delivery-detail.jumbotron.title"
      subtitle={`${t("home.delivery-detail.jumbotron.subtitle", {
        date: dayjs(order.deliveredAt).format("DD MMMM YYYY"),
      })}`}
    >
      <DeliveryPane
        order={order}
        delivery={me.delivery}
        products={products}
        dogs={order.dogs}
        onMenusUpdated={reloadOrder}
      />
      {/* <ExtrasPane dogs={order.dogs} onClick={() => {}} />
      <SupplementsPane dogs={order.dogs} onClick={() => {}} /> */}
      <DeliveryConfirmationPane
        me={me}
        order={order}
        onPaymentSuccessful={reloadOrder}
      />
    </Page>
  );
}

export default DeliveryDetailPage;
