type ErrorBoxProps = {} & React.HTMLAttributes<HTMLDivElement>;

function ErrorBox({ children, className }: ErrorBoxProps) {
  return (
    <div
      className={`bg-red bg-opacity-10 text-red p-5 rounded-2xl text-left ${className}`}
    >
      {children}
    </div>
  );
}

export default ErrorBox;
