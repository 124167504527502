import { Account, Address, Delivery, User } from "models/account";
import { authenHttpClient } from "./http-client";
import { ProductResponse } from "./product";
import { APIResponse, SuccessResponse } from "./response";
import { Dog } from "models/mydog";

export type AccountResponse = {
  user: UserResponse;
  // dogs: DogResponse[];
  dogs: Dog[];
  address: AddressResponse[];
  delivery: DeliveryResponse;
};

export type DogResponse = {
  id: string;
  name: string;
  sex: string;
  isNeutered: boolean;
  ageType: string;
  year: number;
  month: number;
  week: number;
  breedId: number;
  mainFoodType: string;
  eatingHabitType: string;
  weight: number;
  bodyType: string;
  activityHabitType: string;
  hasFoodRestriction: boolean;
  foodRestrictionTypes: string[];
  hasSpecialCondition: boolean;
  specialConditionId: number | null;
  menus: Menu[];
  dailyEnergy: number;
};

export type DeliveryResponse = {
  frequency: number;
  deliveryDate: Date;
};

export type MealPlanResponse = {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  price: number;
  menus: Menu[];
};

export type Menu = {
  amount: number;
} & ProductResponse;

export type UserResponse = {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  telno: string;
};

export type AddressResponse = {
  number: string;
  street: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  telno: string;
};

export type EmailExistResponse = {
  exist: boolean;
  verified: boolean;
};

export type GetAccountResponse = {
  account: AccountResponse;
};

export type UpdateUserRequest = {} & User;

export type UpdateDeliveryFrequencyRequest = Pick<Delivery, "frequency">;

export type UpdateAddressRequest = Pick<Account, "addresses">;

export type UpdatePasswordRequest = {
  oldPassword: string;
  newPassword: string;
};

class AccountService {
  async doesEmailExist(): Promise<EmailExistResponse> {
    const { data } = await authenHttpClient.get<
      APIResponse<EmailExistResponse>
    >(`/users/email-exist`);
    return data.data;
  }

  async getMe(): Promise<AccountResponse> {
    const { data } = await authenHttpClient.get<
      APIResponse<GetAccountResponse>
    >(`/v1/me`);
    return data.data.account;
  }

  async updateUser(req: UpdateUserRequest): Promise<void> {
    await authenHttpClient.put<APIResponse<SuccessResponse>>(
      `/v1/me/user`,
      req
    );
  }

  async updatePassword(req: UpdatePasswordRequest): Promise<void> {
    await authenHttpClient.put<APIResponse<SuccessResponse>>(
      `/v1/me/password`,
      req
    );
  }

  async updateDeliveryFrequency(
    req: UpdateDeliveryFrequencyRequest
  ): Promise<void> {
    await authenHttpClient.put<APIResponse<SuccessResponse>>(
      `/v1/me/delivery-frequency`,
      req
    );
  }

  async updateAddresses(addresses: Address[]): Promise<void> {
    await authenHttpClient.put<APIResponse<SuccessResponse>>(
      `/v1/me/addresses`,
      {
        addresses,
      }
    );
  }

  async updateDogs(dogs: Dog[]): Promise<void> {
    await authenHttpClient.put<APIResponse<SuccessResponse>>("/v1/me/dogs", {
      dogs,
    });
  }
}

export const accountService = new AccountService();
