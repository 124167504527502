import ImageSelect from "features/common/components/ImageSelect";
import { useTranslation } from "react-i18next";

import EachDogName from "features/common/components/EachDogName";
import WarningBox from "features/common/components/WarningBox";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { pick } from "lodash";
import { BodyType, DogBodyTypeFormValues, bodyTypeOptions } from "models/mydog";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";

function DogBodyTypePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateDogBodyType } = useOnboardingDispatch();
  const { dogs } = useOnboarding();

  const { handleSubmit, control, watch, getValues } =
    useForm<DogBodyTypeFormValues>({
      defaultValues: {
        bodyTypes: dogs.map((dog) => pick(dog, ["id", "name", "bodyType"])),
      },
    });
  const { fields } = useFieldArray({
    control,
    name: "bodyTypes",
  });

  const updateToStore = (data: DogBodyTypeFormValues) => {
    const bodyTypes = data.bodyTypes.map((dog) => dog.bodyType!);
    updateDogBodyType(bodyTypes);
  };

  const onSubmit: SubmitHandler<DogBodyTypeFormValues> = async (data) => {
    updateToStore(data);
    navigate("/start/my-dog/10/activity-habit");
  };

  const bodyTypes = watch("bodyTypes");

  return (
    <Page title="start.my-dog.body-type.title">
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <div key={index} className="mt-4 mb-8 flex flex-col gap-y-4">
            <EachDogName template="my-dog.body-type" name={field.name} />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name={`bodyTypes.${index}.bodyType`}
              render={({ field: { onChange, value } }) => (
                <ImageSelect<BodyType>
                  className="grid grid-cols-2 md:grid-cols-3 gap-4"
                  options={bodyTypeOptions}
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
            <WarningBox className="sm:mx-28 text-left">
              {bodyTypes[index].bodyType}:&nbsp;
              {t(
                `component.body-type-select.${bodyTypes[index].bodyType}.description`,
                {
                  name: bodyTypes[index].name,
                }
              )}
            </WarningBox>
          </div>
        ))}
        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/my-dog/8/weight");
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default DogBodyTypePage;
