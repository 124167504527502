import imgInfo from "assets/images/start/menu/meal-plan/info.png";
import { useValidate } from "features/hook/useValidate";
import { get, includes, intersection, isEmpty } from "lodash";
import { Menu, MenusSelectionFormValues } from "models/meal";
import {
  Dog,
  FoodRestrictionType,
  calculateFoodAmountPerBox,
  calculatePricePerBox,
  findAllergicIngredientsInProduct,
  foodMeatIngredientTypes,
} from "models/mydog";
import { Product } from "models/product";
import { useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import Modal from "../modals/Modal";
import CounterInput from "./Counter";
import EachDogName from "./EachDogName";
import ErrorText from "./ErrorText";
import FormattedAmount from "./FormattedAmount";

type ProductDetailModalProps = {
  product: Product;
  allergicTo: FoodRestrictionType[];
  isOpen: boolean;
  onClose: () => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue">;
function ProductDetailModal({
  product,
  allergicTo,
  isOpen,
  onClose,
}: ProductDetailModalProps) {
  const { t } = useTranslation();
  const allergicIngredientsInProduct = findAllergicIngredientsInProduct(
    product,
    allergicTo
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      className="rounded-2xl w-11/12 sm:w-1/2"
    >
      <div className="p-7">
        <h3 className="text-2xl font-normal">{product.name}</h3>
        <div className="mt-5">{product.description}</div>
        {!isEmpty(allergicIngredientsInProduct) && (
          <div className="mt-10">
            {t("start.my-dog.food-restriction.no-allergy-guarantee", {
              ingredients: allergicIngredientsInProduct,
            })}
            :
          </div>
        )}
        <ul className="list-disc pl-5">
          {product.ingredients.map((ingredient, idx) => (
            <li
              className={
                includes(allergicIngredientsInProduct, ingredient)
                  ? "line-through"
                  : ""
              }
              key={idx}
            >
              {ingredient}
            </li>
          ))}
        </ul>
      </div>
      <div className="bg-lightGray py-5 px-7 rounded-b-2xl flex justify-end">
        <input
          className="form-submit button primary-button rounded-lg shadow-none"
          type="button"
          value={`${t("dialog.button.confirm")}`}
          onClick={onClose}
        />
      </div>
    </Modal>
  );
}

type ReciepeBoxProps = {
  product: Product;
  dog: Dog;
  selected: boolean;
  disableIncrease: boolean;
  disableDecrease: boolean;
  onChange: (amount: number) => void;
  onIncrease: (amount: number) => void;
  onDecrease: (amount: number) => void;
  onInfoClicked: () => void;
  defaultBoxNum: number;
};
function RecipeBox({
  product,
  dog,
  selected,
  disableIncrease,
  disableDecrease,
  onChange,
  onIncrease,
  onDecrease,
  onInfoClicked,
  defaultBoxNum,
}: ReciepeBoxProps) {
  const { t } = useTranslation();

  const foodAmountPerBox = calculateFoodAmountPerBox(
    dog.dailyEnergy,
    product.energy
  );
  const pricePerBox = calculatePricePerBox(foodAmountPerBox, product.price);

  const allergicIngredientsInProduct = findAllergicIngredientsInProduct(
    product,
    dog.foodRestrictionTypes
  );

  return (
    <div
      className={`border rounded-lg p-3 cursor-pointer ${
        selected ? "border-blue1 border-2" : "border-lightGray"
      }`}
    >
      <div className="relative">
        <img
          className="w-full h-36 object-cover rounded-lg"
          src={product.imageUrl}
          alt="meal"
        />
        <div
          className={`absolute top-3 left-3 z-10 rounded-full w-6 h-6 flex items-center justify-center ${
            selected ? "bg-blue1" : "bg-white"
          }`}
        >
          {selected && <span className="text-white font-bold">&#x2713;</span>}
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <div className="text-blue1 font-normal text-2xl">{product.name}</div>
        <img
          className="w-6 h-6"
          src={imgInfo}
          alt="info"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onInfoClicked();
          }}
        />
      </div>
      <div className="text-gray mt-3 text-left font-light">
        {product.description}
      </div>
      {/* <div className="text-sm text-gray mt-2 text-left font-light">
        Energy: {product.energy}kcal / 100g
      </div>
      <div className="text-sm text-gray text-left font-light">
        Food Amount: {foodAmountPerBox}g / box
      </div> */}
      <div className="text-sm text-left font-normal mt-3">
        Price per day: <FormattedAmount value={pricePerBox} />
      </div>

      {!isEmpty(allergicIngredientsInProduct) && (
        <div className="text-sm text-green text-left mt-3">
          {t("start.my-dog.food-restriction.no-allergy-guarantee", {
            ingredients: allergicIngredientsInProduct
              .map((frt) =>
                t(`start.my-dog.food-restriction-type.${frt}.button`)
              )
              .join(", "),
          })}
        </div>
      )}

      {selected && (
        <>
          <div className="mt-4">
            <CounterInput
              unit="กล่อง"
              defaultValue={defaultBoxNum}
              onChange={onChange}
              disableDecrease={disableDecrease}
              disableIncrease={disableIncrease}
              onIncrease={onIncrease}
              onDecrease={onDecrease}
            />
          </div>
          {/* <div className="text-sm text-gray text-left font-light mt-2">
            Estimated price: <FormattedAmount value={estimatedPrice} />
          </div> */}
        </>
      )}
    </div>
  );
}

type MenusSelectProps = {
  products: Product[];
  deliveryFrequency: number;
  dog: Dog;
  defaultValue: Menu[];
  onChange: (value: Menu[]) => void;
};
export function MenusSelect({
  products,
  deliveryFrequency,
  dog,
  defaultValue,
  onChange,
}: MenusSelectProps) {
  const { t } = useTranslation();

  const initialState = defaultValue ? defaultValue : [];
  const [selectedMenus, setSelectedMenus] = useState<Menu[]>(initialState);

  const totalBox = selectedMenus.reduce((sum, menu) => sum + menu.boxNum, 0);
  const [quota, setQuota] = useState(deliveryFrequency - totalBox);
  const [disableIncrease, setDisableIncrease] = useState(
    deliveryFrequency === totalBox
  );
  const [disableDecrease, setDisableDecrease] = useState(totalBox === 0);
  const updateQuota = (newQuota: number) => {
    setQuota(newQuota);
    if (newQuota === 0) {
      setDisableDecrease(false);
      setDisableIncrease(true);
    } else if (newQuota === deliveryFrequency) {
      setDisableDecrease(true);
      setDisableIncrease(false);
    } else {
      setDisableDecrease(false);
      setDisableIncrease(false);
    }
  };

  const [showProductDetailModal, setShowProductDetailModal] = useState(false);
  const [selectedProductIndexForDetail, setSelectedProductIndexForDetail] =
    useState(0);

  const edibleProducts = products.filter((product) => {
    const allergicIngredientsInProduct = findAllergicIngredientsInProduct(
      product,
      dog.foodRestrictionTypes
    );
    const containMainIngredient =
      intersection(allergicIngredientsInProduct, foodMeatIngredientTypes)
        .length !== 0;
    return !containMainIngredient;
  });

  return (
    <div>
      <div className="text-blue1 text-center">
        <span className="text-blue1 font-medium">
          {deliveryFrequency - quota}
        </span>
        {t("component.menus-selection.quota.label", {
          maxQuota: deliveryFrequency,
        })}
      </div>
      <div className="grid sm:grid-cols-3 gap-4 auto-rows-min mt-5">
        {edibleProducts.map((product, idx) => {
          const selectedMenu = selectedMenus.find(
            (selectedMenu) => selectedMenu.id === product.id
          );

          return (
            <div
              key={idx}
              onClick={() => {
                const alreadySelected = selectedMenus.some(
                  (selectedMenu) => selectedMenu.id === product.id
                );
                let newSelectedMenus: Menu[] = [];
                if (alreadySelected) {
                  // then it will remove it from list
                  newSelectedMenus = selectedMenus.filter(
                    (selectedMenu) => selectedMenu.id !== product.id
                  );
                  const selectedMenu = selectedMenus.find(
                    (selectedMenu) => selectedMenu.id === product.id
                  );
                  updateQuota(quota + selectedMenu!.boxNum);
                } else {
                  if (quota === 0) {
                    alert("You have reached maximum quota");
                    return;
                  }
                  newSelectedMenus = [
                    ...selectedMenus,
                    {
                      ...product,
                      amountPerBox: calculateFoodAmountPerBox(
                        dog.dailyEnergy,
                        product.energy
                      ),
                      boxNum: 1,
                    },
                  ];
                  updateQuota(quota - 1);
                }
                setSelectedMenus(newSelectedMenus);
                onChange(newSelectedMenus);
              }}
            >
              <RecipeBox
                product={product}
                dog={dog}
                selected={selectedMenu !== undefined}
                onChange={(boxNum) => {
                  const otherMenus = selectedMenus.filter(
                    (selectedMenu) => selectedMenu.id !== product.id
                  );
                  const updatedMenus = [
                    ...otherMenus,
                    {
                      ...product,
                      boxNum,
                      amountPerBox: calculateFoodAmountPerBox(
                        dog.dailyEnergy,
                        product.energy
                      ),
                    },
                  ];
                  setSelectedMenus(updatedMenus);
                  onChange(updatedMenus);
                }}
                onIncrease={() => {
                  updateQuota(quota - 1);
                }}
                onDecrease={() => {
                  updateQuota(quota + 1);
                }}
                disableIncrease={disableIncrease}
                disableDecrease={disableDecrease}
                defaultBoxNum={selectedMenu ? selectedMenu.boxNum : 1}
                onInfoClicked={() => {
                  setSelectedProductIndexForDetail(idx);
                  setShowProductDetailModal(true);
                }}
              />
            </div>
          );
        })}

        <ProductDetailModal
          product={edibleProducts[selectedProductIndexForDetail]}
          allergicTo={dog.foodRestrictionTypes}
          isOpen={showProductDetailModal}
          onClose={() => {
            setShowProductDetailModal(false);
          }}
        />
      </div>
    </div>
  );
}

export type MenusSelectFieldProps = {
  products: Product[];
  deliveryFrequency: number;
  dogs: Dog[];
  control: Control<MenusSelectionFormValues, any>;
  errors: FieldErrors<MenusSelectionFormValues>;
};
export function MenusSelectField({
  products,
  deliveryFrequency,
  dogs,
  control,
  errors,
}: MenusSelectFieldProps) {
  const { validateMenus } = useValidate();

  const { fields } = useFieldArray({
    control,
    name: "menus",
  });

  return (
    <div>
      {fields.map((field, index) => (
        <div key={index} className="my-4">
          <EachDogName
            name={dogs[index].name}
            template="menu.meal-plan"
            className="mb-4 text-orange text-3xl"
          />
          <Controller
            control={control}
            rules={{
              validate: (value) => validateMenus(value, deliveryFrequency),
            }}
            name={`menus.${index}`}
            render={({ field: { onChange, value } }) => (
              <div
                className={`${
                  get(errors, `menus[${index}]`) && "border border-red p-2"
                }`}
              >
                <MenusSelect
                  dog={dogs[index]}
                  deliveryFrequency={deliveryFrequency}
                  products={products}
                  defaultValue={value}
                  onChange={onChange}
                />
              </div>
            )}
          />
          <ErrorText error={get(errors, `menus[${index}].message`)} />
        </div>
      ))}
    </div>
  );
}
