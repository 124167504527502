import ButtonSelect from "features/common/components/ButtonSelect";
import EachDogName from "features/common/components/EachDogName";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { pick } from "lodash";
import { DogNeuteredFormValues, neuteredOptions } from "models/mydog";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function DogNeuteredPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dogs } = useOnboarding();
  const { updateDogIsNeutered } = useOnboardingDispatch();

  const { handleSubmit, control, getValues } = useForm<DogNeuteredFormValues>({
    defaultValues: {
      isNeutereds: dogs.map((dog) => pick(dog, ["id", "name", "isNeutered"])),
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "isNeutereds",
  });

  const updateToStore = (data: DogNeuteredFormValues) => {
    const isNeutereds = data.isNeutereds.map((dog) => dog.isNeutered!);
    updateDogIsNeutered(isNeutereds);
  };

  const onSubmit: SubmitHandler<DogNeuteredFormValues> = async (data) => {
    updateToStore(data);
    navigate("/start/my-dog/4/age");
  };

  return (
    <Page title="start.my-dog.is-neutered.title">
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <div key={index} className="mt-4 md:px-36 mb-8">
            <EachDogName template="my-dog.is-neutered" name={field.name} />
            <Controller
              control={control}
              name={`isNeutereds.${index}.isNeutered`}
              render={({ field: { onChange, value } }) => (
                <div>
                  <ButtonSelect
                    options={neuteredOptions}
                    defaultValue={field.isNeutered}
                    className="grid grid-cols-2 gap-4 mt-4"
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </div>
        ))}
        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/my-dog/2/sex");
            }}
            value={`${t("start.navbuttonbar.back")}`}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
    </Page>
  );
}

export default DogNeuteredPage;
