interface FormattedAmountProps {
  value: number;
}

function FormattedAmount({ value }: FormattedAmountProps) {
  const formatted = value.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
  return <>{formatted} ฿</>;
}

export default FormattedAmount;
