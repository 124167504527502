import axios from "axios";
import {
  removeEmail,
  removeOnboarding,
  removeStartRecentPath,
  removeToken,
} from "features/common/storage";
import { useNavigate, useRouteError } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError() as Error;
  let title = error.message;
  let detail = error.stack;
  if (axios.isAxiosError(error)) {
    detail = JSON.stringify(error.response?.data);
  }
  return (
    <div className="w-full h-screen flex flex-col space-y-8 items-center justify-center bg-bg text-black">
      <div>Error</div>
      <div className="font-bold mt-10">
        เกิดปัญหาขึ้น กรุณาพยายามใหม่ในภายหลัง
      </div>
      <div className="p-6">{title}</div>
      <div className="p-6">{detail}</div>
      <button
        className="form-submit w-auto py-2 px-5"
        onClick={() => {
          navigate(-1);
        }}
      >
        ย้อนกลับ
      </button>
      <button
        className="form-submit w-auto py-2 px-5"
        onClick={() => {
          removeEmail();
          removeOnboarding();
          removeStartRecentPath();
          removeToken();
          window.location.reload();
        }}
      >
        เคลียร์ข้อมูล
      </button>
    </div>
  );
}

export default ErrorPage;
