import Modal from "features/common/modals/Modal";
import { useTranslation } from "react-i18next";

type UpdateActiveOrderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue">;

function UpdateActiveOrderModal({
  isOpen,
  onClose,
  onConfirm,
}: UpdateActiveOrderModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={() => {}} className="rounded-2xl modal-md">
      <div className="p-7">
        <h3 className="text-2xl font-normal">
          {t("component.modal.update-active-order.title")}
        </h3>
        <div className="mt-4">
          {t("component.modal.update-active-order.description")}
        </div>
      </div>
      <div className="bg-lightGray grid grid-cols-2 gap-x-4 py-5 px-7 rounded-b-2xl">
        <input
          className="form-submit back-button button rounded-lg shadow-none border border-gray"
          type="button"
          onClick={onClose}
          value={`${t("dialog.button.cancel")}`}
        />
        <input
          className="form-submit button primary-button rounded-lg shadow-none"
          type="submit"
          value={`${t("dialog.button.confirm")}`}
          onClick={onConfirm}
        />
      </div>
    </Modal>
  );
}

export default UpdateActiveOrderModal;
