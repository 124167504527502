import React from "react";
import { useTranslation } from "react-i18next";

type AddButtonProps = {
  name: string;
  onClick: () => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue" | "onChange">;

function AddButton({ name, onClick }: AddButtonProps) {
  const { t } = useTranslation();
  return (
    <div
      className="flex items-center gap-x-2 text-blue1 cursor-pointer"
      onClick={onClick}
    >
      <span className="inline-flex items-center justify-center w-5 h-5 rounded-full bg-blue1">
        <span className="text-white text-2xl">&#x002B;</span>
      </span>
      <div className="text-xl font-normal">{t(name)}</div>
    </div>
  );
}

export default AddButton;
