import ContextMenu, {
  MenuOption,
} from "features/common/components/ContextMenu";
import { IconCaretDown } from "features/common/icon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { setLanguage } from "../storage";
import dayjs from "dayjs";

type LanguageSwitcherProps = {
  paneClass: string;
  labelClass?: string;
};

const menuOptions: MenuOption<string>[] = [
  {
    name: "English",
    value: "en",
  },
  {
    name: "ไทย",
    value: "th",
  },
];

function LanguageSwitcher({ paneClass }: LanguageSwitcherProps) {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const { i18n } = useTranslation();
  return (
    <div
      onClick={() => {
        setShowContextMenu(!showContextMenu);
      }}
    >
      <div className="cursor-pointer relative">
        <div className={`flex items-center text-white fill-white gap-x-2`}>
          {menuOptions
            .find((option) => option.value === i18n.language)
            ?.value.toUpperCase()}
          <IconCaretDown className="w-3 h-3" />
        </div>
        <ContextMenu
          options={menuOptions}
          className={`absolute ${paneClass} w-24`}
          show={showContextMenu}
          onChoose={(language) => {
            i18n.changeLanguage(language);
            setLanguage(language);
            setShowContextMenu(false);
            dayjs.locale(language);
          }}
          onClose={() => {
            setShowContextMenu(false);
          }}
          defaultValue={i18n.language}
        />
      </div>
    </div>
  );
}

export default LanguageSwitcher;
