import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";

type WeightBoxProps = {
  weight: number;
  onChange: (weight: number) => void;
};

function WeightBox({ weight, onChange }: WeightBoxProps) {
  const { t } = useTranslation();

  return (
    <div
      className="border border-lightGray rounded-lg h-14 grid grid-cols-2 divide-x divide-lightGray w-32"
      key={weight}
    >
      <input
        type="number"
        className="w-full h-full text-center border border-lightGray outline-none appearance-none"
        onBlur={(e) => {
          const inputValue = parseInt(e.target.value, 10);
          onChange(inputValue);
        }}
        min={0}
        max={40}
        defaultValue={weight}
      />
      <div className="flex justify-center items-center">
        {t("start.my-dog.weight.unit.label")}
      </div>
    </div>
  );
}

const markStyle: CSSProperties = {
  marginTop: "13px",
  color: "#A0B3C1",
  fontSize: "16px",
  width: "70px",
};
const marks = {
  0: {
    style: markStyle,
    label: "0 kg",
  },
  20: {
    style: markStyle,
    label: "20 kg",
  },
  40: {
    style: markStyle,
    label: "40 kg",
  },
};

type WeightSliderProps = {
  defaultValue: number;
  onChange: (weight: number) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue" | "onChange">;

function WeightSlider({ defaultValue, onChange }: WeightSliderProps) {
  const [weight, setWeight] = useState<number>(defaultValue);

  return (
    <div className="px-6">
      <div className="flex justify-center my-4">
        <WeightBox
          weight={weight}
          onChange={(weight) => {
            if (weight > 40) {
              setWeight(40);
              onChange(40);
            } else {
              setWeight(weight);
              onChange(weight);
            }
          }}
        />
      </div>
      <Slider
        value={weight}
        max={40}
        marks={marks}
        defaultValue={defaultValue}
        onChange={(val) => {
          setWeight(val as number);
          onChange(val as number);
        }}
        className="w-full"
        handleStyle={{
          width: "30px",
          height: "30px",
          border: "solid 1px #F2AB26",
          borderRadius: "50%",
          opacity: "1",
        }}
        railStyle={{
          height: "16px",
          backgroundColor: "#F5F7F9",
          borderRadius: "8px",
        }}
        activeDotStyle={{
          display: "none",
        }}
        dotStyle={{
          display: "none",
        }}
        trackStyle={{
          height: "16px",
          backgroundColor: "#F2AB26",
        }}
      />
    </div>
  );
}

export default WeightSlider;
