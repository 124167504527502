import imgFreeDelivery from "assets/images/start/why-us/free-delivery.png";
import imgFreshRecipe from "assets/images/start/why-us/fresh-recipe.png";
import imgNoCommitment from "assets/images/start/why-us/no-commitment.png";
import ConfirmModal from "features/common/modals/ConfirmModal";
import imgSchedule from "assets/images/start/why-us/schedule.png";
import imgTailorMade from "assets/images/start/why-us/tailor-made.png";
import imgVitamin from "assets/images/start/why-us/vitamin.png";
import Input from "features/common/components/Input";
import BottomNavigationBar from "features/start/components/BottomNavigationBar";
import Page from "features/start/components/Page";
import { UserDetailsFormValues } from "models/account";
import {
  useOnboarding,
  useOnboardingDispatch,
} from "features/start/state/context";
import { get, pick } from "lodash";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { anonymousService } from "resources/anonymous";
import { useState } from "react";
import { onInvalidScrollToElement } from "features/common/utils";
import ErrorText from "features/common/components/ErrorText";
import { setEmail } from "features/common/storage";

export type Country = {
  id: string;
  name: string;
};

type ReasonItemProps = {
  iconUrl: string;
  name: string;
  iconWidth?: number;
  iconHeight?: number;
};

function ReasonItem({
  iconUrl,
  name,
  iconWidth = 4,
  iconHeight = 4,
}: ReasonItemProps) {
  const { t } = useTranslation();
  return (
    <li className="flex gap-x-2 items-center text-left">
      <div className="bg-blue1 rounded-full p-2">
        <img
          src={iconUrl}
          alt={name}
          className={`w-${iconWidth} h-${iconHeight}`}
        />
      </div>
      {t(name)}
    </li>
  );
}

function UserDetailsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, addresses, dogs, delivery } = useOnboarding();
  const { updateUserDetails } = useOnboardingDispatch();
  const [showEmailExistDialog, setShowEmailExistDialog] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm<UserDetailsFormValues>({
    defaultValues: {
      ...pick(user, ["firstName", "lastName", "email", "agreeTerms"]),
    },
  });

  const updateToStore = (data: UserDetailsFormValues) => {
    updateUserDetails({
      ...data,
      telno: "",
    });
  };

  const onSubmit: SubmitHandler<UserDetailsFormValues> = async (data) => {
    setEmail(data.email);
    const { exist, verified } = await anonymousService.doesEmailExist(
      data.email
    );
    if (exist && verified) {
      setShowEmailExistDialog(true);
      return;
    }
    await anonymousService.saveAccountWithUserDetails({
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        telno: "",
        agreeTerms: data.agreeTerms,
      },
      addresses,
      dogs,
      delivery,
    });
    updateToStore(data);
    navigate("/start/menu/generation");
  };

  const validateEmail = (value: string) => {
    if (!/\S+@\S+\.\S+/.test(value)) {
      return "Invalid email address";
    }
    return true;
  };

  return (
    <Page
      title="start.menu.user-details.title"
      subtitle="start.menu.user-details.subtitle"
    >
      <div className="text-xl font-normal mt-8">
        {t("start.menu.user-details.name-and-email")}
      </div>
      <form onSubmit={handleSubmit(onSubmit, onInvalidScrollToElement)}>
        <div className="mt-4 md:px-24">
          <div className="grid sm:grid-cols-2 gap-2 sm:gap-6">
            <Input
              className="sm:col-span-1"
              label="start.menu.user-details.first-name.label"
              placeholder="start.menu.user-details.first-name.label"
              {...register("firstName", {
                required: `${t("form.validation.required")}`,
              })}
              errors={errors}
            />
            <Input
              className="sm:col-span-1"
              label="start.menu.user-details.last-name.label"
              placeholder="start.menu.user-details.last-name.label"
              {...register("lastName", {
                required: `${t("form.validation.required")}`,
              })}
              errors={errors}
            />
          </div>
          <div className="grid sm:grid-cols-1 gap-2 sm:gap-6 mt-3">
            <Input
              className="sm:col-span-1"
              type="email"
              label="start.menu.user-details.email.label"
              placeholder="start.menu.user-details.email.label"
              {...register("email", {
                required: `${t("form.validation.required")}`,
                validate: validateEmail,
              })}
              errors={errors}
            />
          </div>
          <div className="mt-7 flex flex-col items-start text-left">
            <label className="cursor-pointer">
              <input
                type="checkbox"
                className="w-4 h-4"
                {...register("agreeTerms", {
                  required: `${t("form.validation.required")}`,
                })}
              />
              <span className="ml-4 font-light">
                {t("start.menu.user-details.terms.label")}
              </span>
            </label>
            <ErrorText error={get(errors, `agreeTerms.message`)} />
          </div>
          <div className="mt-8 text-xl font-normal">
            {t("start.menu.user-details.reason.title")}
          </div>
          <ul className="mt-5 bg-lightGray rounded-lg py-4 px-6 flex flex-col justify-start items-start font-light gap-y-2">
            <ReasonItem
              iconUrl={imgTailorMade}
              name="start.menu.user-details.reason.1"
            />
            <ReasonItem
              iconUrl={imgFreshRecipe}
              name="start.menu.user-details.reason.2"
            />
            <ReasonItem
              iconUrl={imgFreeDelivery}
              name="start.menu.user-details.reason.3"
            />
            <ReasonItem
              iconUrl={imgVitamin}
              name="start.menu.user-details.reason.4"
            />
            <ReasonItem
              iconUrl={imgSchedule}
              name="start.menu.user-details.reason.5"
            />
            <ReasonItem
              iconUrl={imgNoCommitment}
              name="start.menu.user-details.reason.6"
            />
          </ul>
        </div>
        <BottomNavigationBar>
          <input
            className="form-submit back-button button"
            type="button"
            value={`${t("start.navbuttonbar.back")}`}
            onClick={() => {
              updateToStore(getValues());
              navigate("/start/my-dog/12/special-condition");
            }}
          />
          <input
            className="form-submit button primary-button"
            type="submit"
            value={`${t("start.navbuttonbar.continue")}`}
          />
        </BottomNavigationBar>
      </form>
      <ConfirmModal
        header="start.menu.user-details.dialog.email-exist.title"
        description="start.menu.user-details.dialog.email-exist.description"
        image={<div></div>}
        isOpen={showEmailExistDialog}
        onClose={() => {
          setShowEmailExistDialog(false);
        }}
        onConfirm={() => {
          navigate("/login");
        }}
      />
    </Page>
  );
}

export default UserDetailsPage;
