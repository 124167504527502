import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
} from "react";
import {
  ActivityHabitType,
  BodyType,
  Dog,
  DogAndFoodRestriction,
  DogAndSpecialCondition,
  DogIdAndAge,
  DogIdAndBreed,
  DogIdAndName,
  EatingHabitType,
  MainFoodType,
  SexType,
} from "../../../models/mydog";
import {
  OnboardingAction,
  OnboardingActionType,
  onboardingReducer,
  OnboardingState,
} from "./reducer";
import { Delivery, User, Address } from "../../../models/account";
import { Menu } from "../../../models/meal";

const localStorageKey = "onboarding";
const stateStr = localStorage.getItem(localStorageKey);
const initialState: OnboardingState = stateStr
  ? JSON.parse(stateStr)
  : {
      dogs: [],
      user: {},
      addresses: [],
      delivery: {
        frequency: 14,
      },
    };

export const OnboardingContext = createContext<OnboardingState>(initialState);

export const OnboardingDispatchContext = createContext<
  Dispatch<OnboardingAction>
>(() => {});

export function OnboardingProvider({ children }: { children: any }) {
  const [onboarding, dispatch] = useReducer(onboardingReducer, initialState);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(onboarding));
  }, [onboarding]);

  return (
    <OnboardingContext.Provider value={onboarding}>
      <OnboardingDispatchContext.Provider value={dispatch}>
        {children}
      </OnboardingDispatchContext.Provider>
    </OnboardingContext.Provider>
  );
}

export function useOnboarding() {
  return useContext(OnboardingContext);
}

export function useOnboardingDispatch() {
  const dispatch = useContext(OnboardingDispatchContext);

  function updateDogName(dog: DogIdAndName) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_NAME,
      payload: dog,
    });
  }

  function addDog(dog: Dog) {
    dispatch({
      type: OnboardingActionType.ADD_DOG,
      payload: dog,
    });
  }

  function removeDog(dog: Partial<Dog>) {
    dispatch({
      type: OnboardingActionType.DELETE_DOG,
      payload: dog,
    });
  }

  function updateDogSex(sexes: SexType[]) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_SEX,
      payload: sexes,
    });
  }

  function updateDogIsNeutered(isNeutered: boolean[]) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_NEUTERED,
      payload: isNeutered,
    });
  }

  function updateDogAge(ages: DogIdAndAge[]) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_AGE,
      payload: ages,
    });
  }

  function updateDogBreed(breeds: DogIdAndBreed[]) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_BREED,
      payload: breeds,
    });
  }

  function updateDogMainFood(mainFoods: MainFoodType[]) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_MAIN_FOOD,
      payload: mainFoods,
    });
  }

  function updateDogEatingHabit(eatingHabits: EatingHabitType[]) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_EATING_HABIT,
      payload: eatingHabits,
    });
  }

  function updateDogWeight(weights: number[]) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_WEIGHT,
      payload: weights,
    });
  }

  function updateDogBodyType(bodyTypes: BodyType[]) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_BODY_TYPE,
      payload: bodyTypes,
    });
  }

  function updateDogActivityHabitType(activityHabitTypes: ActivityHabitType[]) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_ACTIVITY_HABIT_TYPE,
      payload: activityHabitTypes,
    });
  }

  function updateDogFoodRestriction(foodRestriction: DogAndFoodRestriction[]) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_FOOD_RESTRICTION,
      payload: foodRestriction,
    });
  }

  function updateDogSpecialCondition(
    specialCondition: DogAndSpecialCondition[]
  ) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_SPECIAL_CONDITION,
      payload: specialCondition,
    });
  }

  function updateUserDetails(user: User) {
    dispatch({
      type: OnboardingActionType.UPDATE_USER_DETAILS,
      payload: user,
    });
  }

  function updateDailyEnergy(energies: number[]) {
    dispatch({
      type: OnboardingActionType.UPDATE_DOG_DAILY_ENERGY,
      payload: energies,
    });
  }

  function chooseMenus(menus: Menu[][]) {
    dispatch({
      type: OnboardingActionType.CHOOSE_MENUS,
      payload: menus,
    });
  }

  function updateAddress(address: Address) {
    dispatch({
      type: OnboardingActionType.UPDATE_ADDRESS,
      payload: address,
    });
  }

  function updateDelivery(delivery: Delivery) {
    dispatch({
      type: OnboardingActionType.UPDATE_DELIVERY,
      payload: delivery,
    });
  }

  return {
    addDog,
    updateDogName,
    removeDog,
    updateDogSex,
    updateDogIsNeutered,
    updateDogAge,
    updateDogBreed,
    updateDogMainFood,
    updateDogEatingHabit,
    updateDogWeight,
    updateDogBodyType,
    updateDogActivityHabitType,
    updateDogFoodRestriction,
    updateDogSpecialCondition,
    updateDailyEnergy,
    updateUserDetails,
    chooseMenus,
    updateAddress,
    updateDelivery,
  };
}
