import { IconPencil } from "features/common/icon";
import React from "react";
import { useTranslation } from "react-i18next";

type AddButtonProps = {
  name: string;
  onClick: () => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue" | "onChange">;

function EditButton({ name, onClick }: AddButtonProps) {
  const { t } = useTranslation();
  return (
    <div
      className="flex items-center gap-x-2 text-blue1 cursor-pointer"
      onClick={onClick}
    >
      <span className="rounded-full w-5 h-5 bg-blue1 flex justify-center items-center">
        <IconPencil className="fill-white" />
      </span>
      <div className="text-xl font-normal">{t(name)}</div>
    </div>
  );
}

export default EditButton;
