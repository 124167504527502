import { useTranslation } from "react-i18next";
import imgJumbotron from "assets/images/home/jumbotron.png";

type JumbotronProps = {
  title: string;
  subtitle: string;
};
function Jumbotron({ title, subtitle }: JumbotronProps) {
  const { t } = useTranslation();
  return (
    <div className="bg-white">
      <div className="container mx-auto flex justify-between items-center h-[170px] p-3 sm:p-0">
        <div className="text-left">
          <h4 className="font-normal text-3xl">{t(title)}</h4>
          <div className="text-xl mt-4">{t(subtitle)}</div>
        </div>
        <img
          src={imgJumbotron}
          alt="jumbotron"
          className="w-[320px] h-[110px] self-end hidden sm:block"
        />
      </div>
    </div>
  );
}

type PageProps = {
  title: string;
  subtitle: string;
} & React.HTMLAttributes<HTMLDivElement>;

function Page({ title, subtitle, children, className }: PageProps) {
  return (
    <>
      <Jumbotron title={title} subtitle={subtitle} />
      <div className="container mx-auto pt-9 pb-7 px-3 sm:px-0">
        <div className={className}>{children}</div>
      </div>
    </>
  );
}

export default Page;
