type SuccessBoxProps = {} & React.HTMLAttributes<HTMLDivElement>;

function SuccessBox({ children, className }: SuccessBoxProps) {
  return (
    <div
      className={`bg-green bg-opacity-10 text-green p-5 rounded-2xl text-left ${className}`}
    >
      {children}
    </div>
  );
}

export default SuccessBox;
