import { AxiosResponse } from "axios";
import { Account } from "models/account";
import { AccountResponse, GetAccountResponse } from "./account";
import { anonymousHttpClient as httpClient } from "./http-client";
import {
  GetOrderResponse,
  GetUploadSlipUrlResponse,
  OrderResponse,
} from "./order";
import {
  APIResponse,
  AuthenticationResponse,
  SuccessResponse,
} from "./response";

export type CreatePasswordRequest = {
  email: string;
  password: string;
  code: string;
};

export type EmailLoginRequest = {
  email: string;
  password: string;
};

export type EmailExistResponse = {
  exist: boolean;
  verified: boolean;
};

export type InformBankTransferResponse = {
  code: string;
};

class AnonymousService {
  async doesEmailExist(email: string): Promise<EmailExistResponse> {
    const { data } = await httpClient.get<APIResponse<EmailExistResponse>>(
      `/v1/users/email-exist?email=${email}`
    );
    return data.data;
  }

  async getAccount(email: string): Promise<AccountResponse> {
    const res = await httpClient.get<APIResponse<GetAccountResponse>>(
      `/v1/anonymous/accounts`,
      {
        params: {
          email,
        },
      }
    );
    return res.data.data.account;
  }

  async saveAccountWithUserDetails(account: Account): Promise<void> {
    await httpClient.post<APIResponse<SuccessResponse>>(
      `/v1/anonymous/accounts/user-details`,
      account
    );
  }

  async saveAccountWithMealPlan(account: Account): Promise<void> {
    await httpClient.post<APIResponse<SuccessResponse>>(
      `/v1/anonymous/accounts/meal-plan`,
      account
    );
  }

  async saveAccountWithDelivery(account: Account): Promise<void> {
    await httpClient.post<APIResponse<SuccessResponse>>(
      `/v1/anonymous/accounts/delivery`,
      account
    );
  }

  async getSlipUploadUrl(): Promise<string> {
    const { data } = await httpClient.get<
      APIResponse<GetUploadSlipUrlResponse>
    >("/v1/anonymous/orders/slip-upload-url");
    return data.data.uploadUrl;
  }

  async informBankTransfer(): Promise<InformBankTransferResponse> {
    const res = await httpClient.post<APIResponse<InformBankTransferResponse>>(
      "/v1/anonymous/orders/bank-transfer"
    );
    return res.data.data;
  }

  async placeOrder(): Promise<OrderResponse> {
    const { data } = await httpClient.post<APIResponse<GetOrderResponse>>(
      `/v1/anonymous/orders`
    );
    return data.data.order;
  }

  async getOrder(): Promise<OrderResponse> {
    const { data } = await httpClient.get<APIResponse<GetOrderResponse>>(
      `/v1/anonymous/orders`
    );
    return data.data.order;
  }

  async createPassword(req: CreatePasswordRequest): Promise<string> {
    const { data } = await httpClient.post<APIResponse<AuthenticationResponse>>(
      `/v1/anonymous/create-password`,
      req
    );
    return data.data.token;
  }

  async emailLogin(req: EmailLoginRequest): Promise<string> {
    let res: AxiosResponse<APIResponse<AuthenticationResponse>>;

    try {
      res = await httpClient.post<APIResponse<AuthenticationResponse>>(
        "/v1/auth/email-login",
        req
      );
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        throw new IncorrectPasswordError("Incorrect password");
      }
      throw err;
    }
    return res.data.data.token;
  }
}

export class IncorrectPasswordError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "IncorrectPasswordError";
    Object.setPrototypeOf(this, IncorrectPasswordError.prototype);
  }
}

export const anonymousService = new AnonymousService();
