export type ProgressBarProps = {
  progress: number;
} & React.HTMLAttributes<HTMLDivElement>;

function ProgressBar({ progress, className }: ProgressBarProps) {
  return (
    <div className={`h-4 bg-lightGray rounded-full ${className}`}>
      <div
        className="h-full bg-orange rounded-full animate-progress"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
}

export default ProgressBar;
