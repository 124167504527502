import { isEmpty } from "lodash";
import { Menu } from "models/meal";
import { AgeType } from "models/mydog";
import { useTranslation } from "react-i18next";

export function useValidate() {
  const { t } = useTranslation();

  function validateAge(
    name: string,
    ageType: AgeType,
    main: number,
    sub: number
  ) {
    if (ageType === "adult") {
      if (main === 0 && sub < 2) {
        return `${t("form.validation.too-young-age", { name: name })}`;
      }
      return true;
    } else if (ageType === "puppy") {
      if (main < 2) {
        return `${t("form.validation.too-young-age", { name: name })}`;
      }
      return true;
    }
    return true;
  }

  const validateWeight = (value: number) => {
    if (value === 0) {
      return t("form.validation.required");
    }
    return true;
  };

  const validateFoodRestrictions = (value: string[]) => {
    if (isEmpty(value)) {
      return t("form.validation.required");
    }
    return true;
  };

  const validateMenus = (menus: Menu[], maxBoxNum: number) => {
    if (isEmpty(menus)) {
      return t("form.validation.required");
    }
    const totalBox = menus.reduce((sum, menu) => sum + menu.boxNum, 0);
    if (totalBox !== maxBoxNum) {
      return t("form.validation.not-enough-box");
    }
    return true;
  };

  return {
    validateAge,
    validateWeight,
    validateFoodRestrictions,
    validateMenus,
  };
}
